import React from 'react';
import ReactTable from '../custom/ReactTable';

export default ({
    handleUpateContainer,
    data,
    setSelectedContainer,
    setModalUpdateContainerOpen,
    numRows,
    extendedMappingType
}) => {

    const minimalMapping = [
        {
            name: 'Container',
            value: 's_container'
        },
        {
            name: '',
            value: 'fas fa-edit',
            icon: true,
            function: (item) => handleUpateContainer(item)
        }
    ];
    
    const extendedMapping = [
        {
            name: 'Container',
            value: 's_container'
        },
        {
            name: 'Last Modified',
            value: 't_modified',
            datetime: true
        },
        {
            name: 'Modified By',
            value: 's_modified_by',
            datetime: true
        },
        {
            name: 'Arrived',
            value: 'b_arrived',
            boolean: true
        },
        {
            name: 'Status',
            value: 's_status'
        },
        {
            name: '',
            value: 'fas fa-edit',
            icon: true,
            function: () => handleUpateContainer(true)
        }
    ];

    return (
            <ReactTable
                data={data}
                mapping={ extendedMappingType ? extendedMapping : minimalMapping }
                enableClick={true}
                handleClick={setSelectedContainer}
                numRows={numRows}
                index={true}
            />
    );
}