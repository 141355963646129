import React, { useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css'
import 'animate.css';
import Auth0Lock from 'auth0-lock';
import config from '../../auth_config.json';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

export const AppContext = React.createContext();

export default (props) => {

    const lock = new Auth0Lock(config.clientId, config.domain);
    const { user, loading, getAccessTokenSilently, isAuthenticated } = useAuth0();
    const baseApiUrl = 'https://externalportalbackend.azurewebsites.net/api';
    // const baseApiUrl = 'http://localhost:3002/api';
    const eosWebApiUrl = 'https://eos-web-backend.azurewebsites.net/api';
    // const eosWebApiUrl = 'http://localhost:3002/api';
    const eosWebAuthCode = `6J!RT-bHBz9^!#z&JZg?cMZvaxMnt&!E$&&*jDbXKa%PsHY^!mD-TDeW@h6Yy?8C+kg#!j2Vn9A^5@9wtKSKS5AM+uX$&A*EU$J+Hsjn#p+zY7VMxBbRZKQM?K?M$Q@QUvn#Bqrbuq6m5Mfdyk^r87NnT@w?DDuK7QVk3y*K$=P?-%-Kv4x=vxk7s=R3RvrBhKr@t3?YP&SqB#^Mr^7fLPwq6?FB6GCaA??WqfH+bYmn?@#-4rZ4=HPNW+Z7j+F9+Jf#ChtWf&BWSew=Ez#^#szcHy%^&uc9ZSev85cYW?Ek4a5nCcJpKq@aSYDLXDZt2L@z2QTaWuQ3Hj726#AXg8VdKuwdpd%4vpsKpSM&sEvBVeFRWWk!g3-zuQCSW%WT2wEEsT!7zk@Z&cMa$#QQa_yTd62Sv_QNXUs_wR3BGNZZPRjdCRHXY=q2ucZAB^Tc9U9B5@3L!g-@DFj%h+Nb+L7dBhdfcJ^!W?^!^de9Q4t$p#XM+35^9_PF$qbBXvwA&PTmb5V@eJ?BBLX!E&bxGnS9&p8*mqnfxUnJ-Exa^jk?UQRXb2d7_GFh2qzg+yrn+#a8RHEVGDQphcK9yWx2fUx&Ve*sUWP!KQDM!J3W5ptckcx4J?qD=+4X_a$z%SA3?5G_vszHtEP4B*&2Dc3P3%hH@9gz$NK#DjXXB*!new=SE&U!7C!G&473tL?YnB8jgR2vbHnyvsLDdSJ9Z@yt_SM7S-FVupu#xnJXSu#cT7yB^KmDqDu$KBNWUP$44&AzgzcN!=^RxGLb8+372x!rq8SGs?keFenZbcs68kcTjG5^P+HwA?qy4=d3w8+6Vw2SzhJ*C^d42Et^yTW=M#=4PpmTUNq$2eMMP3mEpf7*JRx&mmS=v9kZSK!Z6z_2bnJ9Rnt*9^TnYnevpA4YLNnHesUx@wUYC2nF6%aT6Xq%cC!qY7#gXb#P*HEsK=2sgu^!+n&z=8UVsme?%fuYUY_G3NF%4ZS^CXJZeKZt-fdczL_2XYnEH&VePk2eEq?Q=Jdq`;
    const audience = config.audience;
    const [s_unit, set_s_unit] = useState(null);
    const [metadata, setMetadata] = useState({});
    const [modalSetUnitOpen, setModalSetUnitOpen] = useState(false);
    const [companyUnits, setCompanyUnits] = useState([]);
    const history = useHistory();

    const asyncHandler = (cb) => {
        return async (...args) => {
            try {
                return await cb(...args);
            } catch (err) {
                console.log(err);
                alert(err);
            }
        }
    }

    const getUserCompanyUnits = asyncHandler(async(s_company_guid) => {
        const token = await getAccessTokenSilently({ audience });
        
        const res = await axios.post(`${baseApiUrl}/getUserData`, {
            s_company_guid
        }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        console.log(res.data);
        const { data } = res;
        const units = (data && data.length > 0 && data.split(',')) || [];
        setCompanyUnits(units);
    });

    useEffect(() => {
        const getUserMetadata = asyncHandler(async() => {
            const token = await getAccessTokenSilently({
                audience: config.audience
            });
        
            lock.getUserInfo(token, function(error, profile) {
                const profileData = profile['https://dev-334eqvnn:auth0:com/app_metadata']
                
                if (profileData.s_company_guid) {
                    setMetadata(profileData);
                    getUserCompanyUnits(profileData.s_company_guid);
                }
            });
        });

        if (user && isAuthenticated) {
            getUserMetadata();
        }
    }, [user, isAuthenticated]);

    const createSuccessNotification = (message, type='success') => {
        // NotificationManager.success(message);
        store.addNotification({
            title: ' ',
            message: <div style={{fontFamily: 'Segoe UI', fontSize: '16px'}}><i className="far fa-check-square mr-2"></i>{message}</div>,
            type, 
            container: 'top-center',                // where to position the notifications
            animationIn: ["animated", "fadeInDown"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOutUp"],   // animate.css classes that's applied
            dismiss: {
                duration: 3000
            }
        });
    }

    const addLocalValue = (dataSet, setDataSet, newOjb) => {
        const copy = Object.assign([], dataSet);
        copy.push(newOjb);
        setDataSet(copy);
    }

    const updateLocalValue = (dataSet, setDataSet, id, updateValuesOjb) => {
        const copy = Object.assign([], dataSet);
        const updateIndex = copy.findIndex(item => item.id === id);
        for (let key in updateValuesOjb) {
            let value = updateValuesOjb[key];
            if (value) {
                if (typeof value !== 'boolean') {
                    if (value.length > 0) {
                        value = value.toUpperCase();
                    }
                }
            }
            copy[updateIndex][key] = value;
        }
        setDataSet(copy);
    }
    
    const deleteLocalValue = (dataSet, setDataSet, id) => {
        const newSet = dataSet.filter(d => d.id !== id);
        const copy = Object.assign([], newSet);    
        setDataSet(copy);
    }

    const formatCost = (cost) => {
        const toFormat = cost && cost !== null && cost > 0 ? parseFloat(cost) : 0;
        return `${toFormat.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
    }

    // Specific to Import:
    const [importFiles, setImportFiles] = useState([]);

    const addImportFile = (file) => {
        const copy = Object.assign([], importFiles);
        const exists = copy.find(f => f.guid === file.guid);
        if (!exists) {
            copy.push(file);
            setImportFiles(copy);
        }
    }

    const removeImportFile = (file) => {
        const filtered = importFiles.filter(f => f.guid !== file.guid);
        setImportFiles(filtered);
    }

    const clearImportFiles = () => {
        setImportFiles([]);
    }

    return (
        <AppContext.Provider value={{
            user,
            metadata,
            s_company_guid: metadata.s_company_guid,
            loading,
            baseApiUrl,
            eosWebApiUrl,
            eosWebAuthCode,
            audience,
            companyUnits,
            s_unit, 
            set_s_unit,
            modalSetUnitOpen, 
            setModalSetUnitOpen,
            history,
            actions: {
                asyncHandler,
                getAccessTokenSilently,
                createSuccessNotification,
                addLocalValue,
                updateLocalValue,
                deleteLocalValue,
                formatCost
            },
            importData: {
                importFiles,
                addImportFile,
                removeImportFile,
                clearImportFiles
            }
        }}>
            { props.children }
        </AppContext.Provider>
    );
}