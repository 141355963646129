import React, { useState } from 'react';
import moment from 'moment';
import { Button } from 'reactstrap';
import ReactTable from "react-table";
import "react-table/react-table.css";

export default ({
    data,
    mapping,
    handleClick,
    numRows,
    locked,
    index,
    enableClick,
    customHeight,
    customWidth,
    defaultFiltered
}) => {

    /* 
        export const mapping = [
            {
                name: 'ID',
                value: 'id',
                datetime: true,
                utc: true,
                email: true,
                money: true,
                icon: true,
                nested: true,
                subvalue: '',
                function: (item) => removeStationCustomer(item.stationCustomerRecordId)
            },
        ]
    */

    const filterCaseInsensitive = (filter, row) => {
        const id = filter.pivotId || filter.id;
        if (row[id] !== null && typeof row[id] === 'string') {
            return (
                row[id] !== undefined ?
                    String(row[id].toLowerCase()).includes(filter.value.toLowerCase()) : true
            )
        }
        else {
            return (
                String(row[filter.id]) === filter.value
            )
        }
    }

    const [selectedRow, setSelectedRow] = useState('');

    const onRowClick = (state, rowInfo, column, instance) => {
        return {
            onClick: e => {
                handleClick && handleClick(rowInfo.original);
                setSelectedRow(rowInfo.index);
                // console.log('A Td Element was clicked!')
                // console.log('it produced this event:', e)
                // console.log('It was in this column:', column)
                // console.log('It was in this row:', rowInfo) //rowInfo.original
                // console.log('It was in this table instance:', instance)
            },
            style: {
                backgroundColor: enableClick && rowInfo && rowInfo.index === selectedRow ? '#3993F3' : null,
                color: enableClick && rowInfo && rowInfo.index === selectedRow ? 'white' : 'black'
            }
        }
    }

    const formatCost = (cost) => {
        if (cost < 0) {
            const useCost = parseFloat(Math.abs(cost)); 
            return `${useCost.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
        }
        const toFormat = cost && cost !== null && cost > 0 ? parseFloat(cost) : 0;
        return `${toFormat.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
    }

    const resolveConcatValues = (d, values, operator) => {
        let string = '';

        // values.map(v => string += `${d[v]}${operator}`);

        for (let i = 0; i < values.length; i++) {
            const current = values[i];
            if (d[current] && d[current] !== null) {
                string += `${d[current]}${operator}`
            }   
        }

        string = string.substr(0, string.length - 2);

        return string;
    }
    
    const dataTableColumns = [];

    if (index) {
        dataTableColumns.push({
            Header: "#",
            id: "row",
            maxWidth: 50,
            filterable: true,
            Cell: (row) => {
                return <div>{row.index + 1}</div>;
            }
        });
    }

    for (let i = 0; i < mapping.length; i++) {

        const current = mapping[i];
        !current.exclude && dataTableColumns.push({
            id: current.id ? current.id : `id${i}`,
            Header: current.name,
            accessor: (d) => 
                current.datetime || current.date ?
                    moment(d[current.value]).isValid() ? 
                        current.utc ?
                            current.datetime ?
                                moment.utc(d[current.value]).format('MM/DD/YYYY HH:mm') :
                                moment.utc(d[current.value]).format('MM/DD/YYYY') :
                            current.datetime ?
                                moment(d[current.value]).format('MM/DD/YYYY HH:mm') : 
                                moment(d[current.value]).format('MM/DD/YYYY') :
                        '' :
                current.money ? 
                    `$${formatCost(d[current.value])}` : 
                current.email ? 
                    d[current.value] && `${d[current.value].toUpperCase().replace('@CHOICE.AERO', '')}` :
                current.icon && current.showCondition && current.showCondition(d) ?
                    <i style={{width: 50}} className={current.value} onClick={() => current.function(d)} /> :
                current.icon && !current.showCondition ?
                    <i style={{width: 50}} className={current.value} onClick={() => current.function(d)} /> :
                current.boolean ?
                    d[current.value] ? 'Yes' : 'No' :
                current.button ?
                    <Button onClick={() => current.function(d)}>{current.value}</Button> :
                current.concat ?
                    <span>{resolveConcatValues(d, current.values, current.operator)}</span> :
                current.nested ?
                    d[current.value][current.subvalue] :
                current.image ?
                    <img src={`${d[current.value]}`} style={{ width: '200px', height: 'auto' }} /> :
                current.importPaymentMethod ? 
                    d['f_amount'] < 0 ? 'CHARGE' :
                    d['f_amount'] > 0 && d[current.value] !== 'OVERRIDE' ? 'PAYMENT' : d[current.value] :
                d[current.value],
            columns: current.multiple ? [] : null,
            style: { 
                textAlign: `${current.money ? 'right' : current.icon || current.button ? 'center' : 'left'}`
            },
            width: current.icon && 50 || current.smallWidth && 50 || 
                   current.mediumWidth && 75 ||
                   current.largeWidth && 250 ||
                   current.customWidth && current.customWidth,
            filterable: current.icon || current.button ? false : true
        });
    }

    return (
        <ReactTable
            data={data}
            columns={dataTableColumns}
            defaultPageSize={numRows}
            showPageSizeOptions={ locked ? false : true }
            filterable={true}
            defaultFilterMethod={(filter, row) => filterCaseInsensitive(filter, row) }
            getTrProps={onRowClick}
            className="-striped -highlight"
            style={{
                height: `${customHeight && customHeight}`,
                width: `${customWidth && customWidth}`
            }}
            defaultFiltered={defaultFiltered}
        /> 
    );
}
