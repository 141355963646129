import React, { useState, useEffect, useContext  } from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { AppContext } from '../components/context/index';
import { useHistory} from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';
import "rc-switch/assets/index.css";
import {  Button, Row, Col  } from "reactstrap";
import { Formik } from 'formik';
import { Wizard, Steps, Step } from 'react-albus';
import TopNavigation from '../components/custom/wizard-hooks/TopNavigation';
import { v4 as uuidv4 } from 'uuid';

import mapping from '../components/import/mapping';
import PaymentAndLocation from '../components/import/PaymentAndLocation';
import IdentificationAndDocuments from '../components/import/IdentificationAndDocuments';
import CheckClearance from '../components/import/CheckClearance';
import ModalAddPaymentCharge from '../components/import/ModalAddPaymentCharge'; 
import ModalReject from '../components/import/ModalReject'; 
import ModalCreateAwb from '../components/import/ModalCreateAwb'; 

const ImportMain = ({
    values, 
    setFieldValue,
    resetForm
}) => {

    // Add to context:
    // importFiles={importFiles}
    // addImportFile={addImportFile}
    // removeImportFile={removeImportFile}
    // clearImportFiles={clearImportFiles}

    const {
        user,
        eosWebApiUrl,
        eosWebAuthCode,
        s_unit,
        actions,
        importData
    } = useContext(AppContext);

    const { asyncHandler, formatCost } = actions;

    const {
        importFiles,
        addImportFile,
        removeImportFile,
        clearImportFiles
    } = importData;

    const history = useHistory();
    const [myImportAwbs, setMyImportAwbs] = useState([]);
    const [selectedAwb, setSelectedAwb] = useState({});
    const [manualMode, setManualMode] = useState(false);
    const [ffms, setFfms] = useState([]);
    const [fhls, setFhls] = useState([]);
    const [fwbs, setFwbs] = useState([]);
    const [payments, setPayments] = useState([]);
    const [locations, setLocations] = useState([]);
    const [f_import_per_kg, set_f_import_per_kg] = useState(0);
    const [f_import_min_charge, set_f_import_min_charge] = useState(0);
    const [storageDays, setStorageDays] = useState(0);
    const [storageStartDate, setStorageStartDate] = useState('');
    const [dailyStorage, setDailyStorage] = useState(0);

    const [charges, setCharges] = useState({
        isc: {
            name: 'ISC', 
            cost: 0
        },
        storage: {
            name: 'Storage',
            description: '',
            cost: 0
        },
        other: {
            name: 'Other',
            cost: 0,
            description: ''
        },
        displayTotalCharges: {
            name: 'Total Charges',
            cost: 0,
            displayOnly: true
        },
        credits: {
            name: 'Total Credits',
            cost: 0,
            negative: true
        },
        paid: {
            name: 'Total Paid',
            cost: 0,
            negative: true
        }
    });

    const myAwbsQuery = asyncHandler(async() => {
        const ownershipAgent = user.name;

        const res = await axios.post(`${eosWebApiUrl}/agentAwbs`, {
            ownershipAgent,
            s_unit
        }, {
            headers: {'Authorization': `Bearer ${eosWebAuthCode}`}
        });

        
        const myImportAwbs = res.data.filter(d => d.s_type === 'IMPORT');

        if(myImportAwbs.length < 1) {
            history.push('/queue');
        } else {
            setMyImportAwbs(myImportAwbs);
            setSelectedAwb(myImportAwbs[0]);
        }
    });

    useEffect(() => {
        if (user && s_unit) {
            myAwbsQuery();
        }
    }, [user, s_unit]);

    const additionalImportDataQuery = asyncHandler(async() => {
        const { s_mawb } = selectedAwb;
        const res = await axios.post(`${eosWebApiUrl}/additionalImportData`, {
            data: {
                s_mawb,
                s_airline_prefix: s_mawb.substr(0, 3),
                s_unit
            }
        }, {
            headers: {
                'Authorization': `Bearer ${eosWebAuthCode}`
            }
        });

        console.log(res.data);

        const {
            paymentsInfo,
            ffmInfo,
            fhlInfo,
            fwbData,
            locationInfo,
            iscData
        } = res.data;

        for (let i = 0; i < ffmInfo.length; i++) {
            ffmInfo[i].selected = true;
        }

        setPayments(paymentsInfo);
        setFfms(ffmInfo);
        setFhls(fhlInfo);
        setFwbs(fwbData);
        setLocations(locationInfo);
        resolveIscCost(iscData);
    });

    useEffect(() => {
        resolveCredits();
    }, [payments]);

    useEffect(() => {
        resetForm();
        if (selectedAwb && selectedAwb.s_mawb) {
            setFieldValue('t_counter_start_time', moment().local().format('MM/DD/YYYY HH:mm'));
            additionalImportDataQuery();
        }
    }, [selectedAwb]);

    const handleSelectFfm = (ffm) => {
        const copy = Object.assign([], ffms);
        const updatedIndex = copy.findIndex(f => f.i_id === ffm.i_id);

        const newStatus = !copy[updatedIndex].selected;

        if (newStatus === false) {
            const selectedCount = copy.reduce((total, current) => {
                return total += current.selected ? 1 : 0;
            }, 0);

            if (selectedCount === 1) {
                return;
            }

            copy[updatedIndex].selected = newStatus;

        } else {
            copy[updatedIndex].selected = newStatus;
        }

        setFfms(copy);
    };

    const calcAutomaticPiecesWeightArrivalDate = () => {
        const pieces = ffms.reduce((total, current) => {
            return total += current.selected ? current.i_actual_piece_count : 0;
        }, 0);

        const weight = ffms.reduce((total, current) => {
            return total += current.selected ? current.f_weight : 0;
        }, 0);

        const useWeight = weight.toFixed(2);

        setFieldValue('i_pieces', pieces);
        setFieldValue('f_weight', useWeight);
        setFieldValue('i_pieces_auto', pieces);
        setFieldValue('f_weight_auto', useWeight);

        const sortedFFMs = ffms.sort((a, b) => moment.utc(b.d_arrival_date) - moment.utc(a.d_arrival_date));
        const setLastFltArrivalDate = ffms.length > 0 ? moment.utc(sortedFFMs[0].d_arrival_date).format('YYYY-MM-DD') : null;
        setFieldValue('d_last_arrival_date', setLastFltArrivalDate);
        setFieldValue('d_last_arrival_date_auto', setLastFltArrivalDate);

    };

    useEffect(() => {
        if (!manualMode) {
            calcAutomaticPiecesWeightArrivalDate();
        }
        if (ffms && ffms.length > 0) {
            resolveStorageDays();
        }
    }, [ffms, manualMode, values.d_last_arrival_date]);

    useEffect(() => {
        if (values.s_hawb && values.s_hawb.length > 0) {
            setManualMode(true);
        } else {
            setManualMode(false);
        }
    }, [values.s_hawb]);

    const updateCharge = (type, cost) => {
        const copy = Object.assign({}, charges);
        copy[type].cost = cost;
        setCharges(copy);
    };

    const updateChargeField = (type, field, value) => {
        const copy = Object.assign({}, charges);
        copy[type][field] = value;
        setCharges(copy);
    };

    const resolveIscCost = (iscData) => {
        let iscCost = 0;

        const { airlineDataWithDetail, corpStationData } = iscData;

        if (airlineDataWithDetail && airlineDataWithDetail.AirlineMappingDetail) {
            iscCost = airlineDataWithDetail.AirlineMappingDetail.f_import_isc_cost && airlineDataWithDetail.AirlineMappingDetail.f_import_isc_cost;
        } else {
            iscCost = corpStationData.f_import_isc_cost;
        }

        updateCharge('isc', iscCost);
        setFieldValue('f_charge_isc', iscCost);
        setFieldValue('f_charge_isc_auto', iscCost);

        if (airlineDataWithDetail && airlineDataWithDetail.AirlineMappingDetail) {
            set_f_import_per_kg(airlineDataWithDetail.AirlineMappingDetail.f_import_per_kg);
            set_f_import_min_charge(airlineDataWithDetail.AirlineMappingDetail.f_import_min_charge);    
        } else {
            set_f_import_per_kg(corpStationData.f_import_isc_cost);
            set_f_import_min_charge(0);
        }
    };

    const resolveHmCost = () => {
        if (selectedAwb && selectedAwb.s_mawb) {
            const excludeHmFeeAirlinePrefixes = ['114', '080'];
            const currentAirlinePrefix = selectedAwb.s_mawb.substr(0, 3);
            const cost = excludeHmFeeAirlinePrefixes.indexOf(currentAirlinePrefix) === -1 && selectedAwb.s_unit === 'CEWR1' ?
                16.26 :
                0;
            return cost;
        }
    };

    const resolveCredits = () => {
        const credits = payments.reduce((total, current) => {
            return total += current.s_payment_method === 'OVERRIDE' ? current.f_amount : 0;
        }, 0);
        updateCharge('credits', credits);
    }

    const resolveStorageDays = () => {

        if (manualMode) {
            const arrivalDate = new Date(moment.utc(values.d_last_arrival_date).format('MM/DD/YYYY'));
    
            const minusDays = moment(arrivalDate).format('dddd').toUpperCase() === 'FRIDAY' ? 3 : 2;
    
            const storageStartDate = moment(arrivalDate).add(minusDays + 1, 'days');
            setStorageStartDate(moment(storageStartDate).format('YYYY-MM-DD'));

            const now = moment().format('MM/DD/YYYY');
            const today = new Date(now);         
            const days = moment(today).diff(arrivalDate, 'days');
    
            const storageDays = Math.trunc(days - minusDays) > - 1 ? Math.trunc(days - minusDays) : 0;
        
            setStorageDays(storageDays);
        } else {
            const selectedFfms = ffms.filter(f => f.selected);

            // AWB with most recent d_storage_start_day is at index 0:
            const sortedFfms = selectedFfms.sort((a, b) => moment(b.d_storage_start_day) - moment(a.d_storage_start_day));
            const mostRecentArrivalDate = moment.utc(sortedFfms[0].d_storage_start_day).format('MM/DD/YYYY');
            
            const storageStartDate = new Date(mostRecentArrivalDate);
            const now = moment().format('MM/DD/YYYY');
            const today = new Date(now);         
    
            let storageDays = moment(today).diff(storageStartDate, 'days');
            storageDays += 1;
    
            setStorageDays(storageDays);

            setDailyStorage(sortedFfms[0].f_daily_storage_rate);
        }

    }

    const updateStorageDescription = () => {
        if (storageDays > 0) {
            updateChargeField('storage', 'description', `${storageDays} days at $${formatCost(dailyStorage)} per day`);
        } else {
            updateChargeField('storage', 'description', '');
        }
    };

    const resolveStorageCost = () => {

        const ffmCostCalc = (weight) => {
            console.log(`storageDays = ${storageDays}`);
            console.log(`weight = ${weight}`);
            console.log(`f_import_per_kg = ${f_import_per_kg}\nf_import_min_charge = ${f_import_min_charge}`);
            const charge = weight * f_import_per_kg;
            const dailyCharge = (charge > f_import_min_charge ? charge : f_import_min_charge);
            console.log(`daily storage auto = ${weight * f_import_per_kg > f_import_min_charge ? weight * f_import_per_kg : f_import_min_charge}`);
            setDailyStorage(dailyCharge);

            let cost = (dailyCharge * storageDays).toFixed(2);
        
            if (cost < 0) {
                cost = 0;
            }        

            console.log(`cost = ${cost}`);
            return cost;
        };

        if (manualMode) {

            const totalStorageCost = ffmCostCalc(values.f_weight); 

            updateCharge('storage', totalStorageCost);
            setFieldValue('f_charge_storage', totalStorageCost);

        } else {

            if (ffms && ffms.length > 0) {
                const { s_pieces_type } = ffms[0];
                if (s_pieces_type  === 'P' || s_pieces_type === 'D') {
                    // combine all weight and calculate
                    const totalWeight = ffms.reduce((total, ffm) => {
                        return total += ffm.selected ? parseFloat(ffm.f_weight) : 0;
                    }, 0);
    
                    const totalStorageCost = ffmCostCalc(totalWeight);
                    
                    updateCharge('storage', totalStorageCost);
                    setFieldValue('f_charge_storage', totalStorageCost);
                    setFieldValue('f_charge_storage_auto', totalStorageCost);
    
                } else {

                    const getIndividualStorageCost = (ffm) => {
                        if(ffm.selected) {
                            return ffmCostCalc(ffm.f_weight);
                        } 
                        return 0;
                    }

                    const totalStorageCost = ffms.reduce((total, ffm) => {
                        return total += parseFloat(getIndividualStorageCost(ffm));
                    }, 0);

                    console.log(totalStorageCost);

                    updateCharge('storage', totalStorageCost);
                    setFieldValue('f_charge_storage', totalStorageCost);
                    setFieldValue('f_charge_storage_auto', totalStorageCost);
                }
            }
        }
        updateStorageDescription();
    }

    useEffect(() => {
        resolveStorageCost();
    }, [manualMode, ffms, storageDays, f_import_min_charge, f_import_per_kg, values.f_weight, values.d_last_arrival_date]);

    const resolveTotalPaid = () => {
        const totalPaid = payments.reduce((total, payment) => {
            return total += payment.f_amount > 0 && payment.s_payment_method !== 'OVERRIDE' && payment.f_amount;
        }, 0);
        updateCharge('paid', totalPaid);
    }

    useEffect(() => {
        if (payments) {
            resolveTotalPaid();
            calcOtherCharges();
        }
    }, [payments, charges.isc, charges.storage, charges.other]);

    const calcPaymentDue = () => {
        const totalCharges = calcTotalCharges();
        const due = totalCharges - charges.credits.cost - charges.paid.cost;
        return due.toFixed(2);
    }

    const calcTotalCharges = () => {
        console.log(`isc = ${charges.isc.cost}\nstorage = ${charges.storage.cost}\nother = ${charges.other.cost}`);
        const isc = parseFloat(charges.isc.cost) > 0 ? parseFloat(charges.isc.cost) : 0;
        const storage = parseFloat(charges.storage.cost) > 0 ? parseFloat(charges.storage.cost) : 0;
        const other = parseFloat(charges.other.cost) > 0 ? parseFloat(charges.other.cost) : 0;

        return isc + storage + other;
    }

    const calcOtherCharges = () => {
        const standardCharges = ['ISC', 'STORAGE'];
        const isOtherCharge = (current) => current.f_amount < 0 && !standardCharges.includes(current.s_payment_type);  
        
        const types = [];

        let otherCharges = payments.reduce((total, current) => {
            if (isOtherCharge(current)) {
                types.push(current.s_payment_type);
                return total += Math.abs(current.f_amount);
            }
            return total += 0;
        }, 0);

        const hmCost = resolveHmCost();

        if (hmCost > 0 && !types.includes('H&M')) {
            otherCharges += hmCost;
            types.push('H&M');
        }

        updateCharge('other', otherCharges);
        updateChargeField('other', 'description', types.toString());

    }

    // Step 2: Check Identification Documents:

    const identificationFields = ['s_driver_name', 's_driver_id_type', 't_driver_id_expiration', 's_driver_id_number', 'b_driver_id_match_photo'];

    const [fileTypes, setFileTypes] = useState({
        'IDENTIFICATION': false,
        'AWB': false,
        'CONTROL MANIFEST': false,
        'CARRIER CERTIFICATE': false,
        'PICK UP ORDER': false
    });

    const [files, setFiles] = useState([]);

    useEffect(() => {
        if (selectedAwb.s_transaction_id) {
            console.log(selectedAwb);
            const { s_transaction_id } = selectedAwb;
            const storageTranscation = localStorage.getItem('s_transaction_id') && localStorage.getItem('s_transaction_id').toString() || null;
            console.log(`${storageTranscation} === ${s_transaction_id} ? ${storageTranscation === s_transaction_id}`);
            if (storageTranscation !== s_transaction_id) {
                clearImportFiles();
                localStorage.setItem('s_transaction_id', s_transaction_id);
                removeIdentificationInfo();
                setFieldValue('s_driver_name', selectedAwb.s_trucking_driver);
                setFieldValue('b_driver_id_match_photo', false);
                console.log(values);
            } else {
                for (let i = 0; i < identificationFields.length; i++) {
                    const key = identificationFields[i];
                    if (localStorage.getItem(key)) {
                        if (key === 'b_driver_id_match_photo') {
                            setFieldValue(key, localStorage.getItem(key).toString() === 'true' ? true : false);
                        } else {
                            setFieldValue(key, localStorage.getItem(key).toString());
                        }
                    } else {
                        if (key === 's_driver_name') {
                            setFieldValue('s_driver_name', selectedAwb.s_trucking_driver);
                        } else if (key === 'b_driver_id_match_photo') {
                            setFieldValue('b_driver_id_match_photo', false);
                        } else {
                            setFieldValue(key, '');
                        }
                    }
                }

                if (storageTranscation === s_transaction_id) {
                    if (importFiles.length > 0) {
                        for (let i = 0; i < importFiles.length; i++) {
                            const current = importFiles[i];
                            const exists = files.find(f => f.guid === current.guid);
                            if (!exists) {
                                addToFiles(importFiles[i]);
                            }
                        }
                    }
                }
            }
        }
    }, [selectedAwb]);

    useEffect(() => {
        const copy = Object.assign([], fileTypes);
        const haveFileTypes = [];

        for (let key in copy) {
            for (let i = 0; i < files.length; i++) {
                if (files[i].fileType === key) {
                    haveFileTypes.push(key);
                }
            }
        }

        for (let key in copy) {
            if (haveFileTypes.includes(key)) {
                copy[key] = true;
            } else {
                copy[key] = false;
            }
        }

        setFileTypes(copy);

    }, [files]);

    const resolveFileSize = (file) => {
        const base64str = file.base64.split('base64,')[1];
        const decoded = atob(base64str);
        return decoded.length / 0.75;
    }  

    const fileSizeFormatter = (size) => {
        if (size > 999999) {
            return `${(size / 1000000).toFixed(2)} mb`;
        } else {
            return `${(size / 1000).toFixed(2)} kb`;
        }
    }

    const saveIdentificationInformation = () => {
        for (let i = 0; i < identificationFields.length; i++) {
            const key = identificationFields[i];
            localStorage.setItem(key, values[key]);
        }
    } 
    
    const removeIdentificationInfo = () => identificationFields.map(key => localStorage.removeItem(key));

    const addToFiles = (file, upload=false) => {
        const copy = Object.assign([], files);

        file.guid = uuidv4();

        if (upload) {
            file.displaySize = file.size;
            file.type = file.file.type;
        } else {
            const fileSize = resolveFileSize(file);
            file.size = fileSize;
            file.displaySize = fileSizeFormatter(fileSize);   
            file.type = 'image/png'; 
        }

        console.log(file);

        copy.push(file);
        setFiles(copy);

        if (file.fileType === 'IDENTIFICATION') {
            addImportFile(file);
        }
    }

    const removeFile = (file) => {
        const filtered = files.filter(f => f.guid !== file.guid);
        setFiles(filtered);
        if (file.fileType === 'IDENTIFICATION') {
            removeImportFile(file);
            removeIdentificationInfo(); // Assumes that ID docs are linked to the ID info.
        }
    }

    const [file, setFile] = useState({});

    const updateFile = (prop, value) => {
        const copy = Object.assign({}, file);
        copy[prop] = value;
        setFile(copy);
    }

    const checkIdentification = () => {
        const checkValues = ['s_driver_name', 's_driver_id_type', 't_driver_id_expiration', 's_driver_id_number', 'b_driver_id_match_photo'];
        for (let i = 0; i < checkValues.length; i++) {
            const key = checkValues[i];
            if (!values[key] || values[key].length < 1) {
                return false;
            }
        }
        return true;
    }

    const resolvePaidTypes = (type) => {
        if (type === 'ONLINE') {
            const onlinePaymentMethods = ['CARGOSPRINT_CREDIT', 'CREDIT_CARD', 'ECHECK'];
            const total = payments.reduce((total, current) => {
                return total += onlinePaymentMethods.includes(current.s_payment_method) ? current.f_amount : 0;
            }, 0);
            return total;
        } else if (type === 'TOTAL') {
            const total = payments.reduce((total, current) => {
                return total += current.f_amount;
            }, 0);
            return total;
        } else {
            const total = payments.reduce((total, current) => {
                return total += current.s_payment_type === type ? current.f_amount : 0;
            }, 0);
            return total;
        }   
    }

    // const resolvePaidTypes = (type) => {

    //     const onlinePaymentMethods = ['CARGOSPRINT_CREDIT', 'CREDIT_CARD', 'ECHECK'];

    //     const calc = (total, current) => {
    //         if (type === 'ONLINE') {
    //             return total += onlinePaymentMethods.includes(current.s_payment_method) ? current.f_amount : 0;
    //         } else if (type === 'TOTAL') {
    //             return total += current.f_amount;
    //         } else {
    //             return total += current.s_payment_method === type ? current.f_amount : 0;
    //         }
    //     }

    //     const total = payments.reduce((total, current) => {
    //         return calc(total, current);
    //     }, 0);  

    //     return total;
    // }

    const resolveUserModified = () => {
        const {
            i_pieces, 
            i_pieces_auto, 
            f_weight, 
            f_weight_auto, 
            f_charge_isc,
            f_charge_isc_auto,
            f_charge_storage,
            f_charge_storage_auto,
            f_paid_online,
            f_paid_online_auto
        } = values;
        return !(i_pieces === i_pieces_auto && f_weight === f_weight_auto && f_charge_isc === f_charge_isc_auto && f_charge_storage === f_charge_storage_auto && f_paid_online === f_paid_online_auto);
    }

    const resolveOverrideNotes = () => {
        let notes = '';
        payments.map(payment => payment.s_payment_type === 'OVERRIDE' ? notes += `${payment.s_notes}.` : null);
        return notes;
    }


    // Final Submit:
    const createImportItem = asyncHandler(async(reject, push) => {

        let endpoint = '';

        const { email } = user && user;
        const now = moment().local().format('MM/DD/YYYY HH:mm');

        const data = {};
    
        const importData = values;

        const queueData = {
            id: selectedAwb.id,
            t_counter_start: values.t_counter_start_time,
            t_counter_end: now
        }

        importData.s_unit = selectedAwb.s_unit;
        importData.s_awb_type = 'IMPORT';
        importData.s_mawb = selectedAwb.s_mawb;
        importData.s_transaction_id = selectedAwb.s_transaction_id;
        importData.f_charge_others = charges.other.cost;
        importData.f_charges_total = calcTotalCharges();
        importData.f_paid_online = resolvePaidTypes('ONLINE');
        importData.f_paid_online_auto = resolvePaidTypes('ONLINE'); // ! Confirm
        importData.f_paid_check = resolvePaidTypes('CHECK');
        importData.f_paid_cash = resolvePaidTypes('CASH');
        importData.f_paid_total = resolvePaidTypes('TOTAL');
        importData.b_cargo_located = locations.length > 0;
        importData.f_balance_total = calcPaymentDue();
        importData.f_balance_offset = charges.credits.cost; 
        importData.s_balance_approval_notes = resolveOverrideNotes(); 
        importData.s_driver_company = selectedAwb.s_trucking_company;
        importData.t_kiosk_submittedtime = selectedAwb.t_kiosk_submitted;
        importData.s_counter_assigned_agent = selectedAwb.s_counter_ownership_agent;
        importData.t_counter_assigned_start = selectedAwb.t_counter_ownership;
        importData.s_counter_by = email;
        importData.t_counter_endtime = now;
        importData.t_created = now;
        importData.s_created_by = email;
        importData.t_modified = now;
        importData.s_modified_by = email;
        importData.b_user_modified_auto = resolveUserModified();
        importData.s_mawb_id = selectedAwb.s_mawb_id;

        if (reject) {
            importData.s_status = 'REJECTED';
            importData.b_counter_reject = true;
            importData.s_counter_reject_agent = email;
            importData.t_counter_reject_time = now;

            //Queue: 
            queueData.s_status = 'REJECTED';
            queueData.b_counter_reject = true;
            queueData.s_counter_reject_agent = email;
            queueData.t_counter_reject_time = now;
            queueData.s_counter_reject_reason = values.s_counter_reject_reason;

            // endpoint:
            endpoint = 'rejectImportItem';
        } else {
            importData.s_status = 'DOCUMENTED';
            importData.b_counter_reject = false;

            //Queue: 
            queueData.s_status = 'DOCUMENTED';
            queueData.b_counter_reject = false;

            data.files = files;

            // Endpoint
            endpoint = 'createImportItemNew';
        }

        data.importData = importData;
        data.queueData = queueData;

        await axios.post(`${eosWebApiUrl}/${endpoint}`, {
            data
        }, {
            headers: {
                Authorization: `Bearer ${eosWebAuthCode}`
            }
        });

        push('1');
        setModalReject(false);
        myAwbsQuery();
    });

    // ModalPaymentsCharges
    const [modalPayments, setModalPayments] = useState(false);
    const [addCharge, setAddCharge] = useState(false);

    const handleAddPaymentCharge = (addCharge) => {
        setAddCharge(addCharge);
        setModalPayments(true);
    }

    // Modal Reject
    const [modalReject, setModalReject] = useState(false);

    // Modal Create AWB
    const [modalCreateAwb, setModalCreateAwb] = useState(false);

    // Wizard Navigation:

    const checkFiles = () => {
        const required = ['IDENTIFICATION'];
        for (let i = 0; i < required.length; i++) {
            const key = required[i];
            if (!fileTypes[key]) {
                return false;
            }
        }
        return true;
    }

    const resolveEnableNext = (stepItem) => {
        const { id } = stepItem;
        if (id === '1') {
            return true;
        } else if (id === '2') {
            const due = calcPaymentDue();
            if (due <= 0) {
                return true;
            }
        } else if (id === '3') {
            return checkFiles();
        }
        return false;
    };

    const topNavClick = (stepItem, push) => {
        console.log(resolveEnableNext(stepItem));
        if (resolveEnableNext(stepItem)) {
            push(stepItem.id);
        }
    };

    const onClickNext = (goToNext, steps, step, push) => {
        console.log(steps);
        step.isDone = true;
        if (steps.length - 1 <= steps.indexOf(step)) {
            return;
        } else if (!resolveEnableNext(step)) {
            step.disabled = true;
            return;
        } else {
            step.disabled = false;
            goToNext();
        }
    };
    
    const onClickPrev = (goToPrev, steps, step, push) => {
        if (steps.indexOf(step) <= 0) {
            return;
        }

        goToPrev();
    };


    return (
        <Row className='px-3 py-3 '>
            <Col md='12' lg='12'>
                
                <Wizard render={({ step, steps, next, push }) => (
                    <>
                        <Row>
                            <Col md={12}>
                                <h1 className='float-left'>Import</h1>
                                <Button color={'danger'} className={'float-right'} onClick={() => setModalReject(true)}>Reject</Button>
                            </Col>
                        </Row>

                        <div className="wizard wizard-default">
                            <TopNavigation
                                className="justify-content-center mb-4"
                                disableNav={false}
                                topNavClick={topNavClick}
                            />
                            <Steps>
                                <Step id={'1'} name={'Check for Payment & Location'}>
                                    <PaymentAndLocation 
                                        myImportAwbs={myImportAwbs}
                                        selectedAwb={selectedAwb}
                                        setSelectedAwb={setSelectedAwb}
                                        manualMode={manualMode} 
                                        setManualMode={setManualMode}
                                        values={values}
                                        setFieldValue={setFieldValue}
                                        payments={payments}
                                        ffms={ffms}
                                        fhls={fhls}
                                        locations={locations}
                                        fwbs={fwbs}
                                        handleSelectFfm={handleSelectFfm}
                                        charges={charges}
                                        calcPaymentDue={calcPaymentDue}
                                        formatCost={formatCost}
                                        handleAddPaymentCharge={handleAddPaymentCharge}
                                        calcTotalCharges={calcTotalCharges}
                                        storageStartDate={storageStartDate}
                                        setModalCreateAwb={setModalCreateAwb}
                                    />
                                </Step>
                                <Step id={'2'} name={'Check Identification and Documents'}>
                                    <IdentificationAndDocuments 
                                        values={values}
                                        setFieldValue={setFieldValue}
                                        importFiles={importFiles}
                                        fileTypes={fileTypes}
                                        files={files}
                                        addToFiles={addToFiles}
                                        file={file}
                                        setFile={setFile}
                                        updateFile={updateFile}
                                        removeFile={removeFile}
                                        selectedAwb={selectedAwb}
                                        checkIdentification={checkIdentification}
                                        saveIdentificationInformation={saveIdentificationInformation}
                                    />
                                </Step>
                                <Step id={'3'} name={'Check Clearance'}>
                                    <CheckClearance 
                                        selectedAwb={selectedAwb}
                                        values={values}
                                        createImportItem={createImportItem}
                                        push={push}
                                    />
                                </Step>
                            </Steps>
                        </div>

                        <ModalAddPaymentCharge 
                            modal={modalPayments}
                            setModal={setModalPayments}
                            addCharge={addCharge}
                            selectedAwb={selectedAwb}
                            importValues={values}
                            asyncHandler={asyncHandler}
                            email={user && user.name}
                            eosWebApiUrl={eosWebApiUrl}
                            eosWebAuthCode={eosWebAuthCode}
                            setPayments={setPayments}                
                        />

                        <ModalCreateAwb 
                            modal={modalCreateAwb}
                            setModal={setModalCreateAwb}
                            selectedAwb={selectedAwb}
                            user={user}
                            eosWebApiUrl={eosWebApiUrl}
                            eosWebAuthCode={eosWebAuthCode}
                            asyncHandler={asyncHandler}
                            myAwbsQuery={myAwbsQuery}
                        />

                        <ModalReject 
                            modal={modalReject}
                            setModal={setModalReject}
                            createImportItem={createImportItem}
                            push={push}
                            values={values}
                        />

                    </>
                )}>

                </Wizard>

            </Col>
        </Row>
    );
}

const Import = () => {

    const [initialValues, setInitialValues] = useState({});

    const isNumber = (key) => key[0] === 'f' || key[0] === 'i';

    const resolveInitialValues = (mapping) => {
        const values = {};
        for (let i = 0; i < mapping.length; i++) {
            const key = mapping[i];
            if (isNumber(key)) {
                values[key] = 0;
            } else {
                values[key] = '';
            }
        }
        return values;
    }

    useEffect(() => {
        const values = resolveInitialValues(mapping);
        setInitialValues(values);
    }, []);

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize={true}
        >
            {({ values, setFieldValue, resetForm }) => (
                <ImportMain 
                    values={values}
                    setFieldValue={setFieldValue}
                    resetForm={resetForm}
                />
            )}
        </Formik>
    );
}

export default withAuthenticationRequired(Import);