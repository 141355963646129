import React, { useContext, useEffect, useRef } from 'react';
// import { AppContext } from '../components/context/index';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Col, Row, Form, FormGroup, Label, Input } from 'reactstrap';
import ReactTable from '../custom/ReactTable';
import { Formik } from 'formik';
import inboundTransfersMapping from './inboundTransfersMapping';
import SkuTable from './SkuTable';

export default ({
    open,
    toggle,
    skuData,
    selectedSku,
    setSelectedSku,
    handleCreateUpdateSku,
    addInboundTransfer
}) => {

    // const {
    //     s_unit,
    //     set_s_unit,
    //     modalSetUnitOpen,
    //     setModalSetUnitOpen
    // } = useContext(AppContext);

    const initialValues = {};

    inboundTransfersMapping.map(key => initialValues[key] = '');

    const enableSubmit = (isValid) => isValid && selectedSku !== null;

    return (
        <Modal isOpen={open} toggle={toggle} size={'lg'} style={{ maxWidth: '1000px', width: '100%' }}>
                <Formik
                    initialValues={initialValues}
                    validate={values => {
                        const errors = {}
                        const exclude = ['i_actual_quantity', 's_notes', 'i_skids', 'i_pc_per_skid'];
                        for (let key in values) {
                            if (exclude.indexOf(key) === -1) {
                                if (values[key].length === 0) {
                                    errors[key] = `${key} is invalid: ${values[key]}`
                                }
                            }
                        }    
                        return errors; 
                    }}
                    validateOnMount={true}
                >
                    {({ values, isValid, setFieldValue }) => (
                        <>
                            <ModalHeader>
                                <Row>
                                    <Col md={12}>
                                        <h4 className='float-left'>Add Inbound Transfer</h4>
                                    </Col>
                                </Row>
                            </ModalHeader>
                            <ModalBody className='text-center' style={{ height: '700px' }}>
                                <Row>
                                    <Col md={4}>
                                        <Row className='mb-1'>
                                            <Col md={12}>
                                                <h6 className='float-left'>Select SKU</h6>
                                                <Button className='float-right mb-1' onClick={() => handleCreateUpdateSku(true, true)}>Add</Button>
                                            </Col>
                                        </Row>
                                        <SkuTable 
                                            data={skuData}
                                            setSelectedSku={setSelectedSku}
                                            handleCreateUpdateSku={handleCreateUpdateSku}
                                            extendedMappingType={false}
                                            numRows={10}
                                            redirect={true}
                                        />
                                    </Col>
                                    <Col md={8}>
                                        <Row>
                                            <Col md={12}>
                                                <h6>Selected SKU: {selectedSku && selectedSku.s_sku}</h6>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12} className='text-left'>
                                                <Form>
                                                    <FormGroup>
                                                        <Label>Planned Quantity</Label>
                                                        <Input type='number' value={values.i_planned_quantity} onChange={(e) => setFieldValue('i_planned_quantity', e.target.value)} />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>Actual Quantity</Label>
                                                        <Input type='number' value={values.i_actual_quantity} onChange={(e) => setFieldValue('i_actual_quantity', e.target.value)} />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>Skids</Label>
                                                        <Input type='number' value={values.i_skids} onChange={(e) => setFieldValue('i_skids', e.target.value)} />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>Piece per skid</Label>
                                                        <Input type='number' value={values.i_pc_per_skid} onChange={(e) => setFieldValue('i_pc_per_skid', e.target.value)} />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>Notes</Label>
                                                        <Input type='textarea' value={values.s_notes} onChange={(e) => setFieldValue('s_notes', e.target.value)} />
                                                    </FormGroup>
                                                </Form>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="primary" disabled={!enableSubmit(isValid)} onClick={() => addInboundTransfer(values)}>Submit</Button>
                                <Button onClick={() => toggle()}>Cancel</Button>
                            </ModalFooter>
                        </>
                    )}
                </Formik>
        </Modal>
    );
}