import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, Input } from 'reactstrap';
import { Formik, Field } from 'formik';

export default ({
    modal,
    setModal,
    selectedContainer,
    updateContainer,
    deleteContainer
}) => {

    const toggle = () => setModal(!modal);
    const [initialValues, setInitialValues] = useState({});

    const mapping = [
        's_container',
        'd_expected_arrival',
        'd_actual_arrival',
        's_status',
        's_notes'
    ]

    useEffect(() => {
        if (selectedContainer) {
            const values = {};
            for (let i = 0; i < mapping.length; i++) {
                const key = mapping[i];
                values[key] = selectedContainer[key];
            }
            setInitialValues(values);
        }
    }, [selectedContainer]);

    return (
        <div>
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader>Update Container</ModalHeader>
                <Formik
                    initialValues={initialValues}
                    validate={values => {
                        const errors = {}
                        if (initialValues.s_container === values.s_container) {
                            errors['s_container'] = `Value for s_container needs to be updated`;
                        }
                        return errors;   
                    }}
                    validateOnMount={true}
                >
                    {({ values, isValid, setFieldValue }) => (
                        <>
                            <ModalBody>
                                <Field name='s_container' className='form-control' />
                            </ModalBody>
                            <ModalFooter>
                                <Button color="primary" disabled={!isValid} onClick={() => updateContainer(values)}>Submit</Button>{' '}
                                <Button color='danger' onClick={() => deleteContainer(selectedContainer.id)} >Delete</Button>
                                <Button color="secondary" onClick={toggle}>Cancel</Button>
                            </ModalFooter>
                        </>
                    )}
                </Formik>
            </Modal>
        </div>
    );
}