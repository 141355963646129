import React from 'react';
import moment from 'moment';
import {
    CustomInput,
  } from "reactstrap";
import Switch from "rc-switch";
import "rc-switch/assets/index.css";
import {Row, Col, Table, Card, CardBody} from 'reactstrap';

import {useEffect, useRef, useState} from 'react';

const Finalize = ({
    enableFinalSubmit,
    handleFinalSubmit,
    handleModalRejectionConfirm
}) => {
    return (
        <Row className='py-2' style={{fontSize: '16px'}}>
            <Col md={12}>
                <Card className='custom-opacity-card' style={{borderRadius: '0.75rem'}}>
                    <CardBody className='custom-card-transparent py-2' style={{backgroundColor: 'rgba(255,255,255,0.2)'}}>
                        <h4>Finalize:</h4>
                        <div className='row px-3'>
                            <div className='col-3 px-0'>
                                <button className="btn btn-primary" style={{fontWeight: 'bolder', fontSize: '14px'}} type="button" disabled={!enableFinalSubmit()} onClick={() => handleFinalSubmit(true)}>Submit</button>
                            </div>
                            <div className='col-3 px-0'>
                                <button className="btn btn-danger" type="button" onClick={() => handleModalRejectionConfirm()}>Reject</button>
                            </div>                        
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
}

export default Finalize;