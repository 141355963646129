import React, { Component, Fragment, useState, useEffect, useRef  } from 'react';
import {withRouter} from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';
import Switch from "rc-switch";
import "rc-switch/assets/index.css";

import { Input, Row, Col, Card, CardBody, CardTitle, CardText, Button } from 'reactstrap';
import ReactTooltip from 'react-tooltip';

import ModalAdditionalData from './ModalAdditionalData';
import ModalEditData from './ModalEditData';
import ReactTable from '../custom/ReactTable';


export default ({
    user, 
    eosWebApiUrl, 
    eosWebAuthCode, 
    createSuccessNotification, 
    s_unit,
    asyncHandler,
    deleteLocalValue 
}) => {

    const [rackItems, setRackItems] = useState([]);
    const [s_mawb, set_s_mawb] = useState('');
    const [s_hawb, set_s_hawb] = useState('');
    const [filteredRackItems, setFilteredRackItems] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [earliestDate, setEarliestDate] = useState('');
    const [latestDate, setLatestDate] = useState('');
    const [selectedRackItem, setSelectedRackItem] = useState(null);
    const [modalAdditionalDataOpen, setModalAdditionalDataOpen] = useState(false);
    const [updateId, setUpdateId] = useState(0);
    const [mawbAsc, setMawbAsc] = useState(true);
    const [updateSort, setUpdateSort] = useState(0);
    const [sortBy, setSortBy] = useState(null);
    const [searchByAirline, setSearchByAirline] = useState(false);
    const [airlinePrefixes, setAirlinPrefixes] = useState([]);
    const [selectedPrefix, setSelectedPrefix] = useState('');

    //edit
    const [modalEditOpen, setModalEditOpen] = useState(false);
    const [i_pieces, set_i_pieces] = useState(null);
    const [s_tower, set_s_tower] = useState(null);
    const [s_level, set_s_level] = useState(null);
    const [s_position, set_s_position] = useState([]);
    const [specialLocation, setSpecialLocation] = useState(null);
    const specialLocations = ['BYARD', 'WCOOL', 'COOL1', 'COOL2', 'COOL3', 'COOL4', 'DGRAC', 'SMRAC', 'CHOLD', 'LIVE1'];
    const [enableSpecialLocation, setEnableSpecialLocation] = useState(false);
    const [s_notes, set_s_notes] = useState('');

    useEffect(() => {
        selectRackItems();
    }, [user, s_unit]);

    useEffect(() => {
        if (!searchByAirline) {
            if (s_mawb.length > 0) {
                const filtered = rackItems.filter(i => i.s_mawb.includes(s_mawb));
                setFilteredData(filtered);
            } else if (s_hawb.length > 0) {
                console.log(s_hawb);
                const filtered = rackItems.filter(i => i.s_hawb.toUpperCase().includes(s_hawb.toUpperCase()));
                console.log(filtered);
                setFilteredData(filtered);
            }
        } else {
            set_s_mawb('');
            set_s_hawb('');
            const filtered = rackItems.filter(i => i.i_airline_prefix == selectedPrefix);
            console.log(selectedPrefix);
            console.log(filtered);
            setFilteredData(filtered);
        }
    }, [s_mawb, s_hawb, searchByAirline, selectedPrefix]); 

    useEffect(() => {
        if (rackItems.length > 0) {
            setEarliestDate(moment(rackItems[0].t_created).format('MM/DD/YYYY HH:mm'));
            setLatestDate(moment(rackItems[rackItems.length - 1].t_created).format('MM/DD/YYYY HH:mm'));    
        }
    }, [rackItems]);

    const formatMawb = (mawb) => {
        return mawb.substr(0, 13).replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
    }

    const resolveAirlinPrefixes = (data) => {
        const prefixes = [];
        data.map(d => d.i_airline_prefix && prefixes.indexOf(d.i_airline_prefix) === -1 && prefixes.push(d.i_airline_prefix));
        setAirlinPrefixes(prefixes);
        setSelectedPrefix(prefixes[0]);
    }

    const selectRackItems = () => {
     
        axios.post(`${eosWebApiUrl}/selectRackItems`, {
            s_unit
        }, {
            headers: {'Authorization': `Bearer ${eosWebAuthCode}`}
        }).then(response => {
            setRackItems(response.data);
            resolveAirlinPrefixes(response.data);
        }).catch(error => {

        });
    }


    const handleDisplayAdditionalData = (item) => {
        setSelectedRackItem(item);
        setModalAdditionalDataOpen(true);
    }


    const handleSort = (sortBy) => {
        setSortBy(sortBy);
        setUpdateSort(updateSort+1);
    }

    const sortByAwb = (a, b) => {
        if (sortBy === 's_position') {
            if (!a.s_tower) {
                // Change this values if you want to put `null` values at the end of the array
                return -1;
             }
         
             if (!b.s_tower) {
                // Change this values if you want to put `null` values at the end of the array
                return +1;
             }
         
             if (mawbAsc) {
                setMawbAsc(false);
                return a.s_tower.localeCompare(b.s_tower);
             }else {
                setMawbAsc(true);
                return b.s_tower.localeCompare(a.s_tower);
             }
        } else {
            if (mawbAsc) {
                setMawbAsc(false);
                return a[sortBy] - b[sortBy];
            } else {
                setMawbAsc(true);
                return b[sortBy] - a[sortBy];
            }
        }

    }

    useEffect(() => {
        const sortedRackItems = rackItems.sort(sortByAwb);
        console.log(sortedRackItems);
        setRackItems(sortedRackItems);
        setUpdateId(updateId+1);
    }, [updateSort]);

    const handleEdit = (rackItem) => {
        setSelectedRackItem(rackItem);
        set_i_pieces(rackItem.i_pieces);
        set_s_tower(rackItem.s_tower);
        set_s_level(rackItem.s_level);
        set_s_position(rackItem.s_position);

        if (specialLocations.indexOf(rackItem.s_location) !== -1) {
            setSpecialLocation(rackItem.s_location);
            setEnableSpecialLocation(true);
        } else {
            setEnableSpecialLocation(false);
        }

        setModalEditOpen(true);
    }

    const resolveLocation = () => {
        const position = parseInt(s_position) < 10 ? `0${s_position}` : s_position;
        return `${s_tower}${s_level}${position}`;
    }

    const updateRackLocation = () => {    
        const {s_guid} = selectedRackItem;  
        const s_location = enableSpecialLocation ? specialLocation : resolveLocation();
        const now = moment().local().format('MM/DD/YYYY hh:mm A');
        const t_modified = now;
        const s_modified_by = user && user.name;
        const s_location_type = enableSpecialLocation ? 'SPECIAL' : 'REGULAR';

        const userName = user.name.toUpperCase().split('@')[0];
        const date = moment().format('MM/DD/YYYY');
        const formatNotes = `[${userName}|${date} - ${s_notes}]. `;

        axios.post(`${eosWebApiUrl}/updateRackLocation`, {
            s_guid,
            s_tower,
            s_level,
            s_position,
            s_location,
            i_pieces,
            s_unit,
            t_modified,
            s_modified_by,
            s_location_type,
            s_notes: formatNotes
        }, {
            headers: {'Authorization': `Bearer ${eosWebAuthCode}`}
        }).then(response => {
            setRackItems(response.data);
            setModalEditOpen(false);
            createSuccessNotification('Location Updated');
            set_s_notes('');
        }).catch(error => {

        });
    }

    const updateRackStatus = asyncHandler(async(s_status) => {
        const { id } = selectedRackItem;
        await axios.post(`${eosWebApiUrl}/updateRackStatus`, {
            data: {
                id,
                s_status,
                t_modified: moment().local().format('MM/DD/YYYY HH:mm'),
                s_modified_by: user.name
            }
        }, {
            headers: {
                Authorization: `Bearer ${eosWebAuthCode}`
            }
        });
        
        setModalEditOpen(false);
        deleteLocalValue(rackItems, setRackItems, id);
        createSuccessNotification('Location Deleted');
    });

    const filteringActive = () => s_mawb.length > 0 || s_hawb.length > 0 || searchByAirline;

    const resolveCsvData = (useData) => {
        const data = [];
        const fields = [
            's_airline', 
            's_mawb',
            's_hawb',
            's_location',
            'i_pieces',
            's_special_hanlding_code',
            's_notes',
            'd_flight',
            's_flightnumber',
            's_flight_uld',
            'b_hold',
            'b_usda_hold',
            'b_customs_hold',
            'b_comat',
            'dc_created',
            'sc_created_username'
        ];

        for (let i = 0; i < useData.length; i++) {
            const currentItem = useData[i];
            if (!currentItem.b_delivered) {
                let object = {};
                for (let j = 0; j < fields.length; j++) {
                    const currentField = fields[j];
                    object[currentField] = currentItem[currentField];
                }
                data.push(object);
            }
        }

        return data;
    }
    
    const rackCsv = () => {
        const useData = filteringActive() ? filteredData : rackItems;

        const main = resolveCsvData(useData);

        const data = {
            main,
            email: user && user.name
        }

        axios.post(`${eosWebApiUrl}/rackCsv`, {
            data
        }, {
            headers: {'Authorization': `Bearer ${eosWebAuthCode}`}
        }).then(response => {
            createSuccessNotification('You will receive an email');
        });
    }

    const mapping = [
        {
            name: 'Location',
            value: 's_location'
        },
        {
            name: 'MAWB',
            value: 's_mawb'
        },
        {
            name: 'HAWB',
            value: 's_hawb'
        },
        {
            name: 'Pieces',
            value: 'i_pieces'
        },
        {
            name: 'Storage Length',
            value: 'ic_duration_created'
        },
        {
            name: 'More Info.',
            value: 'fas fa-info-circle',
            icon: true,
            function:  (item) => handleDisplayAdditionalData(item)

        },
        {
            name: 'Edit',
            value: 'fas fa-edit',
            icon: true,
            function: (item) => handleEdit(item)
        },
    ];
    
    return (
        <Row>
            <Col md='12' lg='12' className='px-3'>
                <Card className='custom-opacity-card' style={{borderRadius: '0.75rem'}}>
                    <CardBody className='custom-card-transparent py-3 px-5' style={{backgroundColor: 'rgba(255,255,255,0.2)'}}>
                        <Row>
                            <Col md='12' lg='12'>
                                <h4>Showing {rackItems.length} records from {earliestDate} to {latestDate}.</h4>
                            </Col>
                        </Row>
                        <Row className='mt-4 mb-0' style={{fontWeight: 'bold', fontSize: '20px'}}>
                            <Col md='12' lg='12' id={updateId}>
                                <ReactTable
                                    data={rackItems}
                                    mapping={mapping}
                                    index={true}
                                    numRows={20}
                                    customPagination={true}
                                />
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
            <ModalAdditionalData 
                open={modalAdditionalDataOpen}
                handleModal={setModalAdditionalDataOpen}
                rackItem={selectedRackItem}
            />
            <ModalEditData 
                open={modalEditOpen}
                handleModal={setModalEditOpen}
                rackItem={selectedRackItem}
                i_pieces={i_pieces}
                set_i_pieces={set_i_pieces}
                s_tower={s_tower}
                set_s_tower={set_s_tower}
                s_level={s_level}
                set_s_level={set_s_level}
                s_position={s_position}
                set_s_position={set_s_position}
                updateRackLocation={updateRackLocation}
                rackItems={rackItems}
                specialLocation={specialLocation}
                specialLocations={specialLocations}
                setSpecialLocation={setSpecialLocation}
                enableSpecialLocation={enableSpecialLocation}
                setEnableSpecialLocation={setEnableSpecialLocation}
                s_notes={s_notes}
                set_s_notes={set_s_notes}
                updateRackStatus={updateRackStatus}
            />
        </Row>
    );
}
