import React, { Component, Fragment, useState, useEffect, useRef  } from 'react';
import {withRouter} from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';
import Switch from "rc-switch";
import "rc-switch/assets/index.css";

import {Button, Row, Col, Table, Card, CardBody, CardTitle, CardText} from 'reactstrap';


const NilFlights = ({
    user, eosWebApiUrl, eosWebAuthCode, s_unit
}) => {

    const [s_pou, set_s_pou] = useState(null);
    const [userEmail, setUserEmail] = useState(null);
    const [d_arrival_date, set_d_arrival_date] = useState('');
    const [nilUlds, setNilUlds] = useState([]);

    const selectNilUlds = () => {
        axios.post(`${eosWebApiUrl}/selectNilUlds`, {
            d_arrival_date,
            s_pou
        }, {
            headers: {'Authorization': `Bearer ${eosWebAuthCode}`}
        }).then(response => {
            setNilUlds(response.data);
        }).catch(error => {

        });
    }

    useEffect(() => {
        const email = user && user.name; 

        s_unit && set_s_pou(s_unit.substr(1, 3));
        email && setUserEmail(email);
    }, [user]);

    useEffect(() => {
        selectNilUlds();
    }, [d_arrival_date]);

    return (
        <Row className='px-2'>
            <Col md='12' lg='12'>
                <div className='row py-2' style={{fontSize: '16px'}}>
                    <Card className='custom-opacity-card' style={{borderRadius: '0.75rem'}}>
                        <CardBody className='custom-card-transparent py-3' style={{backgroundColor: 'rgba(255,255,255,0.2)'}}>
                            <Row>
                                <h4 className='pr-3' style={{display: 'inline'}}>Select Date:</h4>
                                <input type='date' onChange={(e) => set_d_arrival_date(e.target.value)} style={{width: '200px', display: 'inline'}} />
                            </Row>
                            <Row className='mt-4'>
                                <Table striped>
                                    <thead className='bg-primary'>
                                        <tr>
                                            <th>Airline</th>
                                            <th>Flight</th>
                                            <th>Reported On</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            d_arrival_date !== '' && nilUlds.length === 0 ? 
                                            <h4>No results found</h4> : 
                                            nilUlds.map((u, i) => 
                                                <tr key={i}>
                                                    <td>{u.s_airline_code}</td>
                                                    <td>{u.s_flight_serial}</td>
                                                    <td>{moment(u.t_created).format('MM/DD/YYYY HH:mm')}</td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </Table>
                            </Row>
                        </CardBody>
                    </Card>
                </div>
            </Col>
        </Row>
    );
}

export default withRouter(NilFlights);