import React, { useState, useEffect, useContext } from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import  { AppContext } from '../components/context/index';
import { Row, Col, Button, Card, CardBody, Label, Input } from 'reactstrap';
import moment from 'moment';
import axios from 'axios';
import ReactTable from '../components/custom/ReactTable';
import uldAwbMapping from '../components/breakdownInstructions/uldAwbMapping';
import ModalViewNotes from '../components/breakdownInstructions/ModalViewNotes';

const BreakdownInstructions = ({

}) => {

    const {
        user,
        eosWebApiUrl,
        eosWebAuthCode,
        s_unit,
        actions,
        audience
    } = useContext(AppContext);

    const { asyncHandler, getAccessTokenSilently } = actions;

    const [d_arrival_date, set_d_arrival_date] = useState(moment().format('YYYY-MM-DD')); 
    const [ffmData, setFfmData] = useState([]);
    const [uniqueAirlines, setUniqueAirlines] = useState([]);
    const [ulds, setUlds] = useState([]);
    const [s_flightid, set_s_flightid] = useState('');
    const [selectedUld, setSelectedUld] = useState({});
    const [uldAwbs, setUldAwbs] = useState([]);
    const [selectedAwb, setSelectedAwb] = useState({});
    const [modal, setModal] = useState(false);
    const [s_notes, set_s_notes] = useState('');
    const [type, setType] = useState('');
    const [hawbs, setHawbs] = useState([]);
    const [fwbData, setFwbData] = useState({});
    const [selectedHawbRecord, setSelectedHawbRecord] = useState({});
    const [selectedHouseSerial, setSelectedHouseSerial] = useState('');

    const ffmQuery = asyncHandler(async() => {
        const res = await axios.post(`${eosWebApiUrl}/selectFfmByFlightArrivalDateAndPou`, {
            d_arrival_date,
            s_pou: s_unit.substr(1, 3)
        }, {
            headers: {'Authorization': `Bearer ${eosWebAuthCode}`}
        }); 

        console.log(res.data);

        setFfmData(res.data);
        setUniqueAirlines(resolveUniqueAirlines(res.data));
    });

    const resolveUniqueAirlines = (ffmData) => {
        const airlines = [];
        for (let i = 0; i < ffmData.length; i++) {
            const current = ffmData[i];
            const findCurrent = airlines.find(a => a.s_flight_number === current.s_flight_number);
            if (!findCurrent) {
                airlines.push({
                    s_flight_number: current.s_flight_number,
                    s_flightid: current.s_flightid,
                    s_logo: current.s_logo
                });
            }
        }
        return airlines;
    }

    useEffect(() => {
        if (user && s_unit) {
            setUlds([]);
            setUldAwbs([]);
            setSelectedUld({});
            setSelectedAwb({});
            set_s_flightid('');
            ffmQuery();
        }
    }, [user, d_arrival_date, s_unit]);

    const selectUldsByFlight = asyncHandler(async() => {
        const res = await axios.post(`${eosWebApiUrl}/selectUldsByFlight`, {
            data: {
                s_flight_id: s_flightid,
                s_pou: s_unit.substr(1, 3)
            }
        }, {
            headers: {
                Authorization: `Bearer ${eosWebAuthCode}`
            }
        });

        setUlds(res.data);
    });

    useEffect(() => {
        if (s_flightid && s_flightid.length > 0) {
            setUldAwbs([]);
            selectUldsByFlight();
        }
    }, [s_flightid]);

    useEffect(() => {
        if (selectedUld && selectedUld.id) {
            selectUldAwbs();
        }
    }, [selectedUld]);

    const selectUldAwbs = () => {
        const uldAwbs = ffmData.filter(d => d.s_flightid === s_flightid && d.s_uld === selectedUld.s_uld);
        const uniqueAwbs = [];
        for (let i = 0; i < uldAwbs.length; i++) {
            const current = uldAwbs[i];
            const exists = uniqueAwbs.find(a => a.i_id === current.i_id);
            if (!exists) {
                uniqueAwbs.push(current);
            }
        }
        setUldAwbs(uniqueAwbs);
    }

    const getBreakdownAdditionalData = asyncHandler(async(s_mawb) => {
        const res = await axios.post(`${eosWebApiUrl}/getBreakdownAdditionalData`, {
            data: {
                s_mawb
            }
        }, {
            headers: {
                Authorization: `Bearer ${eosWebAuthCode}`
            }
        });

        const { hawbs, fwbData } = res.data;

        setHawbs(hawbs);
        setFwbData(fwbData);
    });

    const handleAddUldNotes = (item) => {
        setSelectedUld(item);
        setType('uld');
        set_s_notes(item.s_notes || '');
        setModal(true);
    }

    const handleAddAwbNotes = (awb) => {
        setSelectedAwb(awb);
        setType('awb');
        set_s_notes(awb.s_notes || '');
        setModal(true);
        getBreakdownAdditionalData(awb.s_mawb);
    }

    useEffect(() => {
        const selectedHouse = hawbs.find(h => h.s_house_waybill_summaries_serial_1 === selectedHouseSerial);
        setSelectedHawbRecord(selectedHouse || {});
    }, [selectedHouseSerial]);

    useEffect(() => {
        setSelectedHawbRecord(hawbs[0] || {});
    }, [hawbs]);


    return (
        <Row className='mx-3 my-3'>
            <Col md={12}>
                <Row>
                    <Col md={12}>
                        <h4>Import Breakdown Instructions</h4>
                    </Col>
                </Row>
                <Row>
                    <Col md={3}>
                        <Card style={{ borderRadius: '0.75rem' }}>
                            <CardBody>
                                <Row>
                                    <Col md={12}>
                                        <Label className='d-inline mr-2'>Select Date</Label>
                                        <Input type='date' value={d_arrival_date} onChange={(e) => set_d_arrival_date(e.target.value)} className='d-inline' style={{ width: '200px' }} />
                                    </Col>
                                </Row>
                                <Row className='mt-2'>
                                    <Col md={12}>
                                        <ReactTable 
                                            data={uniqueAirlines}
                                            mapping={[
                                                {
                                                    name: 'Airline',
                                                    value: 's_flight_number',
                                                    customWidth: 100
                                                },
                                                {
                                                    name: '',
                                                    value: 's_logo',
                                                    image: true
                                                }
                                            ]}
                                            numRows={10}
                                            enableClick={true}
                                            handleClick={(item) => set_s_flightid(item.s_flightid)}
                                        />
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    {
                        s_flightid.length > 0 &&
                        <>
                            <Col md={4}>
                                <Card style={{ borderRadius: '0.75rem' }}>
                                    <CardBody>
                                        <h4>ULDs in Flight { s_flightid }</h4>
                                        <ReactTable 
                                            data={ulds}
                                            mapping={[
                                                {
                                                    name: 'ULD',
                                                    value: 's_uld'
                                                },
                                                {
                                                    name: 'Destinations',
                                                    value: 's_destination'
                                                },
                                                {
                                                    name: 'SHC',
                                                    value: 's_shc'
                                                },
                                                {
                                                    name: '',
                                                    value: 'View Notes',
                                                    button: true,
                                                    function: (item) => handleAddUldNotes(item)
                                                }
                                            ]}
                                            numRows={12}
                                            enableClick={true}
                                            handleClick={(item) => setSelectedUld(item)}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                            {
                                selectedUld.id && 
                                <Col md={5}>    
                                    <Card style={{ borderRadius: '0.75rem' }}>
                                        <CardBody>
                                            <h4>AWBs in {selectedUld.s_uld} in Flight {s_flightid}</h4>
                                            <ReactTable 
                                                data={uldAwbs}
                                                mapping={uldAwbMapping}
                                                numRows={14}
                                                enableClick={true}
                                                handleClick={(item) => handleAddAwbNotes(item)}
                                            />
                                        </CardBody>
                                    </Card>
                                </Col>
                            }
                        </>
                    }
                </Row>
            </Col>

            <ModalViewNotes 
                modal={modal}
                setModal={setModal}
                s_notes={s_notes}
                set_s_notes={set_s_notes}
                type={type}
                s_flightid={s_flightid}
                selectedUld={selectedUld}
                selectedAwb={selectedAwb}
                hawbs={hawbs}
                fwbData={fwbData}
                selectedHawbRecord={selectedHawbRecord}
                setSelectedHawbRecord={setSelectedHawbRecord}
                selectedHouseSerial={selectedHouseSerial}
                setSelectedHouseSerial={setSelectedHouseSerial}
            />

        </Row>
    );
}

export default withAuthenticationRequired(BreakdownInstructions);
