export default [
    {
        name: 'AWB',
        value: 's_mawb'
    },
    {
        name: 'Dest',
        value: 's_destination',
        smallWidth: true
    },
    {
        name: 'PCs',
        value: 'i_pieces_total',
        smallWidth: true
    },
    {
        name: 'Weight',
        value: 'f_weight'
    },
    {
        name: 'Type',
        value: 's_type'
    },
    {
        name: 'SHC',
        value: 's_special_handling_code'
    }
];