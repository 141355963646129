import React, { useContext } from 'react';
import { AppContext } from '../components/context/index';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, ButtonGroup } from 'reactstrap';
import { useAuth0 } from '@auth0/auth0-react';

export default ({
    open,
    toggle
}) => {

    const { isAuthenticated  } = useAuth0();

    const {
        companyUnits,
        s_unit,
        set_s_unit,
        modalSetUnitOpen,
        setModalSetUnitOpen
    } = useContext(AppContext);

    const units = ['CEWR1', 'CJFK1', 'CIAD1', 'CBOS1', 'CORD1'];

    const handleSelectUnit = (unit) => {
        set_s_unit(unit);
        setModalSetUnitOpen(false);
    }

    return (
        <Modal isOpen={isAuthenticated && s_unit === null || isAuthenticated && modalSetUnitOpen}>
            <ModalHeader>Select Unit</ModalHeader>
            <ModalBody className='text-center'>
                <ButtonGroup>
                {
                    companyUnits.map((u, i) =>
                        <Button key={i} onClick={() => handleSelectUnit(u)}>{u}</Button>
                    )
                }
                </ButtonGroup>
            </ModalBody>
        </Modal>
    );
}