import React, { useState, useEffect, useContext  } from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { AppContext } from '../components/context/index';
import "rc-switch/assets/index.css";
import { Nav, NavItem, TabContent, TabPane, Row, Col  } from "reactstrap";
import { NavLink } from "react-router-dom";
import classnames from "classnames";

import Search from '../components/rack/Search.js';

const Rack = () => {

    const {
        user, 
        eosWebApiUrl,
        eosWebAuthCode,
        actions,
        s_unit
    } = useContext(AppContext);

    const { createSuccessNotification, asyncHandler, deleteLocalValue } = actions;

    const [activeFirstTab, setActiveFirstTab] = useState('1');
    const [s_pou, set_s_pou] = useState(null);
    const [userEmail, setUserEmail] = useState(null);
    
    const toggleTab = (tab) => {
        if (activeFirstTab !== tab) {
            setActiveFirstTab(tab);
        }   
    }

    useEffect(() => {
        const email = user && user.name; 

        s_unit && set_s_pou(s_unit.substr(1, 3));
        email && setUserEmail(email);
    }, [user]);

    return (
        <Row className='px-3 py-3'>
            <Col md='12' lg='12'>
                <Row>
                    <h1 className='pl-3 pb-0 mb-1'>Rack</h1>
                </Row>
                <Row>
                    <Col mg='12' lg='12'>
                        <Nav tabs className="separator-tabs ml-0 mb-2">
                            <NavItem>
                                <NavLink
                                location={{}}
                                to="#"
                                className={classnames({
                                    active: activeFirstTab === "1",
                                    "nav-link": true
                                })}
                                onClick={() => {
                                    toggleTab("1");
                                }}
                                >
                                Search
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                location={{}}
                                to="#"
                                className={classnames({
                                    active: activeFirstTab === "2",
                                    "nav-link": true
                                })}
                                onClick={() => {
                                    toggleTab("2");
                                }}
                                >
                                View
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </Col>
                </Row>

                <TabContent activeTab={activeFirstTab} className='mt-2'>
                    <TabPane tabId="1">
                        <Search 
                            eosWebApiUrl={eosWebApiUrl}
                            eosWebAuthCode={eosWebAuthCode}
                            createSuccessNotification={createSuccessNotification}   
                            user={user}         
                            s_unit={s_unit}
                            asyncHandler={asyncHandler} 
                            deleteLocalValue={deleteLocalValue}                
                        />
                    </TabPane>
                    {/* <TabPane tabId="2">
                        <View 
                            baseApiUrl={baseApiUrl}
                            headerAuthCode={headerAuthCode}
                            eightyWindow={eightyWindow}
                            createSuccessNotification={createSuccessNotification}
                            user={user}                             
                        />
                    </TabPane> */}
                </TabContent>
            </Col>
        </Row>
    );
}

export default withAuthenticationRequired(Rack);