import React, { useState, useEffect, useContext } from 'react';
import { Row, Col, Button, Card, CardBody } from 'reactstrap';
import ReactTable from '../custom/ReactTable';
import ContainerTable from '../inboundTransfers/ContainerTable';

export default ({
    handleAddContainer,
    handleUpateContainer,
    modalAddContainerOpen, 
    setModalAddContainerOpen,
    setModalUpdateContainerOpen,
    containerData,
    skuData,
    selectedContainer,
    setSelectedContainer,
    addContainer,
    modalAddInbTransfer,
    setModalAddInbTransfer,
    selectedSku,
    setSelectedSku,
    addInboundTransfer,
    containerInboundData,
    modalUpdateTransfer, 
    setModalUpdateTransfer,
    selectedTransfer, 
    handleUpdateTransfer,
    updateInboundTransfer,
    deleteTransfer
}) => {

    return (
        <div className='pl-4 pr-2'>
            <Row>
                <Col md={3}>
                    <Card className='custom-card mt-2'>
                        <CardBody>
                            <Row>
                                <Col md={12}>
                                    <h4 className='float-left'>Transfer Type</h4>
                                    <Button className='float-right mb-1' onClick={() => handleAddContainer()}>New Container</Button>
                                </Col>
                            </Row>
                            <ContainerTable 
                                handleUpateContainer={handleUpateContainer}
                                data={containerData}
                                setSelectedContainer={setSelectedContainer}
                                setModalUpdateContainerOpen={setModalUpdateContainerOpen}
                                numRows={10}
                                extendedMappingType={false}
                            />
                        </CardBody>
                    </Card>
                </Col>
                <Col md={9}>
                {
                    selectedContainer.id && 
                    <Card className='custom-card mt-2'>
                        <CardBody>
                            <Row>
                                <Col md={12}>
                                    <h4 className='float-left'>Inbound Transfers List <span style={{ fontSize: '12px' }}>(Click to update)</span></h4>
                                    <Button className='float-right mb-1' onClick={() => setModalAddInbTransfer(true)}>Add</Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <ReactTable 
                                        data={containerInboundData}
                                        index={true}
                                        enableClick={true}
                                        handleClick={handleUpdateTransfer}
                                        numRows={10}
                                        mapping={[
                                            {
                                                name: 'SKU',
                                                value: 'SKU',
                                                subvalue: 's_sku',
                                                nested: true
                                            },
                                            {
                                                name: 'Planned Quantity',
                                                value: 'i_planned_quantity'
                                            },
                                            {
                                                name: 'Actual Quantity',
                                                value: 'i_actual_quantity'
                                            },
                                            {
                                                name: 'Located',
                                                value: ''
                                            }
                                        ]}
                                    />
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                }
                </Col>
            </Row>
        </div>
    );
}