export const formikMapping = [
    's_sku',
    's_commodity',
    'f_weight_kg',
    's_dims',
    's_notes'
]

export const tableMapping = [
    {
        name: 'SKU',
        value: 's_sku'
    },
    {
        name: 'Commodity',
        value: 's_commodity'
    },
    {
        name: 'Weight KG',
        value: 'f_weight_kg'
    },
    {
        name: 'Dimensions',
        value: 's_dims'
    },
    {
        name: 'Notes',
        value: 's_notes'
    },
    {
        name: 'Last modified',
        value: 't_modified',
        datetime: true
    },
    {
        name: 'Modified by',
        value: 's_modified_by',
        email: true
    }
]