import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';
import tz from 'moment-timezone';
import ReactTooltip from 'react-tooltip';
import { Col, Card, CardBody } from 'reactstrap';

const CompanyCard = ({ 
    company, 
    viewCompany,
    selectedType,
    awbs,
    checkIsWaiting,
    timeSince
}) => {

    const [isWaiting, setIsWaiting] = useState(false);

    useEffect(() => {
        setIsWaiting(checkIsWaiting(company, awbs, selectedType));
    }, [company, awbs, selectedType]);

    const getTruckingCoTimeWaiting = (company) => {
        return timeSince(company.t_kiosk_submitted);
    };

    const resolveBackground = (company) => {

        if (isWaiting) {
            const { s_state } = company;

            switch(s_state) {
                case 'EXPORT':
                    return '#6BB4DD';
                case 'IMPORT':
                    // return '#6BB4DD';
                    return '#61B996';
                case 'MIXED': 
                    return 'goldenrod';
            }
        }
        return 'grey'
    };

    const resolveFlashit = (company) => {
        const start = moment(company.t_kiosk_submitted)
        const now = moment();
        const diff = moment.duration(moment(now).diff(moment(start)));
        var days = parseInt(diff.asDays()); //84
        var hours = parseInt(diff.asHours()); //2039 hours, but it gives total hours in given miliseconds which is not expacted.
        hours = hours - days*24;  // 23 hours
        var minutes = parseInt(diff.asMinutes()); //122360 minutes,but it gives total minutes in given miliseconds which is not expacted.
        minutes = minutes - (days*24*60 + hours*60);
        minutes = minutes < 10 ? `0${minutes}` : minutes;
        hours = hours - 4;

        console.log(company.s_trucking_company);
        console.log(hours);
        console.log(minutes);

        if(hours > 1) {
            return true;
        }
        return false;
    };

    const resovleOwnershipName = (email) => {
        return email && email.toUpperCase().replace('@CHOICE.AERO', '') || '';
    }

    const resolveWaitingTime = () => {
        const ownershipTime = moment.utc(company.t_counter_ownership).format('MM/DD/YYYY hh:mm A');
        const timeWaitingSinceOwnership = timeSince(company.t_counter_ownership);
        return `${ownershipTime} ${timeWaitingSinceOwnership}`;
    }

    const resolveTruckingCompanyName = (name) => {
        if (name.length > 20) {
            return `${name.substr(0, 28)}..`;
        }
        return name;
    }

    return(
        <Col md={12} className={`px-2 company-card-hover my-3`} onClick={() => viewCompany(company)}>
            <Card className={`card my-2 px-0 ${resolveFlashit(company) ? 'flashit' : null}`} style={{borderRadius: '0.75rem', backgroundColor: resolveBackground(company), boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', fontSize: '16px'}}>
                <CardBody className="card-body-waiting px-3 pt-3 pb-1">
                    <ReactTooltip />
                    <h5 data-tip={company.s_trucking_company} className={`mt-0 mb-2`}>{resolveTruckingCompanyName(company.s_trucking_company)}</h5>
                    <div style={{position: 'absolute', right: '2%', top: '10%'}}>
                        <span className="bg-info px-1 ml-1">{company.exportCount}</span>
                        <span className="bg-success px-1">{company.importCount}</span>
                    </div>
                    <div className={`d-flex align-content-center flex-wrap justify-content-center my-auto waiting-card-subtitle bg-white mb-1 text-center`}>
                        {company.s_trucking_driver}
                    </div>
                    <div className="mb-0 mt-2" >
                        <p style={{float: 'left', fontSize: '16px'}} className='mb-0'>{moment.utc(company.t_kiosk_submitted).format('MM/DD/YYYY hh:mm A')}</p>
                        <p style={{float: 'right', fontSize: '16px'}} className="mb-0">{getTruckingCoTimeWaiting(company)}</p>
                    </div>
                </CardBody>
                {
                    !isWaiting && 
                    <div className='div-processing-agent' style={{boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }}>
                        <p className='mb-0'>Agent: {resovleOwnershipName(company.s_counter_ownership_agent)}</p>
                        <p className='mb-0'>{resolveWaitingTime()}</p>
                    </div>
                }
            </Card>
        </Col>
    );
}

export default CompanyCard;