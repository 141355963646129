import React, {useEffect, useRef, useState} from 'react';
import moment from 'moment';
import {
    CustomInput,
  } from "reactstrap";
import Switch from "rc-switch";
import "rc-switch/assets/index.css";
import {Row, Col, Table, Card, CardBody} from 'reactstrap';

const Master = ({
    s_mawb,
    s_origin,
    s_port_of_unlading,
    s_destination,
    i_pieces,
    i_weight,
    s_commodity,
    b_dg,
    handleBdg,
    b_screened,
    handleInput,
    resolveBorder,
    s_shc1,
    s_shc2,
    s_shc3,
    s_shc4,
    s_shc5
}) => {

    const shc1Ref = useRef(null);
    const shc2Ref = useRef(null);
    const shc3Ref = useRef(null);
    const shc4Ref = useRef(null);
    const shc5Ref = useRef(null);

    const useInitialfocus = () => {
        let ref = shc1Ref;

        if(s_shc1 && s_shc1.length === 3) {
            ref = shc2Ref;
        } if (s_shc2 && s_shc2.length === 3) {
            ref = shc3Ref;
        } if (s_shc3 && s_shc3.length === 3) {
            ref = shc4Ref;
        } if (s_shc4 && s_shc4.length === 3) {
            ref = shc5Ref;
        }

        useEffect(() => {
            if(s_shc1.length === 3) {
                ref.current.focus();
            }
        }, [ref]);
    };

    useInitialfocus();

    return (
        <Row className='py-2' style={{fontSize: '16px'}}>
            <Col md={12}>
                <Card className='custom-opacity-card' style={{borderRadius: '0.75rem'}}>
                    <CardBody className='custom-card-transparent pt-3 pb-0' style={{backgroundColor: 'rgba(255,255,255,0.2)'}}>
                        <h4>Master:</h4>
                        <Table striped>
                            <thead>
                                <tr></tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="col">AWB</th>
                                    <th scope="col">Origin</th>
                                    <th scope="col">POU</th>
                                    <th scope="col">Destination</th>
                                </tr>
                                <tr>
                                    <th scope="row">{s_mawb}</th>
                                    <td><input type="text"  id={'s_origin'} value={s_origin} onChange={(e) => handleInput(e)} className="form-control" style={{maxWidth: '200px', border: resolveBorder(s_origin)}} /></td>
                                    <td><input type="text" id='s_port_of_unlading' value={s_port_of_unlading} onChange={(e) => handleInput(e)} className="form-control" style={{maxWidth: '180px', border: resolveBorder(s_port_of_unlading)}}/></td>
                                    <td><input type="text" id={'s_destination'} value={s_destination} onChange={(e) => handleInput(e)}  className="form-control" style={{maxWidth: '200px', border: resolveBorder(s_destination)}} /></td>
                                </tr>
                                <tr>
                                    <th scope="col">Pieces</th>
                                    <th scope="col">Weight</th>
                                    <th scope="col" colspan="2">Commodity</th>
                                </tr>
                                <tr>
                                    <td><input type="number" id='i_pieces' value={i_pieces} onChange={(e) => handleInput(e)} className="form-control" style={{maxWidth: '90px', border: resolveBorder(i_pieces)}} /></td>
                                    <td><input type="number" id='i_weight' value={i_weight} onChange={(e) => handleInput(e)} className="form-control" style={{maxWidth: '90px', border: resolveBorder(i_weight)}} /></td>
                                    <td colspan="2"><input type="text" id='s_commodity' value={s_commodity} onChange={(e) => handleInput(e)} className="form-control" style={{maxWidth: '310px', border: resolveBorder(s_commodity)}}/></td>
                                </tr>
                                <tr>
                                    <th scope="col">DG</th>
                                    <th scope="col" colspan="2">Special Handling Codes</th>
                                    <th scope="col">Screened</th>
                                </tr>
                                <tr>
                                <td>
                                    <Switch
                                        className="switch-dg"
                                        checked={b_dg}
                                        onClick={handleBdg}
                                        style={{border: '2px rgb(95, 153, 247) solid'}}
                                    />  
                                    </td>
                                    <td colspan="2">
                                        <input type="text"  ref={shc1Ref} id='s_shc1' value={s_shc1} onChange={(e) => handleInput(e)} className="form-control mr-2" style={{maxWidth: '55px', float: 'left'}} maxlength="3" />
                                        <input type="text"  ref={shc2Ref} id='s_shc2' value={s_shc2} onChange={(e) => handleInput(e)} className="form-control mr-2" style={{maxWidth: '55px', float: 'left'}} maxlength="3" />
                                        <input type="text"  ref={shc3Ref} id='s_shc3' value={s_shc3} onChange={(e) => handleInput(e)} className="form-control mr-2" style={{maxWidth: '55px', float: 'left'}} maxlength="3" />
                                        <input type="text"  ref={shc4Ref} id='s_shc4' value={s_shc4} onChange={(e) => handleInput(e)} className="form-control mr-2" style={{maxWidth: '55px', float: 'left'}} maxlength="3" />
                                        <input type="text"  ref={shc5Ref} id='s_shc5' value={s_shc5} onChange={(e) => handleInput(e)} className="form-control mr-2" style={{maxWidth: '55px', float: 'left'}} maxlength="3" />
                                    </td>
                                    <td style={{fontWeight: 'bold', fontSize: '16px'}}>{b_screened ? 'Yes' : 'No'}</td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </Table>
                    </CardBody>
                </Card>
            </Col>
        </Row>

    );
}

export default Master;