import React, { useState, useEffect } from 'react';
import { Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Row, Col } from 'reactstrap';
import axios from 'axios';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { chargePaymentTypes } from '../../utils/data';

export default ({
    modal,
    setModal,
    addCharge,
    selectedAwb,
    importValues,
    asyncHandler,
    email,
    eosWebApiUrl,
    eosWebAuthCode,
    setPayments
}) => {

    const acceptPaymentTypes = ['CASH', 'CHECK'];
    const [f_amount, set_f_amount] = useState(0);
    const [s_payment_type, set_s_payment_type] = useState('');
    const [s_notes, set_s_notes] = useState('');
    const [s_payment_reference, set_s_payment_reference] = useState('');
    const [chargeAmount, setChargeAmount] = useState(0);
    const [selectedCharge, setSelectedCharge] = useState(chargePaymentTypes[0]);

    useEffect(() => {
        if (addCharge) {
            set_s_payment_type(chargePaymentTypes[0].name);
        } else {
            set_s_payment_type(acceptPaymentTypes[0]);
        }

        setChargeAmount(0);
        set_f_amount(0);
        set_s_notes('');
    }, [modal]);

    const toggle = () => setModal(!modal);

    const addPaymentOrCharge = asyncHandler(async() => {

        let endpoint;

        const now = moment().local().format('MM/DD/YYYY HH:mm');

        const payments = {};

        payments.s_payment_type = s_payment_type;
        payments.s_awb = selectedAwb.s_mawb;
        payments.s_hawb = importValues.s_hawb;
        payments.f_amount = addCharge ? Math.abs(f_amount) * -1 : f_amount;
        payments.s_name = selectedAwb.s_trucking_driver;
        payments.t_created_date = now;
        payments.s_unit = selectedAwb.s_unit;
        payments.s_created_by = email;
        payments.t_created = now;
        payments.s_notes = s_notes;

        if (!addCharge) {
            payments.s_payment_method = 'PAYMENT'; //updated
            endpoint = 'addPayment';
        } else {
            payments.s_payment_method = 'CHARGE'; //updated
            endpoint = 'addCharge';
        }

        const counter = {
            s_unit: selectedAwb.s_unit,
            s_mawb: selectedAwb.s_mawb,
            s_airline: selectedAwb.s_airline,
            s_payment_reference: s_payment_reference,
            s_type: payments.s_payment_type,
            f_amount: f_amount,
            t_created: now,
            s_created_by: email,
            t_modified: now,
            s_modified_by: email,
            s_hawb: importValues.s_hawb,
            d_payment_date: now,
            s_mawb_id: selectedAwb.s_mawb_id,
            s_guid: uuidv4(),
            s_status: 'PROCESSED',
            s_notes: s_notes          
        };

        const data = {
            payments
        }

        if (!addCharge) {
            data.counter = counter;
        }

        const res = await axios.post(`${eosWebApiUrl}/${endpoint}`, {
            data
        }, {
            headers: {
                Authorization: `Bearer ${eosWebAuthCode}`
            }
        });

        setPayments(res.data);
        setModal(false);
    });

    useEffect(() => {
        const useAmount = parseFloat(chargeAmount) > 0 ? parseFloat(chargeAmount) : 0;

        if (addCharge) {
            const type = chargePaymentTypes.find(t => t.name === s_payment_type);
            if (type) {
                setSelectedCharge(type);
                const total = useAmount * type.multiplier;
                set_f_amount(total);
            }
        } else {
            set_f_amount(useAmount + 20);
        }
    }, [addCharge, chargeAmount, s_payment_type]);

    return (
        <Modal isOpen={modal} toggle={toggle} size={'lg'}>
            <ModalHeader>{ addCharge ? 'ADD CHARGE' : 'ACCEPT PAYMENT' } </ModalHeader>
            <ModalBody>
                <FormGroup>
                    <Label>Type</Label>
                    <select value={s_payment_type} className={'form-control'} onChange={(e) => set_s_payment_type(e.target.value)}>
                    {
                        addCharge ? 
                            chargePaymentTypes.map((type, i) =>
                                <option key={i} value={type.name}>
                                    {type.name}
                                </option>
                            ) :
                            acceptPaymentTypes.map((t, i) =>
                                <option key={i} value={t}>{t}</option>
                            ) 
                            
                    }
                    </select>
                </FormGroup>
                {
                    !addCharge && s_payment_type === 'CHECK' && 
                    <FormGroup>
                        <Label>Check Number</Label>
                        <Input value={s_payment_reference} type={'text'} onChange={(e) => set_s_payment_reference(e.target.value)} />
                    </FormGroup>
                }
                {
                    addCharge ?
                        <FormGroup>
                            <Row>
                                <Col md={4}>
                                    <Label>Count</Label>
                                    <Input type={'number'} value={chargeAmount} onChange={(e) => setChargeAmount(e.target.value)} />
                                </Col>
                                <Col md={4}>
                                    <Label>UOM</Label>
                                    <Input disabled value={selectedCharge.uom || ''} />
                                </Col>
                                <Col md={4}>
                                    <Label>Total</Label>
                                    <Input disabled value={f_amount} />
                                </Col>
                            </Row>
                        </FormGroup> :
                        <>
                            <FormGroup>
                                <Label>Amount</Label>
                                <Input type={'number'} value={chargeAmount} onChange={(e) => setChargeAmount(e.target.value)} />
                            </FormGroup>
                            <FormGroup>
                                <Label>Counter Fee</Label>
                                <Input disabled value={'$20.00'} />
                            </FormGroup>
                            <FormGroup>
                                <Label>Amount Due:</Label>
                                <Input disabled value={f_amount}  />
                            </FormGroup>
                        </>
                }
                <FormGroup>
                    <Label>Notes</Label>
                    <Input value={s_notes} type={'textarea'} onChange={(e) => set_s_notes(e.target.value)} />
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={() => addPaymentOrCharge()}>Submit</Button>{' '}
                <Button color="secondary" onClick={() => toggle()}>Cancel</Button>
            </ModalFooter>
        </Modal>
    );
}
