import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input } from 'reactstrap';
import { Formik, Field } from 'formik';
import moment from 'moment';
import FormikCheckbox from '../custom/FormikCheckbox';

export default ({
    open,
    toggle,
    addContainer,
    newContainer,
    selectedContainer
}) => {

    const statusOptions = ['EXPECTED', 'ARRIVED', 'EMPTY', 'PICKED-UP'];
    const mapping = [
        's_container',
        'd_expected_arrival',
        'd_actual_arrival',
        's_status',
        's_notes'
    ];

    const [initialValues, setInitialValues] = useState({});

    useEffect(() => {
        console.log(selectedContainer);
        const values = {};
        for (let i = 0; i < mapping.length; i++) {
            const key = mapping[i];
            if (newContainer) {
                if (key === 's_status') {
                    values[key] = statusOptions[0];
                } else {
                    values[key] = '';
                }
            } else {
                const dateKeys = ['d_expected_arrival', 'd_actual_arrival'];
                if (dateKeys.indexOf(key) !== -1) {
                    values[key] = moment.utc(selectedContainer[key]).format('YYYY-MM-DD');
                } else {
                    values[key] = selectedContainer && selectedContainer[key];
                }
            }
        }
        setInitialValues(values);
    }, [open]);

    return (
        <div>
            <Modal isOpen={open} toggle={toggle} size={'lg'} style={{maxWidth: '1000px', width: '100%'}}>
                <ModalHeader>Add Container</ModalHeader>
                    <Formik
                        initialValues={initialValues}
                        validate={values => {
                            const errors = {};
                            const include = ['s_container', 'd_expected_arrival'];
                            for (let key in values) {
                                if (include.indexOf(key) !== -1) {
                                    if (values[key].length === 0) {
                                        errors[key] = `${key} has an invalid value: ${values[key]}`;
                                    }
                                }
                            }
                            return errors;
                        }}
                        validateOnMount={true}
                        enableReinitialize={true}
                    >
                        {({ isValid, values, setFieldValue }) => (
                            <>
                                <ModalBody>
                                    <Form>
                                        <FormGroup>
                                            <Label>Container Number</Label>
                                            <Field type='text' name='s_container' className="form-control" />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>Expected Arrival</Label>
                                            <Input type='date' value={values.d_expected_arrival} onChange={(e) => setFieldValue('d_expected_arrival', e.target.value)} />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>Actual Arrival</Label>
                                            <Input type='date' value={values.d_actual_arrival} onChange={(e) => setFieldValue('d_actual_arrival', e.target.value)} />
                                        </FormGroup>
                                        {/* <FormGroup>
                                            <FormikCheckbox 
                                                name={'b_arrived'}
                                                checked={values.b_arrived}
                                                label={'Arrival Confirmation'}
                                                onClick={() => setFieldValue('b_arrived', !values.b_arrived)}
                                            />
                                        </FormGroup> */}
                                        <FormGroup>
                                            <Label>Status</Label>
                                            <Field component='select' name='s_status' className="form-control">
                                            {
                                                statusOptions.map((o, i) =>
                                                    <option value={o} key={i}>{o}</option>
                                                )
                                            }
                                            </Field>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>Notes</Label>
                                            <Field type='text' name='s_notes' className="form-control" />
                                        </FormGroup>
                                    </Form>
                                </ModalBody>
                                <ModalFooter>
                                    <Button color="primary" onClick={() => addContainer(values)} disabled={!isValid}>Submit</Button>{' '}
                                    <Button color="secondary" onClick={toggle}>Cancel</Button>
                                </ModalFooter>
                            </>
                        )}
                    </Formik>
            </Modal>
        </div>
    );
}