import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, Input, Label, FormGroup } from 'reactstrap';
import { Formik, Field } from 'formik';
import { formikMapping } from './skuMapping';

export default ({
    modal,
    setModal,
    newSku,
    selectedSku,
    createUpdateSku,
    deleteSku
}) => {

    const toggle = () => setModal(!modal);
    const [initialValues, setInitialValues] = useState({});

    useEffect(() => {
        const values = {};
        for (let i = 0; i < formikMapping.length; i++) {
            const currentKey = formikMapping[i];
            if (newSku) {
                values[currentKey] = '';
            } else {
                if (selectedSku) {
                    values[currentKey] = selectedSku[currentKey];
                }
            }
        }
        setInitialValues(values);
    }, [selectedSku, newSku, modal]);

    return (
        <div>
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader>{ newSku ? 'Add' : 'Update' } SKU</ModalHeader>
                <Formik
                    initialValues={initialValues}
                    validate={values => {
                        const exclude = ['f_weight_kg', 's_dims', 's_commodity', 's_notes'];
                        const errors = {}
                        for (let key in values) {
                            if (exclude.indexOf(key) === -1 && values[key] === '') {
                                errors[key] = `Invalid value: ${values[key]}`;
                            }
                        } 
                        return errors;
                    }}
                    validateOnMount={true}
                >
                    {({ values, isValid, setFieldValue }) => (
                        <>
                            <ModalBody>
                                <Form>
                                    <FormGroup>
                                        <Label>SKU</Label>
                                        <Field type='text' name='s_sku' className='form-control' />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Commodity</Label>
                                        <Field type='text'  name='s_commodity' className='form-control' />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Weight in KG</Label>
                                        <Field type='number' name='f_weight_kg' className='form-control' />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Dimensions</Label>
                                        <Field component='textarea' name='s_dims' className='form-control' />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Notes</Label>
                                        <Field component='textarea' name='s_notes' className='form-control' />
                                    </FormGroup>
                                </Form>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="primary" disabled={!isValid} onClick={() => createUpdateSku(values)}>Submit</Button>{' '}
                                {
                                    !newSku && 
                                    <Button color='danger' onClick={() => deleteSku(selectedSku.id)} >Delete</Button>
                                }
                                <Button color="secondary" onClick={toggle}>Cancel</Button>
                            </ModalFooter>
                        </>
                    )}
                </Formik>
            </Modal>
        </div>
    );
}