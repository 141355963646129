import React from 'react';
import ContainerTable from './ContainerTable';
import { Row, Col, Button } from 'reactstrap';

export default ({
    data,
    setSelectedContainer,
    setModalAddContainerOpen,
    setModalUpdateContainerOpen,
    handleAddContainer,
    handleUpateContainer
}) => {

    return (
        <div className='px-5'>
            <Row>
                <Col md={12}>
                    <Button onClick={() => handleAddContainer()}>Add New</Button>
                </Col>
            </Row>
            <Row className='mt-2'>
                <Col md={12}>
                    <ContainerTable 
                        data={data}
                        setSelectedContainer={setSelectedContainer}
                        setModalUpdateContainerOpen={setModalUpdateContainerOpen}
                        numRows={20}
                        extendedMappingType={true}
                        handleUpateContainer={handleUpateContainer}
                    />
                </Col>
            </Row>
        </div>
    );
}