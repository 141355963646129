import React from 'react';
import moment from 'moment';

const CreateAcceptanceSheet = ({
    b_dg,
    b_screened,
    s_mawb,
    i_pieces,
    i_weight,
    s_transport_type,
    s_airline_code,
    s_airline,
    t_depart_date,
    s_origin,
    s_destination,
    s_port_of_unlading,
    s_commodity,
    s_iac,
    s_ccsf,
    s_shc1,
    s_shc2,
    s_shc3,
    s_shc4,
    s_shc5,
    s_company_driver_name,
    s_company,
    s_company_driver_id_type_1,
    s_company_driver_id_num_1,
    d_company_driver_id_expiration_1,
    b_company_driver_photo_match_1,
    s_company_driver_id_type_2,
    s_company_driver_id_num_2,
    d_company_driver_id_expiration_2,
    b_company_driver_photo_match_2,
    s_kiosk_submitted_agent,
    getProcessAgentName,
    agentName,
    user,
    t_created
}) => {
    return (
        <div dangerouslySetInnerHTML={{ __html: `
            <!DOCTYPE html>
            <html>
                <head>
                    <link rel="stylesheet" type="text/css" media="print" href="print.css" />
                </head>
                <h4 id='export-acceptance'>EXPORT ACCEPTANCE <span class='version'>v1.9</span></h4>

                <table class="ticket-door" style='margin-top: 0px;'>
                    <thead>
                        <tr>
                            <th width=28%>TICKET #:</th>
                            <th width=27%>DOOR #:</th>
                            <th width=22%>DG: ${b_dg ? 'YES' : 'NO'}</th>
                            <th width=23%>CCSF: ${b_screened ? 'YES' : 'NO'}</th>
                        </tr>
                    </thead>
                </table>

                <img src='https://ewrstorage1.blob.core.windows.net/pics/choice-logo.png' class='logo'>

                <div class='main-tables'>
                    <table class="customTable" style='margin-top: 0px;'>
                        <tbody>
                            <tr>
                                <td width=40%>AWB:<span id='text-awb'>${s_mawb}</span></td>
                                <td width=20%>PCS: ${i_pieces}</td>
                                <td width=20%>WEIGHT: ${i_weight}</td>
                                <td width=20%>FLIGHT TYPE: ${s_transport_type}</td>
                            </tr>
                        </tbody>
                    </table>
                    <table class="customTable">
                        <tbody>
                            <tr>
                            <td width=25%>FLIGHT #: ${s_airline_code}</td>
                            <td width=25%>AIRLINE: ${s_airline}</td>
                            <td width=25%>DATE: ${t_depart_date}</td>
                            <td width=25%>ORIGIN: ${s_origin && s_origin.toUpperCase()}</td>
                            </tr>
                        </tbody>
                        </table>
                    <table class="customTable">
                        <tbody>
                            <tr>
                            <td width=20%>DESTINATION: ${s_destination && s_destination.toUpperCase()} </td>
                            <td width=20%>PORT UNLOADING: ${s_port_of_unlading && s_port_of_unlading.toUpperCase()}</td>
                            <td width=20%>COMMODITY: ${s_commodity && s_commodity.toUpperCase()}</td>
                            <td width=20%>IAC: ${s_iac}</td>
                            <td width=20%>CCSF: ${s_ccsf && s_ccsf !== null ? s_ccsf : ''}</td>
                            </tr>
                        </tbody>
                    </table>
                    <table class="customTable">
                        <tbody>
                            <tr>
                                <td width=40%>TRANSPORT: ${s_transport_type}</td>
                                <td width=12%>SHC1: ${s_shc1 ? s_shc1.toUpperCase() : ''}</td>
                                <td width=12%>SHC2: ${s_shc2 ? s_shc2.toUpperCase() : ''}</td>
                                <td width=12%>SHC3: ${s_shc2 ? s_shc2.toUpperCase() : ''}</td>
                                <td width=12%>SHC4: ${s_shc4 ? s_shc4.toUpperCase() : ''}</td>
                                <td width=12%>SHC5: ${s_shc5 ? s_shc5.toUpperCase() : ''}</td>
                            </tr>
                        </tbody>
                    </table>
                    <table class="customTable">
                        <tbody>
                            <tr>
                                <td width=40%>DRIVER NAME: ${s_company_driver_name && s_company_driver_name.toUpperCase()}</td>
                                <td width=60%>TRUCKING COMPANY: ${s_company && s_company.toUpperCase()}</td>
                            </tr>
                        </tbody>
                    </table>
                    <table class="customTable">
                        <tbody>
                            <tr>
                                <td width=20%>1ST ID TYPE: ${s_company_driver_id_type_1 && s_company_driver_id_type_1.toUpperCase()}</td>
                                <td width=30%>ID NUMBER: ${s_company_driver_id_num_1 && s_company_driver_id_num_1.toUpperCase()}</td>
                                <td width=25%>ID EXPIRATION: ${d_company_driver_id_expiration_1 !== '' ? moment(d_company_driver_id_expiration_1).format('MM/DD/YYYY') : 'N/A'}</td>
                                <td width=25%>MATCHING PHOTO: ${b_company_driver_photo_match_1 ? 'YES' : 'NO'}</td>
                            </tr>
                        </tbody>
                    </table>
                    <table class="customTable">
                        <tbody>
                            <tr>
                                <td width=20%>2ND ID TYPE: ${s_company_driver_id_type_2 !== null ? s_company_driver_id_type_2.toUpperCase() : 'N/A'}</td>
                                <td width=30%>ID NUMBER: ${s_company_driver_id_num_2 !== null ? s_company_driver_id_num_2.toUpperCase() : 'N/A'}</td>
                                <td width=25%>ID EXPIRATION: ${d_company_driver_id_expiration_2 && d_company_driver_id_expiration_2 !== '' ? moment(d_company_driver_id_expiration_2).format('MM/DD/YYYY') : 'N/A'}</td>
                                <td width=25%>MATCHING PHOTO: ${b_company_driver_photo_match_2 ? 'YES' : 'N/A'}</td>
                            </tr>
                        </tbody>
                    </table>
                    <table class="customTable" style='border-bottom: 4px black solid;'>
                        <tbody>
                            <tr>
                                <td width=50%>ACCEPTANCE AGENT: ${getProcessAgentName ? agentName.toUpperCase() : user && user.name.toUpperCase()}</td>
                                <td width=50%>DATE/TIME: ${moment.utc(t_created).format('YYYY-MM-DD HH:mm:00')}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div class='div-acceptance'>
                        <h4 class='no-margin'>ACCEPTANCE<span style='margin-left: 80px;'>PIECE:______________________ WEIGHT:______________________</span></h4>
                    </div>

                    <table class="borderless-table">
                        <tbody>
                            <tr>
                                <td width=10% class='table-left'></td>
                                <td width=10%>PCS</td>
                                <td width=10%>L</td>
                                <td width=10%>W</td>
                                <td width=10%>H</td>
                                <td width=10%>PCS</td>
                                <td width=10%>L</td>
                                <td width=10%>W</td>
                                <td width=10%>H</td>
                            </tr>
                            <tr>
                                <td width=10% class='table-left small-font2'>CONDITION</td>
                                <td width=10%>______</td>
                                <td width=10%>______</td>
                                <td width=10%>______</td>
                                <td width=10%>______</td>
                                <td width=10%>______</td>
                                <td width=10%>______</td>
                                <td width=10%>______</td>
                                <td width=10%>______</td>
                            </tr>
                            <tr>
                                <td width=10% class='table-left small-font2'><span>&#9744;</span>SIGNS TAMPERING</td>
                                <td width=10%>______</td>
                                <td width=10%>______</td>
                                <td width=10%>______</td>
                                <td width=10%>______</td>
                                <td width=10%>______</td>
                                <td width=10%>______</td>
                                <td width=10%>______</td>
                                <td width=10%>______</td>
                            </tr>
                            <tr>
                                <td width=10% class='table-left small-font2'><span>&#9744;</span>VISUAL LEAKAGES</td>
                                <td width=10%>______</td>
                                <td width=10%>______</td>
                                <td width=10%>______</td>
                                <td width=10%>______</td>
                                <td width=10%>______</td>
                                <td width=10%>______</td>
                                <td width=10%>______</td>
                                <td width=10%>______</td>
                            </tr>
                            <tr>
                                <td width=10% class='table-left small-font2'><span>&#9744;</span>CONTENT INCONSISTENCIES</td>
                                <td width=10%>______</td>
                                <td width=10%>______</td>
                                <td width=10%>______</td>
                                <td width=10%>______</td>
                                <td width=10%>______</td>
                                <td width=10%>______</td>
                                <td width=10%>______</td>
                                <td width=10%>______</td>
                            </tr>
                            <tr>
                                <td width=10% class='table-left small-font2'><span>&#9744;</span>SUSPICIOUS CONDITIONS</td>
                                <td width=10%>______</td>
                                <td width=10%>______</td>
                                <td width=10%>______</td>
                                <td width=10%>______</td>
                                <td width=10%>______</td>
                                <td width=10%>______</td>
                                <td width=10%>______</td>
                                <td width=10%>______</td>
                            </tr>
                            <tr style='height: 20px;'>
                                <td width=10% class='table-left small-font2'>DAMAGES</td>
                            </tr>
                        </tbody>
                    </table>
                    <table class="customTable" style='border-bottom: 4px black solid;'>
                        <tbody>
                            <tr>
                                <td width=70%>W/H AGENT PRINT NAME</td>
                                <td width=30%>DATE/TIME</td>
                            </tr>
                        </tbody>
                    </table>
                    <div class='div-screening' style='border: 2px solid black;'>
                        <div class='div-screening-tables'>
                            <table style='text-align: left; float: left; width: 16%;'>
                                <tbody>
                                    <tr>
                                        <td width=10% class='table-left screening-heading'><span style='font-weight: bolder; font-size: 16px;'>SCREENING</span></td>
                                    </tr>
                                    <tr>
                                        <td width=10% class='table-left screening-heading small-font2'>TOTAL PIECE</td>
                                    </tr>
                                    <tr>
                                        <td width=10% class='table-left' style='padding-left: 33px;'>${i_pieces}</td>
                                    </tr>
                                    <tr>
                                        <td style='line-height: 0px; position: relative; bottom: 10px;'>___________</td>
                                    </tr>
                                </tbody>
                            </table>
                            <table style='text-align: left; float: left; width: 16%;'>
                                <tbody class='small-font2'> 
                                    <tr>
                                        <td width=10% style='font-size: 14px;' class="small-font2">SCREENING TYPE</td>
                                    </tr>
                                    <tr>
                                        <td width=10%>______ PHYSICAL</td>
                                    </tr>
                                    <tr>
                                        <td width=10%>______ X-RAY</td>
                                    </tr>
                                    <tr>
                                        <td width=10%>______ ETD</td>
                                    </tr>
                                    <tr>
                                        <td width=10%>______ TSA K-9</td>
                                    </tr>
                                    <tr>
                                        <td width=10%>______ CCSF</td>
                                    </tr>
                                    <tr>
                                        <td width=10%>______ 3P K-9</td>
                                    </tr>
                                </tbody>
                            </table>
                            <table style='text-align: left; float: left; width: 68%'>
                                <tbody>
                                    <tr>
                                        <td width=27% class='small-font2'>ALTERNATIVE SCREENING MEASURES</td>
                                    </tr>
                                    <tr class='screening-row-height'>
                                        <td width=27% class='small-font'><span class='box'>&#9633;</span><span class='screening-text'>Medical Shipments lv3.6.1</span></td>
                                    </tr>
                                    <tr class='screening-row-height'>
                                        <td width=27% class='small-font'><span class='box'>&#9633;</span><span class='screening-text'>Human Remains lv3.6.2</span></td>
                                    </tr>
                                    <tr class='screening-row-height'>
                                        <td width=27% class='small-font'><span class='box'>&#9633;</span><span class='screening-text'>Live Animals Tendered as Cargo lv3.6.3</span></td>
                                    </tr>
                                    <tr class='screening-row-height'>
                                        <td width=27% class='small-font'><span class='box'>&#9633;</span><span class='screening-text'>Cargo Containing Hazardous Materials  lv.3.6.4.</span></td>
                                    </tr>
                                    <tr class='screening-row-height'>
                                        <td width=27% class='small-font'><span class='box'>&#9633;</span><span class='screening-text'>Cargo Accepted From The Federal Reserve Or U.S. Treasury  lv.3.6.5.</span></td>
                                    </tr>
                                    <tr class='screening-row-height'>
                                        <td width=27% class='small-font'><span class='box'>&#9633;</span><span class='screening-text'>Cargo Accepted Directly From The Department Of Defense  lv.3.6.6.</span></td>
                                    </tr>
                                    <tr class='screening-row-height'>
                                        <td width=27% class='small-font'><span class='box'>&#9633;</span><span class='screening-text' sytle='font-size: 9px;'>Sealed Cargo Traveling Under U.S. Customs & Border Protection Orders Of Export</span></td>
                                    </tr>
                                    <tr class='screening-row-height'>
                                        <td width=27% class='small-font'><span class='box'>&#9633;</span><span class='screening-text'>Cargo Accepted In Support Of U.S. Law Enforcement Activities  lv.3.6.8.</span></td>
                                    </tr>
                                    <tr class='screening-row-height'>
                                        <td width=27% class='small-font'><span class='box'>&#9633;</span><span class='screening-text'>Cargo Accepted From Sealed/Interline</span></td>
                                    </tr>
                                    <tr class='screening-row-height'>
                                        <td width=27% class='small-font'><span class='box'>&#9633;</span><span class='screening-text'>Diplomatic Pouches Accepted From DOS</span></td>
                                    </tr>
                                </tbody>
                            </table>    
                        </div>
                        <table class="customTable" style='border-bottom: 4px black solid;'>
                            <tbody>
                                <tr>
                                    <td width=60%>SCREENER</td>
                                    <td width=40%>DATE/TIME</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <style>

                    * {
                        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
                    }

                    table.customTable {
                        width: 100%;
                        height: 25px;
                        background-color: #FFFFFF;
                        border-collapse: collapse;
                        border-width: 2px;
                        border-style: solid;
                        color: #000000;
                        font-size: 12px;
                        border-bottom: 0px;
                    }

                    table.customTable td, table.customTable th {
                        border-width: 2px;
                        border-color: black;
                        border-style: solid;
                        padding: 5px;
                        border-bottom: 0px;
                    }

                    table.customTable thead {
                        background-color: black;
                    }

                    table.ticket-door {
                        text-align: left;
                        margin-left: 30%; 
                        /* margin-right:20%; */
                        border-width: 2px;
                        border-color: black;
                        border-style: solid;
                        border-collapse: collapse;
                        width: 300px;
                        height: 100px;
                        line-height: 20px;
                        margin-bottom: 0px;
                        padding-bottom: 0px;
                        border-bottom: 0px;
                        font-size: 14px;
                        /* position: fixed;
                        left: 10%; */
                    }

                    table.ticket-door th {
                        text-align: left;
                        border-width: 2px;
                        border-color: black;
                        border-style: solid;
                    }

                    table.borderless-table {
                        height: 25px;
                        text-align: center;
                        border-width: 2px;
                        border-color: black;
                        border-style: solid;
                        font-size: 12px;
                        border-bottom: 0px;
                    }

                    table.borderless-table th {
                        text-align: center;
                        border-width: 2px;
                        border-color: black;
                        border-style: solid;
                    }

                    .table-left {
                        font-size: 14px;
                        text-align: left;
                    }

                    #export-acceptance {
                        float: left;
                        font-weight: bold;
                        color: red;
                        margin-bottom: 0px;
                        position: relative;
                        bottom: 13px;
                    }

                    #title {
                        float: right;
                        font-weight: bold;
                        position: relative;
                        bottom: 40px;
                        margin-bottom: 0px;
                        font-size: 16px;
                    }

                    #text-awb {
                        font-size: 26px;
                    }

                    .line{
                        width: 350px;
                        height: 15px;
                        border-bottom: 1px solid black;
                        position: relative;
                    }

                    .no-margin {
                        margin: 0px 0px 0px 0px;
                    }

                    .div-acceptance {
                        width: 800px; 
                        height: 30px; 
                        margin-top: 3px;  
                        margin-bottom: -10px;
                        font-size: 14px;
                    }

                    .div-screening {
                        margin-top: 4px;
                    }

                    .small-font {
                        font-size: 10px;
                    }

                    .small-font2 {
                        font-size: 12px;
                    }

                    .version {
                        font-size: 10px;
                    }

                    .box {
                        font-size:68px;
                    }

                    .screening-text {
                        position: relative;
                        bottom: 14px;
                    }

                    .screening-row-height {
                        line-height: 0.11;
                    }

                    .logo {
                        float: right;
                        font-weight: bold;
                        position: relative;
                        bottom: 40px;
                        height: auto;
                        width: 140px;
                        margin-bottom: 0px;
                    }

                    .screening-heading {
                        font-size: 15px;
                    }

                    .main-tables {
                        margin-top: 0px;
                        padding-top: 0px;
                        position: relative;
                        bottom: 40px;
                    }
                </style>
                <script>
                    setTimeout(() => {
                        window.print();
                    }, 1000);
                </script>
            </html>
        ` }}>

        </div>
    )
}

export default CreateAcceptanceSheet;