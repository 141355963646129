import React from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';

export default ({
    selectedAwb,
    viewMyAssignmentCompany
}) => {
    return (
        <Card style={{borderRadius: '0.75rem'}} onClick={() => viewMyAssignmentCompany && viewMyAssignmentCompany(selectedAwb)} className='card-hover hover-pointer'>
            <CardBody className='custom-card-opacity py-3'>
                <Row>
                    <Col md={3}>
                        <img src={`${selectedAwb.s_driver_photo_link || 'https://media.istockphoto.com/vectors/default-profile-picture-avatar-photo-placeholder-vector-illustration-vector-id1214428300?b=1&k=6&m=1214428300&s=612x612&w=0&h=kMXMpWVL6mkLu0TN-9MJcEUx1oSWgUq8-Ny6Wszv_ms='}`} style={{ borderRadius: '50%', width: '110px', height: '110px' }} />
                    </Col>
                    <Col md={9}>
                        <h4>Customer Details:</h4>
                        <h6>{selectedAwb.s_trucking_company}</h6>
                        <h6>{selectedAwb.s_trucking_driver}</h6>
                        <h6>{selectedAwb.s_trucking_email}</h6>
                        <h6>{selectedAwb.s_trucking_cell || 'No Cellphone Entered'} - SMS {selectedAwb.b_trucking_sms ? 'Enabled' : 'Disabled'}</h6>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
}