export const units = [
    'CEWR1',
    'CIAD1',
    'CBOS1',
    'CJFK1', 
    'CJFK2',
    'CORD1'
];

export const chargePaymentTypes = [
    {
        name: 'STORAGE',
        multiplier: 1
    },
    {
        name: 'IMPORT - COLLECT CHARGES',
        multiplier: 1
    },
    {
        name: 'EXPORT - COLLECT CHARGES',
        multiplier: 1
    },
    {
        name: 'PERISHABLE STORAGE',
        multiplier: 1.5,
        uom: 'KG'
    },
    {
        name: 'B/D FEE LD3',
        multiplier: 150,
        uom: 'ULD'
    },
    {
        name: 'B/D FEE LD7',
        multiplier: 300,
        uom: 'ULD'
    },
    {
        name: 'B/D FEE MD',
        multiplier: 450,
        uom: 'ULD'
    },
    {
        name: 'POST ENTRY',
        multiplier: 150,
        uom: 'AWB'
    },
    {
        name: 'B/D FEE PER HAWB',
        multiplier: 0.36,
        uom: 'KG'
    },
    {
        name: 'VAL STORAGE',
        multiplier: 1.5,
        uom: 'KG'
    },
    {
        name: 'GO PROCESSING',
        multiplier: 105,
        uom: 'AWB'
    },
    {
        name: 'AFTER HOURS',
        multiplier: 200,
        uom: 'EVENT'
    },
    {
        name: 'BATTERY CHARGE',
        multiplier: 80,
        uom: 'SET 16'
    },
    {
        name: 'SKID',
        multiplier: 10,
        uom: 'UNIT'
    },
    {
        name: 'ISC',
        multiplier: 125,
        uom: 'UNIT'
    },
    {
        name: 'CARRIER HANDLING FEE',
        multiplier: 0.15,
        uom: 'KG'
    }
];