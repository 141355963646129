export const tableArray = [
    {
        letter: 'A',
        start: 104,
        end: 121
    },
    {
        letter: 'B',
        start: 104,
        end: 124
    },
    {
        letter: 'C',
        start: 104,
        end: 124
    },
    {
        letter: 'D',
        start: 104,
        end: 124
    },
    {
        letter: 'E',
        start: 104,
        end: 124
    },
    {
        letter: 'F',
        start: 104,
        end: 124
    },
    {
        letter: 'G',
        start: 101,
        end: 124,
        blanks: [301, 302, 303]
    },
    {
        letter: 'H',
        start: 101,
        end: 124,
        blanks: [301, 302, 303]
    },
    {
        letter: 'I',
        start: 101,
        end: 124,
        blanks: [301, 302, 303]
    },
    {
        letter: 'J',
        start: 101,
        end: 124,
        blanks: [301, 302, 303]
    },
    {
        letter: 'K',
        start: 101,
        end: 124,
        blanks: [301, 302, 303]
    },
    {
        letter: 'L',
        start: 101,
        end: 124,
        blanks: [301, 302, 303]
    },
];