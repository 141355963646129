import React, { useState, useEffect } from 'react';
import { Row, Col, Table } from 'reactstrap';

export default ({
    charges,
    calcPaymentDue,
    formatCost,
    calcTotalCharges
}) => {

    const displayCharge = (charge) => {
        if (charge.negative) {
            return `(${formatCost(charge.cost)})`
        }
        return formatCost(charge.cost);
    }

    const displayPaymentDue = () => {
        const due = calcPaymentDue();
        const formatted = formatCost(Math.abs(due));
        if (due < 0) {
            return `(${formatted})`;
        }
        return formatted;
    }

    return (
        <Row className={'mt-3'}>
            <Col md={12}>
                <h4 className={'float-left'}>Summary</h4>
                <i className={'fas fa-print float-right mt-1'} style={{ fontSize: '16px' }} />
                <Table striped style={{ fontSize: '16px' }}>
                    <tdead></tdead>
                    <tbody>
                        <tr>
                            <td className={'float-left'}>ISC</td>
                            <td className={'float-right'}>
                                ${formatCost(charges.isc.cost)}
                            </td>
                        </tr>
                        <tr>
                            <td className={'float-left'}>
                                Storage
                                {
                                    charges.storage.description && 
                                    <span> - {charges.storage.description}</span>
                                }
                            </td>
                            <td className={'float-right'}>
                                ${formatCost(charges.storage.cost)}
                            </td>
                        </tr>
                        <tr>
                            <td className={'float-left'} style={{ width: '80%' }}>
                                Other
                                {
                                    charges.other.description && 
                                    <span> - {charges.other.description}</span>
                                }
                            </td>
                            <td className={'float-right'}>
                                ${formatCost(charges.other.cost)}
                            </td>
                        </tr>
                        <tr>
                            <td className={'float-left'}>Total Charges</td>
                            <td className={'float-right'}>
                                ${formatCost(calcTotalCharges())}
                            </td>
                        </tr>
                        <tr>
                            <td className={'float-left'}>Total Credits</td>
                            <td className={'float-right'}>
                                $({formatCost(charges.credits.cost)})
                            </td>
                        </tr>
                        <tr>
                            <td className={'float-left'}>Total Paid</td>
                            <td className={'float-right'}>
                                $({formatCost(charges.paid.cost)})
                            </td>
                        </tr>
                        <tr>
                            <td className={'float-left'}>Payment Due</td>
                            <td className={'float-right'}>
                                ${displayPaymentDue()}
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </Col>
        </Row>
    );
}

// {
//     Object.keys(charges).map((key, i) => (
//         <tr key={i}>
//             <td className={'float-left'}>
//                 <span>{charges[key].name}</span>
//                 {
//                     charges[key].description && 
//                     <span> - {charges[key].description}</span>
//                 }
//             </td>
//             <td className={'float-right'}>
//                 { displayCharge(charges[key]) }
//             </td>
//         </tr>
//     ))
// }