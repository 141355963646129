import React, { useState, useEffect, useContext, useRef } from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { AppContext } from '../components/context/index';
import { Row, Col, Button, Card, CardBody, Input } from 'reactstrap';
import { Wizard, Steps, Step } from 'react-albus';
import { Line } from 'rc-progress';
import axios from 'axios';
import moment from 'moment';
import CompanyCard from '../components/dockDelivery/CompanyCard';
import AwbCard from '../components/dockDelivery/AwbCard';
import LocationCard from '../components/dockDelivery/LocationCard';
import SignatureCanvas from 'react-signature-canvas'
import {
    useWindowSize,
    useWindowWidth,
    useWindowHeight,
} from '@react-hook/window-size'

const DeliveryProcess = ({
    step, steps, next, previous, push
}) => {

    const [width, height] = useWindowSize();

    const {
        user, 
        actions,
        eosWebApiUrl,
        eosWebAuthCode,
        s_unit
    } = useContext(AppContext);

    const {
        asyncHandler,
        createSuccessNotification
    } = actions;

    // Dock Data and companies:

    const [dockData, setDockData] = useState([]);
    const [dockCompanies, setDockCompanies] = useState([]);

    const dockDeliveryQuery = asyncHandler(async() => {
        const res = await axios.post(`${eosWebApiUrl}/dockDeliveryQuery`, {
            data: {
                s_unit
            }
        }, {
            headers: {
                Authorization: `Bearer ${eosWebAuthCode}`
            }
        });
        console.log(res.data);
        setDockData(res.data);
        setDockCompanies(resolveDockCompanies(res.data));
    });

    useEffect(() => {
        if (s_unit) {
            dockDeliveryQuery();

            let interval = setInterval(() => {
                dockDeliveryQuery();
            }, 10000);

            return () => {
                clearInterval(interval);
            }
        }
    }, [s_unit]);

    const resolveDockCompanies = (dockData) => {
        const companies = [];
        for (let i = 0; i < dockData.length; i++) {
            const current = dockData[i];
            const { s_trucking_company, s_transaction_id, s_trucking_driver, s_dock_ownership, t_counter_end, s_mawb, s_mawb_id, i_pieces, s_location } = current;

            const existingIndex = companies.findIndex(c => c.s_transaction_id === current.s_transaction_id);

            if (existingIndex !== -1) {
                const existingAwb = companies[existingIndex].awbs.findIndex(a => a.s_mawb_id === s_mawb_id);

                if (existingAwb !== -1) {
                    companies[existingIndex].awbs[existingAwb].locations.push({
                        i_pieces, 
                        s_location,
                        confirmDelivery: false
                    });
                } else {
                    companies[existingIndex].awbs.push({
                        s_mawb,
                        s_mawb_id,
                        locations: [{
                            i_pieces, 
                            s_location,
                            confirmDelivery: false
                        }]
                    });
                }
            } else {
                companies.push({
                    s_trucking_company, 
                    s_transaction_id,
                    s_trucking_driver, 
                    s_dock_ownership,
                    t_counter_end,
                    awbs: [{
                        s_mawb,
                        s_mawb_id,
                        locations: [{
                            i_pieces, 
                            s_location,
                            confirmDelivery: false
                        }]
                    }]
                });
            }
        }
        return companies;
    }

    // Selected Company
    const [selectedCompany, setSelectedCompany] = useState({});
    const [t_dock_ownership, set_t_dock_ownership] = useState('');

    const takeDockDeliveryOwnership = asyncHandler(async(company) => {
        await axios.post(`${eosWebApiUrl}/takeDockDeliveryOwnership`, {
            data: {
                s_transaction_id: company.s_transaction_id,
                s_dock_ownership: user.name,
                s_unit,
                t_dock_ownership: moment().local().format('MM/DD/YYYY HH:mm')
            }
        }, {
            headers: {
                Authorization: `Bearer ${eosWebAuthCode}`
            }
        });

        set_t_dock_ownership(moment().local().format('MM/DD/YYYY HH:mm'));
        // setDockCompanies(resolveDockCompanies(res.data));
    });

    const selectCompany = (company) => {
        setSelectedCompany(company);
        takeDockDeliveryOwnership(company);
        next();
    }

    // Selected Awb
    const [selectedAwb, setSelectedAwb] = useState({});
    const [piecesLocations, setPiecesLocations] = useState([]);

    const awbLocationsQuery = asyncHandler(async(awb) => {
        const res = await axios.post(`${eosWebApiUrl}/awbLocationsQuery`, {
            data: {
                s_mawb: awb.s_mawb, 
                s_unit
            }
        }, {
            headers: {
                Authorization: `Bearer ${eosWebAuthCode}`
            }
        });

        console.log(res.data);

        setPiecesLocations(res.data);
    });

    const selectAwb = (awb) => {
        setSelectedAwb(awb);
        awbLocationsQuery(awb);
        next();
    }

    // Selected Location:

    const [selectedLocation, setSelectedLocation] = useState({});

    const selectLocation = (location) => {
        setSelectedLocation(location);
        setPcsToDeliver(location.i_pieces);
        next();
    }

    // AWBs to Deliver:

    const handleConfirmDelivery = () => {
        selectedLocation.confirmDelivery = true;
        console.log(selectedAwb);
        console.log(selectedCompany);
        previous();
    }

    const signatureRef = useRef();

    const updateDeliveredAwb = asyncHandler(async() => {
        const res = await axios.post(`${eosWebApiUrl}/updateDeliveredAwb`, {
            data: {
                s_mawb_id: selectedAwb.s_mawb_id,
                s_status: 'DELIVERED',
                s_modified_by: user.name,
                t_modified: moment().local().format('MM/DD/YYYY HH:mm'),
                t_dock_ownership,
                b_delivered: true,
                s_mawb: selectedAwb.s_mawb,
                s_unit,
                signatureDataUri: signatureRef.current.toDataURL()
            }
        }, {
            headers: {
                Authorization: `Bearer ${eosWebAuthCode}`
            }
        }); 

        setDockData(res.data);
        setDockCompanies(resolveDockCompanies(res.data));
        push('Customers');
    });

    const [pcsToDeliver, setPcsToDeliver] = useState(0);
    const disableDeliver = pcsToDeliver < 1 || pcsToDeliver > selectedLocation.i_pieces;

    const deliverDock = asyncHandler(async() => {
        const res = await axios.post(`${eosWebApiUrl}/deliverDock`, {
            data: {
                id: selectedLocation.id,
                t_modified: moment().local().format('MM/DD/YYYY HH:mm'),
                s_modified_by: user.name,
                s_unit,
                i_pieces: pcsToDeliver,
                b_split: selectedLocation.i_pieces !== pcsToDeliver,
                s_mawb: selectedAwb.s_mawb
            }
        }, {
            headers: {
                Authorization: `Bearer ${eosWebAuthCode}`
            }
        }); 

        push('PiecesLocation');
        setPiecesLocations(res.data);
    });

    // Helpers:

    const resolveDriverCoName = () => {
        if (selectedCompany) {
            const { s_trucking_driver, s_trucking_company } = selectedCompany;
            const output = `${s_trucking_driver} with ${s_trucking_company}`;
            if (output.length > 30) {
                return `${output.substr(0, 27)}...`;
            }
            return output;
        }
    }

    const CompanySubHeader = () => {
        return (
            <Row className={'mb-2'}>
                <Col md={12}>
                    <Button 
                        className={'float-left mr-2 py-1'} 
                        onClick={() => previous()}
                    >
                        <h6 className='d-inline pb-5'>Back</h6>
                    </Button>
                    <h6 className={'float-right mt-2'}>
                        {resolveDriverCoName()}
                    </h6>
                </Col>
            </Row>
        );
    }

    return (
        <Row className={'mobile'}>
            <Col md={12}>
                <Line
                    percent={(steps.indexOf(step) + 1) / steps.length * 100}
                    className="pad-b"
                />
                <div className="example-steps fluid">
                    <h1 onClick={() => push('Customers')} className={'d-inline'}>Dock Delivery</h1>
                    <i className={'fas fa-circle d-inline ml-4'} onClick={() => push('SignAndClose')} style={{fontSize: '10px'}}></i>

                    <Steps>
                        <Step id={'Customers'}>
                            <h4>Current Customers</h4>
                            {
                                dockCompanies.map((c, i) => (
                                    <CompanyCard 
                                        company={c}
                                        key={i}
                                        selectCompany={selectCompany}
                                        user={user}
                                    />
                                ))
                            }
                        </Step>
                        <Step id={'AWBs'}>
                            <CompanySubHeader />
                            <Row>
                                <Col md={12}>
                                    {
                                        selectedCompany.awbs && selectedCompany.awbs.map((a, i) => (
                                            <Row>
                                                <Col md={12}>
                                                    <AwbCard 
                                                        awb={a}
                                                        key={i}
                                                        selectAwb={selectAwb}
                                                    />
                                                </Col>  
                                            </Row>
                                        ))
                                    }
                                </Col>
                            </Row>
                        </Step>
                        <Step id={'PiecesLocation'}>
                            <CompanySubHeader />
                            <Row>
                                <Col md={12}>
                                    <h4>AWB: {selectedAwb.s_mawb}</h4>
                                </Col>
                            </Row>
                            <Row className={'text-center'}>
                                <Col xs={6}>
                                    <h6>Location</h6>
                                </Col>
                                <Col xs={6}>
                                    <h6>Pieces</h6>
                                </Col>
                            </Row>
                            {
                                piecesLocations.map((l, i) => (
                                    <LocationCard 
                                        location={l}
                                        key={i}
                                        selectLocation={selectLocation}
                                    />
                                ))
                            }
                            <Row>
                                <Col md={12}>                                    
                                    <Row className={'mt-2'}>
                                        <Col className={'mx-auto btn btn-secondary'} xs={12} onClick={() => push('SignAndClose')}>
                                            <h1>Ready to Sign</h1>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Step>
                        <Step id={'Deliver'}>
                            <CompanySubHeader />
                            <Row>
                                <Col xs={12}>
                                    <h4>{selectedAwb.s_mawb}</h4>
                                    <h4>Location: {selectedLocation.s_location || 'No Location'}</h4>
                                    <h4>Pieces: {selectedLocation.i_pieces || 'No pieces'}</h4>
                                </Col>
                                <Col xs={12}>
                                    <h4>Custom Number of Pieces</h4>
                                    <Input type={'number'} value={pcsToDeliver} min={2} max={selectedLocation.i_pieces} onChange={(e) => setPcsToDeliver(e.target.value)} />
                                </Col>
                                <Col 
                                    md={12} 
                                    className={`btn text-center mx-3 mt-2 ${disableDeliver && 'disabled'}`} 
                                    style={{ backgroundColor: '#61B996' }}
                                    onClick={() => !disableDeliver && deliverDock()}
                                >
                                    <h1>DELIVER</h1>
                                </Col>
                            </Row>
                        </Step>
                        <Step id={'SignAndClose'}>
                            <Row>
                                <Col md={12}>
                                    <Row>
                                        <Col md={12}>
                                            <h6 className={'float-left'}>Driver Signature:</h6>
                                            <Button 
                                                onClick={() => signatureRef.current.clear()}
                                                className={'float-right py-1'}
                                            >
                                                Clear
                                            </Button>
                                        </Col>
                                    </Row>
                                    <div style={{ width: '100%', height: '400px', border: '2px solid black' }}>
                                        <SignatureCanvas 
                                            penColor='black'
                                            canvasProps={{width: width - 50, height: 400, className: 'sigCanvas'}} 
                                            ref={signatureRef}
                                        />
                                    </div>
                                    <Row className={'mt-2'}>
                                        <Col className={'mx-auto btn btn-success'} xs={12} onClick={() => updateDeliveredAwb()}>
                                            <h1>Confirm</h1>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Step>
                    </Steps>
                </div>
            </Col>
        </Row>
    );
}

const DockDelivery = () => {

    return (
        <Wizard 
            render={({ step, steps, next, previous, push }) => (
                <DeliveryProcess 
                    step={step}
                    steps={steps}
                    next={next}
                    previous={previous}
                    push={push}
                />
            )}
        ></Wizard>
    );
}

export default withAuthenticationRequired(DockDelivery);