import React, { Fragment } from "react";
import { useHistory } from "react-router-dom";
import { Container, Row, Col, Card, CardBody, Table, Button } from "reactstrap";

import { useAuth0 } from "@auth0/auth0-react";

const Home = () => {

  const { loginWithRedirect, isAuthenticated } = useAuth0();
  const history = useHistory();

//   if (isAuthenticated) {
//       history.push('/add-transfers');
//   }

  return (
    <div className="fixed-background d-flex justify-content-center align-items-center">
        <Row>
          <Col md={12}>
              <Card >
                <CardBody>
                    <Col md={12} className='text-center'>
                        <h1>EOS Portal</h1>
                        <Button onClick={() => loginWithRedirect()}>Login</Button>
                    </Col>
                </CardBody>
            </Card>
          </Col>
        </Row>
    </div>
  );
};

export default Home;
