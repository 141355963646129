import { withAuthenticationRequired } from '@auth0/auth0-react';
import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from '../components/context/index';
import {Button, Row, Col, Table, Card, CardBody, CardTitle, CardText} from 'reactstrap';
import moment from 'moment';
import axios from 'axios';

import ModalQuiz from '../components/training/ModalQuiz';
import ModalPowerpoint from '../components/training/ModalPowerpoint';

const Training = ({

}) => {

    const {
        user,
        s_unit,
        actions,
        audience,
        eosWebApiUrl,
        eosWebAuthCode
    } = useContext(AppContext);

    console.log(user);

    const { getAccessTokenSilently, asyncHandler } = actions;

    const [i_training_id, set_i_training_id] = useState(null);
    const [assignedContent, setAssignedContent] = useState([]);
    const [trainingRecord, setTrainingRecord] = useState([]);
    const [trainingContent, setTrainingContent] = useState([]); 
    const [modalPowerpointOpen, setModalPowerpointOpen] = useState(false);
    const [powerpointLink, setPowerpointLink] = useState('');
    const [modalQuizOpen, setModalQuizOpen] = useState(false);
    const [i_quiz_id, set_i_quiz_id] = useState(null);
    const [selectedContent, setSelectedContent] = useState(null);
    const [s_access_type, set_s_access_type] = useState('');
    const [assignorName, setAssignorName] = useState('');

    const selectTrainingAssigned = () => {
        const data = {
            s_user_id: user && user.name
        }
        axios.post(`${eosWebApiUrl}/selectTrainingAssigned`, {
            data
        }, {
            headers: {'Authorization': `Bearer ${eosWebAuthCode}`}
        }).then(response => {
            if (response.data) {
                const {assigned, record, assignorName} = response.data;
                setAssignedContent(assigned);
                setTrainingRecord(record);
                setAssignorName(assignorName);
                set_s_access_type(assigned[0].s_access_type)
                setTrainingContent(resolveCompleted(assigned, record));    
            }
            console.log(response.data);
        }).catch(error => {

        });
    }

    useEffect(() => {
        if (user && user.name) {
            selectTrainingAssigned();
        }
    }, [user]);

    const resolveCompleted = (content, record) => {
        console.log(content);
        console.log(record);

        for (let i = 0; i < content.length; i++) {
            if (record.length > 0) {
                const match = record.filter(r => r.i_content_id === content[i].i_content_id);
                console.log(match);
                if (match.length > 0) {
                    const useRecord = match[match.length - 1];
                    content[i].b_completed = useRecord.b_completed;
                    content[i].t_completed = useRecord.t_completed;
                    content[i].f_percent = useRecord.f_percent;
                }
            }
        }
 
        console.log(content);
        return content;
    }

    const createTrainingRecord = (content, quiz=false, quizContent=null) => {
        const now = moment().local().format('MM/DD/YYYY hh:mm A');

        const data = {
            quiz,
            main: {
                i_content_id: content.i_content_id,
                s_user_id: user && user.name,
                b_completed: quiz ? quizContent.b_completed : true,
                t_completed: now,
                i_training_id: content.i_training_id,
                s_unit,
                s_assignor: content.s_assignor,
                i_training_assigned_id: content.id
            },
            other: {}
        }

        if (quiz) {
            const {i_score, i_total, s_instructor, s_quiz_html} = quizContent;
            data.main.i_score = i_score;
            data.main.i_total = i_total;
            data.main.s_instructor = assignorName;
            data.other.s_quiz_html = s_quiz_html;
        }

        axios.post(`${eosWebApiUrl}/createTrainingRecord`, {
            data
        }, {
            headers: {'Authorization': `Bearer ${eosWebAuthCode}`}
        }).then(response => {
            const {record} = response.data;
            setTrainingRecord(record);
            setTrainingContent(resolveCompleted(assignedContent, record));
        }).catch(error => {

        });
    }

    const enableStartContent = (order) => {
        console.log(`Content order = ${order}`);
        if (order === 1) {
            return true;
        }
        console.log(trainingContent);
        console.log(assignedContent);
        console.log(assignedContent[order - 1]);
        const target = assignedContent.filter(t => t.i_order === (order-1))[0];
        console.log(target);
        return target && target.b_completed;
    }

    const openContent = (content) => {
        set_i_training_id(content.i_training_id);
        setSelectedContent(content);
        if (content.s_type.toUpperCase() === 'POWERPOINT') {
            setPowerpointLink(content.s_link);
            setModalPowerpointOpen(true);
            if (!content.b_completed) {
                createTrainingRecord(content);
            }
        } else if (content.s_type.toUpperCase() === 'QUIZ') {
            set_i_quiz_id(content.i_quiz_id);
            setModalQuizOpen(true);
        }
    }

    const handleContent = (content) => {
        console.log(content);
        if (content.b_enforce_content) {
            if (enableStartContent(content.i_order)) {
                openContent(content);
            } else {
                alert('You must finish all previous training Content before taking the quiz.');
            }    
        } else {
            openContent(content);
        }
    }

    const accessMapping = (s_access_type, trainingContent) => {

        console.log(s_access_type);
        console.log(trainingContent);

        const mapping = [];

        if (s_access_type === 'QUIZ') {
            trainingContent.map(t => t.s_type === 'QUIZ' && mapping.push(t));
        } else if (s_access_type === 'CONTENT') {
            trainingContent.map(t => t.s_type !== 'QUIZ' && mapping.push(t));
        } else {
            trainingContent.map(t => mapping.push(t));
        }
        return mapping;
    }

    const resolveTrainingMapping = (trainingContent) => {

        console.log(trainingContent);

        const mappedByModuleId = [];

        for (let i = 0; i < trainingContent.length; i++) {
            const searchModuleId = trainingContent[i].i_training_id;
            const found = mappedByModuleId.find(t => t.id === searchModuleId);
            if (found) {
                for (let j = 0; j < mappedByModuleId.length; j++) {
                    if (mappedByModuleId[j].id === searchModuleId) {
                        mappedByModuleId[j].content.push(trainingContent[i]);
                    }
                }
            } else {
                mappedByModuleId.push({
                    id: searchModuleId,
                    content: [trainingContent[i]]
                });
            }
        }

        console.log(mappedByModuleId);

        const finalMapping = [];

        for (let i = 0; i < mappedByModuleId.length; i++) {
            const { content } = mappedByModuleId[i];
            const mapping = accessMapping(content[0].s_access_type, content);
            finalMapping.push(...mapping);
        }

        return finalMapping;
    }

    return (
        <Row className={'mx-3 my-2'}>
            <Col md={12}>
                <Row>
                    <Col md={12}>
                        <h4>Training Module</h4>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Card className='custom-card'>
                            <CardBody className='py-3 px-5'>
                                    <Row>
                                        WARNING: This record contains Sensitive Security Information that is controlled under 49 CFR parts 15 and 1520. No part of this record may be disclosed to persons without a "need to know", as defined in 49 CFR parts 15 and 1520, except with the written permission of the Administrator of the Transportation Security Administration or the Secretary of Transportation. Unauthorized release may result in civil penalty or other action. For U.S. government agencies, public disclosure is governed by 5 U.S.C. 552 and 49 CFR parts 15 and 1520.
                                    </Row>
                                    <Row>
                                        <h4>Training Content:</h4>
                                    </Row>
                                    <Row>
                                    {
                                        <Table>
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Type</th>
                                                    <th>Name</th>               
                                                    <th>Completed</th>
                                                    <th>Completed Date</th>
                                                    <th>Score</th>
                                                </tr>
                                            </thead>
                                            <tbody className={'table-row-hover'}>
                                                {                                        
                                                    resolveTrainingMapping(trainingContent).map((t, i) =>  
                                                        <tr key={i} onClick={() => handleContent(t)}>
                                                            <td>{i + 1}</td>
                                                            <td>{t.s_type}</td>
                                                            <td>{t.s_name}</td>
                                                            <td>{t.b_completed && t.b_completed ? 'Yes' : 'No'}</td>
                                                            <td>{t.t_completed && moment.utc(t.t_completed).format('MM/DD/YYYY HH:mm')}</td>
                                                            <td>{t.f_percent && `${(t.f_percent * 100).toFixed(0)}%`}</td>
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                        </Table>
                                    }
                                    </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Col>

            <ModalPowerpoint 
                open={modalPowerpointOpen}
                handleModal={setModalPowerpointOpen}
                s_link={powerpointLink}
            />

            <ModalQuiz 
                open={modalQuizOpen}
                handleModal={setModalQuizOpen}
                i_quiz_id={i_quiz_id}
                eosWebApiUrl={eosWebApiUrl}
                eosWebAuthCode={eosWebAuthCode}
                user={user}
                selectedContent={selectedContent}
                i_training_id={i_training_id}
                createTrainingRecord={createTrainingRecord}
                user={user}
                assignorName={assignorName}
            />
        </Row>
    );
}

export default withAuthenticationRequired(Training);