import React, { useState, useEffect } from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Route, withRouter } from 'react-router-dom';

const SecuredRoute = (props) => {
    const { component: Component, path } = props;

    return (
        <Route path={path} render={() => {
            return <Component {...props}/>
        }} />
    );
}

export default withAuthenticationRequired(SecuredRoute);