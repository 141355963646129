import React, {Fragment, useState, useEffect, useRef} from 'react';
import { Table, Button, Input, Card, CardBody, Breadcrumb, BreadcrumbItem, Nav, NavItem, TabContent, TabPane, Row, Col  } from "reactstrap";
import moment from 'moment';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

import ModalLocate from './ModalLocate';

const Locate = ({
    eosWebApiUrl,
    eosWebAuthCode,
    user,
    userEmail,
    s_pou,
    createSuccessNotification,
    s_unit
}) => {

    const [flights, setFlights] = useState([]);
    const [ulds, setUlds] = useState([]);
    const [s_flight_id, set_s_flight_id] = useState(null);
    const [d_arrival_date, set_d_arrival_date] = useState('');
    const [selectedUld, setSelectedUld] = useState(null);
    const [uldFfms, setUldFfms] = useState([]);
    const [selectedHouseIndex, setSelectedHouseIndex] = useState(0);
    const [modalLocateOpen, setModalLocateOpen] = useState(false);
    const [ffmToLocate, setFfmtoLocate] = useState(null);
    const [rackData, setRackData] = useState([]);

    //locate:
    const [selectedTower, setSelectedTower] = useState('');
    const [selectedLevel, setSelectedLevel] = useState('');
    const [selectedLocation, setSelectedLocation] = useState('');
    const [completeLocation, setCompleteLocation] = useState('');
    const [locationAvailable, setLocationAvailable] = useState(false);
    const [enableSpecialLocation, setEnableSpecialLocation] = useState(false);
    const [specialLocationSelected, setSpecialLocationSelected] = useState('');
    const [pcsToLocate, setPcsToLocate] = useState('');

    const selectUldAcceptanceFlights = () => {
        axios.post(`${eosWebApiUrl}/selectUldAcceptanceFlights`, {
            d_arrival_date,
            s_pou
        }, {
            headers: {'Authorization': `Bearer ${eosWebAuthCode}`}
        }).then(response => {
            console.log(response.data);
            setFlights(response.data);
            set_s_flight_id(response.data[0].s_flight_id);
        }).catch(error => {

        });
    }

    const selectBreakDownUlds = () => {
        axios.post(`${eosWebApiUrl}/selectBreakDownUlds`, {
            s_flight_id,
            s_pou
        }, {
            headers: {'Authorization': `Bearer ${eosWebAuthCode}`}
        }).then(response => {
            console.log(response.data);
            setUlds(response.data);
        }).catch(error => {

        });
    }

    const selectUldFfms = () => {
        const {s_uld} = selectedUld;
        const s_flightid = s_flight_id;
        axios.post(`${eosWebApiUrl}/selectFfmByUld`, {
            s_flightid,
            s_uld
        }, {
            headers: {'Authorization': `Bearer ${eosWebAuthCode}`}
        }).then(response => {
            setUldFfms(null);
            setUldFfms(resolveFfms(response.data));
        }).catch(error => {

        });
    }

    const resolveFfms = (ffms) => {
        const resolvedFfms = [];

        for (let i = 0; i < ffms.length; i++) {

            console.log(resolvedFfms.filter(f => f.i_id === ffms[i].i_id).length);
            console.log(resolvedFfms.filter(f => f.i_id === ffms[i].i_id).length === 0);
            if (resolvedFfms.filter(f => f.i_id === ffms[i].i_id).length === 0) {
                console.log(`FFM NOT IN resolvedFfms: ADDING`);

                console.log(`LOCATED COUNT = ${ffms[i].located_count}`);

                resolvedFfms.push({
                    i_id: ffms[i].i_id,
                    s_cs_id: ffms[i].s_cs_id,
                    s_unique: ffms[i].s_unique,
                    s_flightid: ffms[i].s_flightid,
                    s_status: ffms[i].s_status,
                    s_type: ffms[i].s_type,
                    s_mawb: ffms[i].s_mawb,
                    s_mawb_prefix: ffms[i].s_mawb_prefix,
                    s_mawb_post: ffms[i].s_mawb_post,
                    s_airline_code: ffms[i].s_airline_code,
                    s_flight_number: ffms[i].s_flight_number,
                    s_flight_serial: ffms[i].s_flight_serial,
                    d_arrival_date: ffms[i].d_arrival_date,
                    s_origin: ffms[i].s_origin,
                    s_destination: ffms[i].s_destination,
                    f_weight: ffms[i].f_weight,
                    s_weight_type: ffms[i].s_weight_type,
                    s_pieces_type: ffms[i].s_pieces_type,
                    i_actual_piece_count: ffms[i].i_actual_piece_count,
                    i_pieces_total: ffms[i].i_pieces_total,
                    s_pieces: ffms[i].s_pieces,
                    s_volume: ffms[i].s_volume,
                    s_commodity: ffms[i].s_commodity,
                    s_special_handling_code: ffms[i].s_special_handling_code,
                    s_shc1: ffms[i].s_shc1,
                    s_shc2: ffms[i].s_shc2,
                    s_shc3: ffms[i].s_shc3,
                    s_shc4: ffms[i].s_shc4,
                    s_shc5: ffms[i].s_shc5,
                    s_uld: ffms[i].s_uld,
                    s_uld_type: ffms[i].s_uld_type,
                    s_uld_number: ffms[i].s_uld_number,
                    s_uld_code: ffms[i].s_uld_code,
                    d_do_not_count_date: ffms[i].d_do_not_count_date,
                    d_first_free_day: ffms[i].d_first_free_day,
                    d_second_free_day: ffms[i].d_second_free_day,
                    d_storage_start_day: ffms[i].d_storage_start_day,
                    f_daily_storage_rate: ffms[i].f_daily_storage_rate,
                    s_arrival_weekday: ffms[i].s_arrival_weekday,
                    s_message_type: ffms[i].s_message_type,
                    t_created: ffms[i].t_created,
                    s_created_by: ffms[i].s_created_by,
                    s_modified_by: ffms[i].s_modified_by,
                    t_modified: ffms[i].t_modified,
                    s_notes: ffms[i].s_notes,
                    s_pol: ffms[i].s_pol,
                    s_pou: ffms[i].s_pou,
                    ic_arrival_date: ffms[i].ic_arrival_date,
                    ic_year: ffms[i].ic_year,
                    ic_day: ffms[i].ic_day,
                    ic_month: ffms[i].ic_month,
                    ic_weekday: ffms[i].ic_weekday,
                    sc_month: ffms[i].sc_month,
                    sc_weekday: ffms[i].sc_weekday,
                    t_notification: ffms[i].t_notification,
                    s_notification_user: ffms[i].s_notification_user,
                    s_notification_tpe: ffms[i].s_notification_tpe,
                    b_notification: ffms[i].b_notification,
                    b_located: ffms[i].b_located,
                    s_located_user: ffms[i].s_located_user,
                    t_located: ffms[i].t_located,
                    s_location: ffms[i].s_location,
                    sc_notification_username: ffms[i].sc_notification_username,
                    ic_week: ffms[i].ic_week,
                    uldSum: ffms[i].uldSum,
                    flightSum: ffms[i].flightSum,
                    i_total_consignment_number_of_pieces: ffms[i].i_total_consignment_number_of_pieces,
                    located_count: ffms[i].located_count,
                    houseData: [
                        {
                            //this value set will be equal to the initial value set for selectedHouse because this is the value shown, when NO house is selected
                            //when house is blank, these values are shown
                            s_house_waybill_summaries_serial_1: '',
                            i_house_waybill_summaries_num_pieces_1: '',
                            located_count: ffms[i].located_mawb_count
                        },
                        {
                            s_house_waybill_summaries_serial_1: ffms[i].s_house_waybill_summaries_serial_1,
                            i_house_waybill_summaries_num_pieces_1: ffms[i].i_house_waybill_summaries_num_pieces_1,
                            located_count: ffms[i].located_hawb_count
                        }
                    ],
                    //values that are displayed, need to be initalized to show before the user changes the values:
                    selectedHouse: {
                        s_house_waybill_summaries_serial_1: '',
                        i_house_waybill_summaries_num_pieces_1: '', //blank until user selects a house
                        located_count: ffms[i].located_mawb_count //initially, this will show the s_mawb count
                    }
                });
            } else {
                console.log(`FFM ALREADY IN resolvedFfms: NOT ADDING`);

                for (let j = 0; j < resolvedFfms.length; j++) {
                    console.log(`FOUND EXISTING FFM IN resolvedFfm: NOT ADDING`);
                    if (resolvedFfms[j].i_id === ffms[i].i_id) {
                        resolvedFfms[j].houseData.push({
                            s_house_waybill_summaries_serial_1: ffms[i].s_house_waybill_summaries_serial_1,
                            i_house_waybill_summaries_num_pieces_1: ffms[i].i_house_waybill_summaries_num_pieces_1,
                            located_count: ffms[i].located_hawb_count
                        });
                        console.log(`FINISHED ADDING TO houseData`);
                    }
                }
            }
            
        }

        console.log(resolvedFfms);
        return resolvedFfms.sort(sortByDestination);
    }

    const resolveNullHouseData = (houseDataArray) => {
        return houseDataArray && houseDataArray.length === 2 && houseDataArray[1].i_house_waybill_summaries_num_pieces_1 === null && houseDataArray[1].located_count === 0 && houseDataArray[1].s_house_waybill_summaries_serial_1 === null;
    }

    const updateArrayHouseValue = (value) => {
        const ffmsCopy = Object.assign([], uldFfms);
        console.log(`START value = ${value}`);
        const valueArray = value.split(',');
    
        const i_id = valueArray[0];
        const index = valueArray[1];
    
        for (let i = 0; i < ffmsCopy.length; i++) {
            console.log(ffmsCopy[i].i_id);
            if (parseInt(ffmsCopy[i].i_id) === parseInt(i_id)) {
                console.log(ffmsCopy[i].s_mawb);
                console.log(`ffmsCopy i_id MATCH FOUND`);
                console.log(ffmsCopy[i].houseData[index].i_house_waybill_summaries_num_pieces_1);
                ffmsCopy[i].selectedHouse.s_house_waybill_summaries_serial_1 = ffmsCopy[i].houseData[index].s_house_waybill_summaries_serial_1;
                ffmsCopy[i].selectedHouse.i_house_waybill_summaries_num_pieces_1 = ffmsCopy[i].houseData[index].i_house_waybill_summaries_num_pieces_1
                setPcsToLocate(ffmsCopy[i].houseData[index].i_house_waybill_summaries_num_pieces_1);
                console.log(ffmsCopy[i].selectedHouse);
            }
        }
        console.log(ffmsCopy);
        setUldFfms(null);
        setUldFfms(ffmsCopy);
    }

    const destinationMatch = (ffm) => {
        return s_pou && s_pou === ffm.s_destination;
    }

    const sortByDestination = (a, b) => {
        return a.destination - b.destination;
    }

    const handleModalLocateOpen = (ffm) => {
        setFfmtoLocate(ffm);
        setModalLocateOpen(true);
        setPcsToLocate(ffm.selectedHouse.i_house_waybill_summaries_num_pieces_1);
    }

    const selectAwbRackData = () => {
        const {s_mawb} = ffmToLocate;
        axios.post(`${eosWebApiUrl}/selectAwbRackData`, {
            s_mawb
        }, {
            headers: {'Authorization': `Bearer ${eosWebAuthCode}`}
        }).then(response => {
            setRackData(response.data);
        }).catch(error => {

        });
    }

    const resolveSelectedHouseValue = (ffm) => {
        if (ffm && ffm !== null) {
            let index, i_id;

            for (let i = 0; i < ffm.houseData.length; i++) {
                if (ffm.houseData[i].s_house_waybill_summaries_serial_1 === ffm.selectedHouse.s_house_waybill_summaries_serial_1) {
                    index = i;
                    i_id = ffm.i_id;
                }
            }
    
            const value = `${i_id},${index}`;
        
            return value;
        }
    }

    const resolveLocationLocation = () => {
        const locationNumber = parseInt(selectedLocation);
        if (locationNumber < 10) {
            return `0${selectedLocation}`;
        }
        return selectedLocation;
    }

    const checkRackLocationAvailable = () => {
        const s_location = enableSpecialLocation ? specialLocationSelected : completeLocation;
        axios.post(`${eosWebApiUrl}/checkRackLocationAvailable`, {
            s_location
        }, {
            headers: {'Authorization': `Bearer ${eosWebAuthCode}`}
        }).then(response => {
            const available = response.data[0].count > 0 ? false : true;
            setLocationAvailable(available);
        }).catch(error => {

        });
    }

    const resolveFullyLocated = () => {
        console.log(`parseInt(pcsToLocate) ${parseInt(pcsToLocate)}`);
        console.log(`parseInt(ffmToLocate.selectedHouse.located_count) ${parseInt(ffmToLocate.selectedHouse.located_count)}`);
        console.log(`parseInt(ffmToLocate.uldSum) ${parseInt(ffmToLocate.uldSum)}`);
        console.log(parseInt(pcsToLocate) + parseInt(ffmToLocate.selectedHouse.located_count) >= parseInt(ffmToLocate.uldSum));
        return parseInt(pcsToLocate) + parseInt(ffmToLocate.selectedHouse.located_count) >= parseInt(ffmToLocate.uldSum);
    }

    const resolveSpecialHandlingCode = () =>  {

        let code;
        const {s_shc1, s_shc2, s_shc3, s_shc4, s_shc5} = ffmToLocate;
        const checkArray = [s_shc1, s_shc2, s_shc3, s_shc4, s_shc5];

        let haveContent = false;

        for (let i = 0; i < checkArray.length; i++) {
            if (checkArray[i].toUpperCase() !== 'NULL' && checkArray[i].length > 0) {
                haveContent = true;
            }
        }

        if (haveContent) {
            code = `${s_shc1},${s_shc2},${s_shc3},${s_shc4},${s_shc5}`;
        } else {
            code = 'NO SPECIAL HANDLING CODE';
        }

        return code;
    }

    const locateInRack = () => {
        const data = {
            locate: {
                t_created: moment().local().format('MM/DD/YYYY hh:mm A'),
                s_created_by: userEmail,
                t_modified: moment().local().format('MM/DD/YYYY hh:mm A'),
                s_modified_by: userEmail,
                s_status: 'LOCATED',
                s_unit,
                s_location: enableSpecialLocation ? specialLocationSelected : completeLocation,
                s_tower: selectedTower,
                s_level: selectedLevel,
                s_position: resolveLocationLocation(),
                b_delivered : false,
                s_mawb: ffmToLocate.s_mawb,
                s_hawb: ffmToLocate.selectedHouse.s_house_waybill_summaries_serial_1,
                i_pieces: pcsToLocate,
                s_airline_code: ffmToLocate.s_airline_code,
                i_airline_prefix: ffmToLocate.s_mawb_prefix,
                s_shc1: ffmToLocate.s_shc1,
                s_shc2: ffmToLocate.s_shc2,
                s_shc3: ffmToLocate.s_shc3,
                s_shc4: ffmToLocate.s_shc4,
                s_shc5: ffmToLocate.s_shc5,
                s_special_hanlding_code: resolveSpecialHandlingCode(),
                s_notes: '',
                s_priority: '',
                s_guid: uuidv4(),
                s_flightnumber: ffmToLocate.s_flight_serial,
                s_flight_uld: selectedUld.s_uld,
                s_flight_id: s_flight_id,
                d_flight: d_arrival_date,
                s_platform: 'EOS'

            },
            update: {
                i_id: ffmToLocate.i_id,
                t_modified: moment().local().format('MM/DD/YYYY hh:mm A'),
                s_modified_by: userEmail,
                b_located: resolveFullyLocated(),
                s_located_user: userEmail,
                t_located: moment().local().format('MM/DD/YYYY hh:mm A')
            }
        }

        axios.post(`${eosWebApiUrl}/locateInRack`, {
            data
        }, {
            headers: {'Authorization': `Bearer ${eosWebAuthCode}`}
        }).then(response => {
            setModalLocateOpen(false);
            reset();
            selectUldFfms();
            createSuccessNotification('AWB Located');
        }).catch(error => {

        });
    }

    // const enableLocate = () => {
    //     return (ffmToLocate !== null && locationAvailable && enableSpecialLocation && specialLocationSelected.length > 0 && parseInt(pcsToLocate) >= 1) || (ffmToLocate !== null && locationAvailable && !enableSpecialLocation && selectedTower.toString().length > 0 && selectedLevel.toString().length > 0 && selectedLocation.toString().length > 0 && parseInt(pcsToLocate) >= 1);
    // }

    const enableLocate = () => {
        return (ffmToLocate !== null && enableSpecialLocation && specialLocationSelected.length > 0 && parseInt(pcsToLocate) >= 1) || (ffmToLocate !== null && !enableSpecialLocation && selectedTower.toString().length > 0 && selectedLevel.toString().length > 0 && selectedLocation.toString().length > 0 && parseInt(pcsToLocate) >= 1);
    }

    const reset = () => {
        setSelectedTower('');
        setSelectedLevel('');
        setSelectedLocation('');
        setCompleteLocation('');
        setLocationAvailable(false);
        setSpecialLocationSelected('');
        setPcsToLocate('');
        setModalLocateOpen(false);
    }

    useEffect(() => {
        if (moment(d_arrival_date).isValid()) {
            selectUldAcceptanceFlights();
        }
    }, [d_arrival_date, s_pou]);

    useEffect(() => {
        if (s_flight_id !== '' && s_flight_id !== null) {
            selectBreakDownUlds();
        }
    }, [s_flight_id]);

    useEffect(() => {
        if (selectedUld !== null) {
            selectUldFfms();
        }
    }, [selectedUld]);

    useEffect(() => {
        if (ffmToLocate !== null) {
            selectAwbRackData();
        }
    }, [ffmToLocate]);

    useEffect(() => {
        setCompleteLocation(`${selectedTower}${selectedLevel}${resolveLocationLocation()}`);
    }, [selectedTower, selectedLevel, selectedLocation]);

    useEffect(() => {
        if (!enableSpecialLocation && selectedTower.toString().length > 0 && selectedLevel.toString().length > 0 && selectedLocation.toString().length > 0) {
            checkRackLocationAvailable();
        } else if (enableSpecialLocation && specialLocationSelected.length > 0) {
            checkRackLocationAvailable();
        }
    }, [completeLocation, specialLocationSelected, enableSpecialLocation]);

    return (
        <Row>

            <div className={`mb-3 col-3`}>
                <Card className='custom-opacity-card' style={{borderRadius: '0.75rem'}}>
                    <CardBody className='custom-card-transparent py-3 px-5' style={{backgroundColor: 'rgba(255,255,255,0.2)'}}>
                        <Row className='py-2'>
                            <h4>Make a Selection:</h4>
                        </Row>
                        <Row className='py-2'>
                            <h4>Date:</h4>
                            <input type='date' value={d_arrival_date} onChange={(e) => set_d_arrival_date(e.target.value)} className='ml-4' style={{display: 'inline'}} />
                        </Row>
                        <Row className='py-2'>
                            <h4>Flight:</h4>
                            <select className='ml-3' style={{display: 'inline'}} onChange={(e) => set_s_flight_id(e.target.value)}>
                            {
                                flights.map((f, i) => 
                                    <option key={i} value={f.s_flight_id}>{f.s_flight_number}</option>
                                )
                            }
                            </select>
                        </Row>
                        <Row className='pt-2 pb-0'>
                            <Table className='mb-0' style={{tableLayout: 'fixed'}}>
                                <thead>
                                    <tr>
                                        <th width='50%'>Select ULD</th>
                                        <th width='50%'>Status</th>
                                    </tr>
                                </thead>
                                <tbody>

                                </tbody>
                            </Table>
                        </Row>
                        <Row style={{maxHeight: '430px', overflowY: 'scroll', tableLayout: 'fixed'}}>
                            <Table className='table-row-hover'>
                                <thead>

                                </thead>
                                <tbody>
                                    {
                                        ulds.map((u, i) => 
                                            <tr key={i} onClick={() => setSelectedUld(u)} className={selectedUld !== null && selectedUld.s_uld === u.s_uld ? 'table-row-selected' : ''}>
                                                <td width='50%'>{u.s_uld}</td>
                                                <td width='50%'>{!u.b_closed ? 'Open' : 'Closed'}</td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </Table>
                        </Row>
                    </CardBody>
                </Card>
            </div>

            <div className={`mb-3 col-9`}>

                <Card className='custom-opacity-card mb-3' style={{borderRadius: '0.75rem'}}>
                    <CardBody className='custom-card-transparent py-3 px-5' style={{backgroundColor: 'rgba(255,255,255,0.2)'}}>
                        <Row className='mb-0'>
                            <h4>AWBs for ULD: {selectedUld !== null && selectedUld.s_uld}</h4>
                            <Table className='mb-0' style={{tableLayout: 'fixed'}}>
                                <thead>
                                    <tr>
                                        <th width='11%'>Select AWB</th>
                                        <th width='5%'>Dest</th>
                                        <th width='20%'>Notes</th>
                                        <th width='8%'>Located</th>
                                        <th width='8%'>This ULD</th>
                                        <th width='8%'>This FLT</th>
                                        <th width='8%'>Total</th>
                                        <th width='8%'>SHC</th>
                                        <th width='10%'>House</th>
                                        <th width='8%'>House Pieces</th>
                                        <th width='6%'></th>
                                        {/* 5 to add */}
                                    </tr>
                                </thead>
                            </Table>
                        </Row>
                        <Row className='mb-0'>
                            <Table striped style={{tableLayout: 'fixed'}}>
                                <thead>
                                    {
                                        uldFfms !== null && uldFfms.map((f, i) => destinationMatch(f) &&
                                                <tr key={i}>
                                                <th width='11%'>{f.s_mawb}</th>
                                                <th width='5%'>{f.s_destination}</th>
                                                <th width='20%'>{f.s_notes}</th>
                                                <th width='8%'>{f.located_count}</th>
                                                <th width='8%'>{f.uldSum}</th>
                                                <th width='8%'>{f.flightSum}</th>
                                                <th width='8%'>{f.i_total_consignment_number_of_pieces}</th>
                                                <th width='8%'>{f.s_special_handling_code === 'null' ? 'None' : f.s_special_handling_code}</th>
                                                <th width='10%'>
                                                    {
                                                        resolveNullHouseData(f.houseData) ?
                                                        <>None</> :
                                                        <select style={{width: '110px'}} value={resolveSelectedHouseValue(f)} onChange={(e) => updateArrayHouseValue(e.target.value)}>
                                                            {
                                                                f.houseData.map((d, i) => 
                                                                    <option value={`${f.i_id},${i}`} key={i}>{d.s_house_waybill_summaries_serial_1}</option>
                                                                )
                                                            }
                                                        </select>
                                                    }

                                                </th>
                                                <th width='8%'>{f.selectedHouse.i_house_waybill_summaries_num_pieces_1}</th>
                                                <th width='6%'>
                                                    <Button onClick={(e) => handleModalLocateOpen(f)} color='primary' className='py-1 px-1'>Locate</Button>
                                                </th>
                                            </tr>
                                        )
                                    }
                                </thead>
                            </Table>
                        </Row>
                    </CardBody>
                </Card>

                <Card className='custom-opacity-card' style={{borderRadius: '0.75rem'}}>
                    <CardBody className='custom-card-transparent py-3 px-5' style={{backgroundColor: 'rgba(255,255,255,0.2)'}}>
                        <Row className='mb-0'>
                            <h4><span style={{fontWeight: 'bolder'}}>Transfer</span> AWBs for ULD: {selectedUld !== null && selectedUld.s_uld}</h4>
                            <Table className='mb-0' style={{tableLayout: 'fixed'}}>
                                <thead>
                                    <tr>
                                        <th width='11%'>Select AWB</th>
                                        <th width='5%'>Dest</th>
                                        <th width='20%'>Notes</th>
                                        <th width='8%'>Located</th>
                                        <th width='8%'>This ULD</th>
                                        <th width='8%'>This FLT</th>
                                        <th width='8%'>Total</th>
                                        <th width='8%'>SHC</th>
                                        <th width='10%'>House</th>
                                        <th width='8%'>House Pieces</th>
                                        <th width='6%'></th>
                                        {/* 5 to add */}
                                    </tr>
                                </thead>
                            </Table>
                        </Row>
                        <Row className='mb-0'>
                            <Table striped style={{tableLayout: 'fixed'}}>
                                <thead>
                                    {
                                        uldFfms !== null && uldFfms.map((f, i) => !destinationMatch(f) &&
                                            <tr key={i}>
                                                <th width='11%'>{f.s_mawb}</th>
                                                <th width='5%'>{f.s_destination}</th>
                                                <th width='20%'>{f.s_notes}</th>
                                                <th width='8%'>{f.selectedHouse.located_count}</th>
                                                <th width='8%'>{f.uldSum}</th>
                                                <th width='8%'>{f.flightSum}</th>
                                                <th width='8%'>{f.i_total_consignment_number_of_pieces}</th>
                                                <th width='8%'>{f.s_special_handling_code === 'null' ? 'None' : f.s_special_handling_code}</th>
                                                <th width='10%'>
                                                    {
                                                        resolveNullHouseData(f.houseData) ?
                                                        <>None</> :
                                                        <select style={{width: '110px'}} value={resolveSelectedHouseValue(f)} onChange={(e) => updateArrayHouseValue(e.target.value)}>
                                                            {
                                                                f.houseData.map((d, i) => 
                                                                    <option value={`${f.i_id},${i}`} key={i}>{d.s_house_waybill_summaries_serial_1}</option>
                                                                )
                                                            }
                                                        </select>
                                                    }

                                                </th>
                                                <th width='8%'>{f.selectedHouse.i_house_waybill_summaries_num_pieces_1}</th>
                                                <th width='6%'>
                                                    <Button onClick={(e) => handleModalLocateOpen(f)} color='primary' className='py-1 px-1'>Locate</Button>
                                                </th>
                                            </tr>
                                        )
                                    }
                                </thead>
                            </Table>
                        </Row>
                    </CardBody>
                </Card>

            </div>

            <ModalLocate 
                open={modalLocateOpen}
                handleModal={setModalLocateOpen}
                ffm={ffmToLocate}
                rackData={rackData}
                resolveNullHouseData={resolveNullHouseData}
                updateArrayHouseValue={updateArrayHouseValue}
                resolveSelectedHouseValue={resolveSelectedHouseValue}
                selectedTower={selectedTower}
                setSelectedTower={setSelectedTower}
                selectedLevel={selectedLevel}
                setSelectedLevel={setSelectedLevel}
                selectedLocation={selectedLocation}
                setSelectedLocation={setSelectedLocation}
                completeLocation={completeLocation}
                locationAvailable={locationAvailable}
                enableSpecialLocation={enableSpecialLocation}
                setEnableSpecialLocation={setEnableSpecialLocation}
                specialLocationSelected={specialLocationSelected}
                setSpecialLocationSelected={setSpecialLocationSelected}
                locateInRack={locateInRack}
                pcsToLocate={pcsToLocate}
                setPcsToLocate={setPcsToLocate}
                enableLocate={enableLocate}
            />

        </Row>
    );
}

export default Locate;