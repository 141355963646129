import React from 'react';
import SkuTable from './SkuTable';

import { Row, Col, Button } from 'reactstrap';

export default ({
    data,
    setSelectedSku,
    handleCreateUpdateSku
}) => {

    return (
        <div className='px-5'>
            <Row className='mb-2'>
                <Col md={12}>
                    <Button onClick={() => handleCreateUpdateSku(true)}>Add New</Button>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <SkuTable 
                        data={data}
                        setSelectedSku={setSelectedSku}
                        handleCreateUpdateSku={handleCreateUpdateSku}
                        extendedMappingType={true}
                        numRows={15}
                    />
                </Col>
            </Row>
        </div>

    )
}