import React from 'react'
import { Row, Col, Card, CardBody } from 'reactstrap';
import ReactTable from '../custom/ReactTable';

export default ({
    locations
}) => {
    return (
        <Row className={'mt-2'}>
            <Col md={12}>
                <Card>
                    <CardBody>
                        <h4>Locations</h4>
                        <ReactTable 
                            data={locations}
                            mapping={[
                                {
                                    name: 'Last modified',
                                    value: 't_modified',
                                    date: true
                                },
                                {
                                    name: 'Locations',
                                    value: 's_location'
                                },
                                {
                                    name: 'Pieces',
                                    value: 'i_pieces'
                                },
                                {
                                    name: 'User',
                                    value: 's_modified_by'
                                },
                                {
                                    name: 'CBP',
                                    value: 'fas fa-check',
                                    icon: true,
                                    showCondition: (item) => item.b_customs_hold
                                },
                                {
                                    name: 'USDA',
                                    value: 'fas fa-check',
                                    icon: true,
                                    showCondition: (item) => item.b_usda_hold
                                },
                                {
                                    name: 'Hold',
                                    value: 'fas fa-check',
                                    icon: true,
                                    showCondition: (item) => item.b_hold
                                },
                            ]}
                            index={true}
                            numRows={5}
                            locked={true}
                        />
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
}