import React, {Fragment} from 'react';

import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Input,
    Label,
  } from "reactstrap";
import moment from 'moment';
import { throwStatement } from '@babel/types';

const RejectConfirmModal = ({open, handleModal, s_counter_reject_reason, handleInput, handleReject}) => {

    return (
        <Fragment>
            <Modal isOpen={open} toggle={handleModal}>
            <div className="modal-content" style={{width: '600px'}}>
                <div className="modal-header mx-auto">
                    <h5 className="modal-title" id="exampleModalLabel">Confirm Rejection</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body mx-auto">
                    <div className='text-center'>
                        <i className="fas fa-exclamation-triangle" style={{fontSize: '40px'}}></i>
                        <h4>Enter the reason for rejection:</h4>
                    </div>
                    <div className="input-group mb-3">
                        <textarea type="text" id='s_counter_reject_reason' value={s_counter_reject_reason} onChange={(e) => handleInput(e)} className="form-control" style={{width: '400px', height: '100px'}}></textarea>
                        {/* <textarea type="text" id='s_counter_reject_reason' value={s_counter_reject_reason} onChange={(e) => handleInput(e)} className="form-control" style={{width: '400px'}} placeholder="To reject shipment, type reason here."></textarea> */}
                    </div>
                </div>
                <div className="modal-footer">
                    <button className="btn btn-danger" type="button" disabled={s_counter_reject_reason && s_counter_reject_reason.length > 0 ? false : true} onClick={() => handleReject()}>Confirm</button>
                    <button type="button" className="btn btn-secondary" onClick={handleModal}>Close</button>
                </div>
            </div>
            </Modal>
        </Fragment>
    );
}

export default RejectConfirmModal;