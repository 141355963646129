import React, { useState, useEffect, useContext } from "react";
import { AppContext } from '../components/context/index';
import { Container, Row, Col, Card, CardBody, Table, Button } from "reactstrap";
import axios from 'axios';
import moment from 'moment';

import Loading from "../components/Loading";
import ReactTable from '../components/custom/ReactTable';

const ViewTransfers = () => {

    const {
        loading,
        user,
        baseApiUrl,
        s_unit,
        s_company_guid,
        actions,
        audience
    } = useContext(AppContext);
    
    const [t_departure, set_t_departure] = useState(moment().format('YYYY-MM-DD'));
    const [uniqueTransferTrucks, setUniqueTransferTrucks] = useState([]);
    const [transferData, setTransferData] = useState([]);
    const [selectedTruckGuid, setSelectedTruckGuid] = useState('');
    const [selectedTrucks, setSelectedTrucks] = useState(null);

    const selectUniqueTransferTrucks = async () => {

        const data = {
            t_departure,
            s_unit,
            s_company_guid
        }

        const token = await actions.getAccessTokenSilently({ audience });

        try {
            const response = await axios.post(`${baseApiUrl}/selectUniqueTransferTrucks`, {
                data
            }, {
                headers: {'Authorization': `Bearer ${token}`}
            });

            setTransferData(response.data);
        } catch (err) {
            alert(err);
        }
    }

    useEffect(() => {
        if (user && moment(t_departure).isValid) {
            selectUniqueTransferTrucks();
        }
    }, [t_departure, user]);

    useEffect(() => {

        const groupedData = [];

        for (let i = 0; i < transferData.length; i++) {
            const exists = groupedData.filter(d => d.s_truck_guid === transferData[i].s_truck_guid);
            if (exists.length === 0) {
                groupedData.push({
                    s_truck_id: transferData[i].s_truck_id,
                    s_truck_guid: transferData[i].s_truck_guid
                });
            }
        }

        setUniqueTransferTrucks(groupedData);

    }, [transferData]);

    useEffect(() => {
        const trucks = transferData.filter(t => t.s_truck_guid === selectedTruckGuid);
        console.log(transferData);
        console.log(trucks);
        setSelectedTrucks(trucks);
    }, [selectedTruckGuid]);
    

    if (loading || !user) {
        return <Loading />;
    }

    return (
        <Container fluid className='px-4 py-4'>
            <Row>
                <Col md={12}>
                    <h1>View Transfers</h1>
                </Col>
            </Row>
            <Row>
                <Col md={3}>
                    <Card className='custom-card'>
                        <CardBody className='py-3 px-5'>
                            <Row>
                                <h4 className='pr-3'>Filter by Date:</h4>
                                <input type='date' value={t_departure} onChange={(e) => set_t_departure(e.target.value)} />
                            </Row>
                            <Row>
                                <h4>Results</h4>
                            </Row>
                            <Row>
                            {
                                moment(t_departure).isValid && uniqueTransferTrucks.length === 0 ?  
                                <h5>No Results Found</h5> :
                                <Table className={'table-row-hover'}>
                                    <thead></thead>
                                    <tbody>
                                        {                                        
                                            uniqueTransferTrucks.map((t, i) => 
                                                <tr onClick={() => setSelectedTruckGuid(t.s_truck_guid)} key={i} className={`${selectedTruckGuid === t.s_truck_guid ? 'table-row-selected' : ''}`}>
                                                    <td>{t.s_truck_id}</td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </Table>
                            }
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
                <Col md={9}>
                    {
                        selectedTrucks !== null && selectedTrucks.length > 0 &&  
                        <Card className='custom-card'>
                            <CardBody className='py-3 px-5'>
                                <Row className='my-2'>
                                    <h4>Truck Transfer {selectedTrucks[0].s_truck_id} Details</h4>
                                </Row>
                                <Row className='my-2'>
                                    <h5>Truck number {selectedTrucks[0].s_truck_id} to {selectedTrucks[0].s_to} from {selectedTrucks[0].s_from} departing on {moment(selectedTrucks[0].t_departure).format('MM/DD/YYYY HH:mm')} and estimated arrival on {moment(selectedTrucks[0].t_estimated_arrival).format('MM/DD/YYYY HH:mm')}.</h5>
                                </Row>
                                <Row className='my-2'>
                                    <h5>Entered on {moment(selectedTrucks[0].t_created).format('MM/DD/YYYY HH:mm')}</h5>
                                </Row>
                                <ReactTable 
                                    data={selectedTrucks}
                                    index={true}
                                    mapping={[
                                        {
                                            name: 'AWB',
                                            value: 's_mawb'
                                        },
                                        {
                                            name: 'Arriving Flight',
                                            value: 's_mawb_arrival_flight'
                                        },
                                        {
                                            name: 'Arrival Date',
                                            value: 't_departure',
                                            date: true
                                        },
                                        {
                                            name: 'Pieces',
                                            value: 'i_mawb_pieces'
                                        },
                                        {
                                            name: 'Weight',
                                            value: 'f_mawb_weight_kg'
                                        },
                                        {
                                            name: 'Destination',
                                            value: 's_mawb_destination'
                                        }
                                    ]}
                                    numRows={15}
                                />
                            </CardBody>
                        </Card>
                    }
                </Col>
            </Row>
        </Container>
    );
};

export default ViewTransfers;
