
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { AppContext } from '../components/context/index';
import axios from 'axios';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { renderToString } from 'react-dom/server';
import React, { Component, Fragment, useContext } from 'react';
import GradientWithRadialProgressCard from '../components/export/GradientWithRadialProgressCard';
import { Row, Col } from 'reactstrap';

import IdCheckModal from '../components/export/IdCheckModal';
import IdCheckModal2 from '../components/export/IdCheckModal2';
import TsaCheckModal from '../components/export/TsaCheckModal';
import SelectAwbs from '../components/export/ExportSelectAwbs';
import ExportAwbDetails from '../components/export/ExportAwbDetails';
import CreateAcceptanceSheet from '../components/export/CreateAcceptanceSheet';
import RejectConfirmModal from '../components/export/RejectConfirmModal';
import ModalCreateAwb from '../components/export/ModalCreateAwb';
import MyAwbs from '../components/export/MyAwbs';
import DriversInfo from '../components/export/DriversInfo';
import Flight from '../components/export/Flight';
import Master from '../components/export/Master';
import Notes from '../components/export/Notes';
import Finalize from '../components/export/Finalize';

class Export extends Component {
    constructor(props) {
        super(props);
        this.state = {
            s_unit: null,
            s_awb_type: null,
            s_status: null,
            s_mawb: null,
            s_priority: null,
            s_airline: null,
            s_airline_code: null,
            s_flight_number: '',
            t_depart_date: '',
            s_origin: '',
            s_destination: '',
            i_weight: '',
            i_pieces: '',
            s_transport_type: '',
            s_iac: null,
            s_port_of_unlading: '',
            s_commodity: '',
            s_shc1: '',
            s_shc2: '',
            s_shc3: '',
            s_shc4: '',
            s_shc5: '',
            b_dg: '',
            b_screened: null,
            s_ccsf: null,
            s_non_iac: null,
            b_sms_enabled: null,
            s_sms: '',
            s_company: null,
            s_company_driver_name: '',
            s_company_driver_id_type_1: `STATE DRIVER'S LICENSE`,
            s_company_driver_id_num_1: '',
            d_company_driver_id_expiration_1: '',
            b_company_driver_photo_match_1: false,
            s_company_driver_id_type_2:  `STATE DRIVER'S LICENSE`,
            s_company_driver_id_num_2: null,
            d_company_driver_id_expiration_2: '',
            b_company_driver_photo_match_2: false,
            b_counter_reject: false,
            s_counter_reject_agent: null,
            t_counter_reject_time: null,
            s_counter_reject_reason: '',
            s_kiosk_submitted_agent: null,
            t_kiosk_submitteddatetime: null,
            s_counter_assigned_agent: null,
            t_counter_assigned_start: null,
            s_counter_by: null,
            t_counter_start_time: moment().local().format('MM/DD/YYYY hh:mm A'),
            t_counter_endtime: null,
            t_created: null,
            s_created_by: null,
            t_modified: null,
            s_modified_by: null,
            s_transaction_id: null,
            s_mawb_id: null,
            s_notes: '',
            //Non-essential
            s_trucking_email: null,
            modalIdType1: false,
            modalIdType2: false,
            modalTsaCheck: false,
            modalRejectionConfirm: false,
            modalCreateAwbOpen: false,
            screeningType: null,
            foundIac: null,
            foundCcsf: null,
            tsaSaveInfo: {
                saved: false,
                time: null
            },
            clickIdCheckSave: false,
            myExportAwbs: null,
            currentAwb: null,
            user: null,
            createAwbNum: '',
            createAwbType: '',
            headerAuthCode: `6J!RT-bHBz9^!#z&JZg?cMZvaxMnt&!E$&&*jDbXKa%PsHY^!mD-TDeW@h6Yy?8C+kg#!j2Vn9A^5@9wtKSKS5AM+uX$&A*EU$J+Hsjn#p+zY7VMxBbRZKQM?K?M$Q@QUvn#Bqrbuq6m5Mfdyk^r87NnT@w?DDuK7QVk3y*K$=P?-%-Kv4x=vxk7s=R3RvrBhKr@t3?YP&SqB#^Mr^7fLPwq6?FB6GCaA??WqfH+bYmn?@#-4rZ4=HPNW+Z7j+F9+Jf#ChtWf&BWSew=Ez#^#szcHy%^&uc9ZSev85cYW?Ek4a5nCcJpKq@aSYDLXDZt2L@z2QTaWuQ3Hj726#AXg8VdKuwdpd%4vpsKpSM&sEvBVeFRWWk!g3-zuQCSW%WT2wEEsT!7zk@Z&cMa$#QQa_yTd62Sv_QNXUs_wR3BGNZZPRjdCRHXY=q2ucZAB^Tc9U9B5@3L!g-@DFj%h+Nb+L7dBhdfcJ^!W?^!^de9Q4t$p#XM+35^9_PF$qbBXvwA&PTmb5V@eJ?BBLX!E&bxGnS9&p8*mqnfxUnJ-Exa^jk?UQRXb2d7_GFh2qzg+yrn+#a8RHEVGDQphcK9yWx2fUx&Ve*sUWP!KQDM!J3W5ptckcx4J?qD=+4X_a$z%SA3?5G_vszHtEP4B*&2Dc3P3%hH@9gz$NK#DjXXB*!new=SE&U!7C!G&473tL?YnB8jgR2vbHnyvsLDdSJ9Z@yt_SM7S-FVupu#xnJXSu#cT7yB^KmDqDu$KBNWUP$44&AzgzcN!=^RxGLb8+372x!rq8SGs?keFenZbcs68kcTjG5^P+HwA?qy4=d3w8+6Vw2SzhJ*C^d42Et^yTW=M#=4PpmTUNq$2eMMP3mEpf7*JRx&mmS=v9kZSK!Z6z_2bnJ9Rnt*9^TnYnevpA4YLNnHesUx@wUYC2nF6%aT6Xq%cC!qY7#gXb#P*HEsK=2sgu^!+n&z=8UVsme?%fuYUY_G3NF%4ZS^CXJZeKZt-fdczL_2XYnEH&VePk2eEq?Q=Jdq`      
        }
    };
    
    componentDidMount() {
        console.log(this.props.user);
        this.myAwbsQuery();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        //console.log(nextProps.user);
        console.log(nextProps);

        if (prevState.user !== nextProps.user) {
            return {
                user: nextProps.user
            }
        } else if (prevState.s_unit !== nextProps.s_unit) {
            return {
                s_unit: nextProps.s_unit
            }
        }
        return null;
    }

    componentDidUpdate(prevProps) {
        if (this.props.user !== prevProps.user) {
            this.myAwbsQuery();
        } else if (this.props.s_unit !== prevProps.s_unit) {
            this.myAwbsQuery();
        }
    }

    updateSelectedAwb = (awb) => {
        const data = awb && this.state.myExportAwbs.filter(a => a.s_mawb === awb)[0];
        data && 
        this.setState({
            // Export and Queue
            s_unit: data.s_unit,
            s_awb_type: data.s_type,
            s_status: data.s_status,
            s_mawb: data.s_mawb,
            s_priority: data.s_priority,
            s_airline: data.s_airline,
            s_airline_code: data.s_airline_code,
            s_language: data.s_trucking_language,
            b_sms_enabled: data.b_trucking_sms,
            s_sms: data.s_trucking_cell,
            s_company: data.s_trucking_company,
            s_company_driver_name: data.s_trucking_driver,
            t_kiosk_submitteddatetime: data.t_kiosk_submitted,
            t_counter_assigned_start: data.t_counter_ownership,
            s_transaction_id: data.s_transaction_id,
            s_mawb_id: data.s_mawb_id,

            //Queue only:
            s_trucking_email: data.s_trucking_email,
            s_state: data.s_state,

            //Other
            sm_driver_photo: data.sm_driver_photo
        }, () => {
            this.resetInputAwbDetails();
        });
    }

    eightyWindow = () => false;

    myAwbsQuery = () => {
        const { user, s_unit } = this.props;

        user && s_unit &&
        axios.post(`${this.props.eosWebApiUrl}/agentAwbs`, {
            ownershipAgent: user && user.name,
            s_unit
        }, {
            headers: {'Authorization': `Bearer ${this.props.eosWebAuthCode}`}
        }).then(response => {
            console.log(response.data);
            const data = response.data.filter(d => d.s_type === 'EXPORT');
            if(data.length < 1) {
                this.props.history.push('/queue');
            }
            this.setState({
                myExportAwbs: data,
            }, () => {
                const currentAwbNum = data && data[0].s_mawb;
                currentAwbNum && this.updateSelectedAwb(currentAwbNum);
            });
        }).catch(err => {
            alert.log(err);
        });
    }

    handleModal = () => {
        this.setState(prevState => ({
            modalIdType1: !prevState.modalIdType1
        }));
    };

    handleModalIdType2 = () => {
        this.setState(prevState => ({
            modalIdType1: false,
            modalIdType2: !prevState.modalIdType2
        }));
    };

    handleModalTsaCheck = () => {
        this.setState(prevState => ({
            modalTsaCheck: !prevState.modalTsaCheck
        }));
    };

    handleModalRejectionConfirm = () => {
        this.setState(prevState => ({
            modalRejectionConfirm: !prevState.modalRejectionConfirm
        }));
    };

    handlePhotoMatch1Change = () => {
        this.setState(prevState => ({
            b_company_driver_photo_match_1: !prevState.b_company_driver_photo_match_1
        }), () => {
            console.log(this.state.b_company_driver_photo_match_1);
        });
    };

    handlePhotoMatch2Change = () => {
        this.setState(prevState => ({
            b_company_driver_photo_match_2: !prevState.b_company_driver_photo_match_2
        }), () => {
            console.log(this.state.b_company_driver_photo_match_2);
        });
    };


    handleIdType1 = (e) => {
        this.setState({
            s_company_driver_id_type_1: e.target.value
        }, () => {
            console.log(this.state.s_company_driver_id_type_1);
        });
    };

    handleIdType2 = (e) => {
        this.setState({
            s_company_driver_id_type_2: e.target.value
        }, () => {
            console.log(this.state.s_company_driver_id_type_2);
        });
    };

    handleIdType1Exp = (e) => {
        const date = e.target.value;
        this.setState({
            d_company_driver_id_expiration_1: moment(date).format('MM/DD/YYYY')
        }, () => {
            console.log(this.state.d_company_driver_id_expiration_1);
        });
    };

    handleIdType2Exp = (e) => {
        const date = e.target.value;
        this.setState({
            d_company_driver_id_expiration_2: moment(date).format('MM/DD/YYYY')
        }, () => {
            console.log(this.state.d_company_driver_id_expiration_2);
        });
    };

    handleDriverName = (e) => {
        //const _s_company_driver_name = e.target.value;
        this.setState({
            s_company_driver_name: e.target.value
        }, () => {
            console.log(`s_company_driver_name = ${this.state.s_company_driver_name}`);
        });
    };

    handleIdNum1 = (e) => {
        this.setState({
            s_company_driver_id_num_1: e.target.value
        }, () => {
            console.log(`s_company_driver_id_num_1 = ${this.state.s_company_driver_id_num_1}`);
        });
    };

    handleIdNum2 = (e) => {
        this.setState({
            s_company_driver_id_num_2: e.target.value
        }, () => {
            console.log(`s_company_driver_id_num_2 = ${this.state.s_company_driver_id_num_2}`);
        });
    };

    handleClickIdCheckSave = () => {
        this.setState({
            clickIdCheckSave: true,
            modalIdType1: false,
            modalIdType2: false
        });
    }

    idCheckComplete = () => {
        const {s_company_driver_name, s_company_driver_id_num_1, b_company_driver_photo_match_1, s_company_driver_id_num_2, b_company_driver_photo_match_2, clickIdCheckSave} = this.state;

        if(s_company_driver_name.length > 0 && clickIdCheckSave) {
            if((s_company_driver_id_num_1 !== null && s_company_driver_id_num_1.length > 0 && b_company_driver_photo_match_1) || (s_company_driver_id_num_2 !== null && s_company_driver_id_num_2.length > 0 && b_company_driver_photo_match_2)) {
                return true;
            }
            return false; 
        }
        return false;
    };

    handleIacNum = (e) => {
        console.log(e.target.value);
        this.setState({
            s_iac: e.target.value
        }, () => {
            console.log(this.state.s_iac);
            this.checkIac();
        });
    };

    handleCcsfNum = (e) => {
        this.setState({
            s_ccsf: e.target.value
        }, () => {
            this.checkCcsf();
        });
    };

    handleNonIac = (e) => {
        this.setState({
            s_non_iac: e.target.value
        });
    };

    handleBscreened = (e) => {
        console.log(e);
        this.setState({
            b_screened: JSON.parse(e.target.value),
            screeningType: null,
            s_iac: null,
            s_ccsf: null,
            s_non_iac: null,
            foundIac: null,
            foundCcsf: null,
            tsaSaveInfo: {
                saved: false,
                time: null
            }
        }, () => {
            console.log(this.state.b_screened);
        });
    }

    handleScreeningType = (e) => {
        console.log(e.target.value);
        this.setState({
            screeningType: e.target.value,
            s_iac: null,
            s_ccsf: null,
            s_non_iac: null,
            foundIac: null,
            foundCcsf: null,
            tsaSaveInfo: {
                saved: false,
                time: null
            }
        }, () => {
            console.log(this.state.screeningType);
        });
    }

    checkIac = () => {

        const checkIacNum = this.state.s_iac;

        if(checkIacNum.length === 9) {
            axios.post(`${this.props.eosWebApiUrl}/checkIac`, {
                checkIacNum
            }, {
                headers: {'Authorization': `Bearer ${this.props.eosWebAuthCode}`}
            }).then(response => {
                console.log(response.data);
                this.setState({
                    foundIac: response.data[0]
                });
            }).catch(err => {
                console.log(err);
            });
        } else {
            this.setState({
                foundIac: null
            });
        }
    };

    checkCcsf = () => {

        const  checkCcsfNum = this.state.s_ccsf;

        console.log(checkCcsfNum);

        if(checkCcsfNum.length === 20) {
            console.log(`working`);
            axios.post(`${this.props.eosWebApiUrl}/checkCcsf`, {
                checkCcsfNum
            }, {
                headers: {'Authorization': `Bearer ${this.props.eosWebAuthCode}`}
            }).then(response => {
                console.log(response.data);
                this.setState({
                    foundCcsf: response.data[0]
                });
            }).catch(err => {
                console.log(err);
            });
        } else {
            this.setState({
                foundCcsf: null
            });
        }
    };

    enableSavingTsaCheck = () => {

        const {screeningType, s_non_iac, s_iac, foundIac, s_ccsf, foundCcsf} = this.state;

        const checkIacTypes = ['unscreendIac', 'iacTenderCcsf'];

        if(screeningType === 'unscreendOthers' && s_non_iac !== null && s_non_iac.length > 0) {
            return true;
        } else if(checkIacTypes.indexOf(screeningType) !== -1 && s_iac !== null && foundIac && foundIac !== null) {
            if(foundIac.valid) {
                return true;
            }
        } else if(screeningType === 'iacAlsoCcsf' && s_iac !== null && foundIac && foundIac !== null && s_ccsf !== null && foundCcsf && foundCcsf !== null) {
            if(foundIac.valid && foundCcsf.valid && foundIac.approval_number === foundCcsf.iac_number) {
                return true;
            }
        }

        return false;

    }

    handleSaveTsaCheck = () => {

        console.log(`handleSaveTsaCheck working`);

        const {screeningType} = this.state;

        if(screeningType === 'unscreendOthers') {
            this.setState({
                s_iac: null,
                s_ccsf: null,
                b_screened: false
                //foundIac: null,
                //foundCcsf: null
            });
        } else if(screeningType === 'unscreendIac') {
            this.setState({
                s_non_iac: null,
                s_ccsf: null,
                b_screened: false
            });
        } else if(screeningType === 'iacTenderCcsf') {
            this.setState({
                s_non_iac: null,
                s_ccsf: null,
                b_screened: true
            });
        } else if(screeningType === 'iacAlsoCcsf') {
            this.setState({
                s_non_iac: null,
                b_screened: true
            });
        }

        this.setState({
            tsaSaveInfo: {
                saved: true,
                time: moment().format('MM/DD/YYYY hh:mm A')
            },
            modalTsaCheck: false
        });
    }

    handleCurrentAwb = (e) => {
        console.log(e.target.value);
        const s_mawb = e.target.value;

        const selectedAwb = this.state.myExportAwbs.filter(a => a.s_mawb === s_mawb)[0];

        this.setState({
            currentAwb: selectedAwb
        }, () => {
            console.log(this.state.currentAwb);
        });
    }

    handleBdg = () => {
        this.setState(prevState => ({
            b_dg: !prevState.b_dg
        }), () => {
            console.log(this.state.b_dg);
        });
    };

    trackAwbDetailsProgress = () => {
        let completePercentage = 0;
        const requiredInputFields = ['s_transport_type', 's_flight_number', 't_depart_date', 's_origin', 's_destination', 's_port_of_unlading', 'i_pieces', 'i_weight', 's_commodity'];
        if(this.requiredFieldsComplete()) {
            completePercentage = 100;
        } else {
            for(let i = 0; i < requiredInputFields.length; i++) {
                if(this.state[requiredInputFields[i]] !== null && this.state[requiredInputFields[i]].length > 0) {
                    completePercentage += 11;
                }
            }    
        }

        return completePercentage;
    };

    handleTransportType = (e) => {
        console.log(e.target.value);
        this.setState({
            s_transport_type: e.target.value
        });
    };

    handleInput = (e) => {
        const property = e.target.id;
        const value = e.target.value;
        this.setState({
            [property]: value
        }, () => {
            console.log(this.state);
            console.log(this.state[property]);
        });
    }

    requiredFieldsComplete = () => {
        const requiredInputFields = ['s_transport_type', 's_flight_number', 't_depart_date', 's_origin', 's_destination', 's_port_of_unlading', 'i_pieces', 'i_weight', 's_commodity'];
        for(let i = 0; i < requiredInputFields.length; i++) {
            if(this.state[requiredInputFields[i]] === null || this.state[requiredInputFields[i]].length === 0) {
                return false;
            }
        }
        return true;
    }

    enableFinalSubmit = () => {
        if(this.idCheckComplete() && this.state.tsaSaveInfo.saved && this.requiredFieldsComplete()) {
            return true;
        }
        return false;
    }

    handleFinalSubmit = (success) => {
        let set_s_company_driver_id_type_2 = this.state.s_company_driver_id_type_2;
        let set_s_company_driver_id_num_2 = this.state.s_company_driver_id_num_2;

        let set_b_counter_reject = this.state.b_counter_reject;
        let set_s_counter_reject_agent = this.state.s_counter_reject_agent;
        let set_t_counter_reject_time = this.state.t_counter_reject_time;

        if(!success) {
            set_b_counter_reject = true;
            set_s_counter_reject_agent = this.props.user && this.props.user.name;
            set_t_counter_reject_time = moment().local().format('MM/DD/YYYY hh:mm A');
        }

        if(this.state.s_company_driver_id_num_2 === null || this.state.d_company_driver_id_expiration_2 === null) {
            set_s_company_driver_id_type_2 = 'N/A';
            set_s_company_driver_id_num_2 = 'N/A';
        }

        const email = this.props.user && this.props.user.name;
        const now = moment().local().format('MM/DD/YYYY hh:mm A');
        email && 
        this.setState({
            s_kiosk_submitted_agent: email,
            s_counter_assigned_agent: email,
            s_counter_by: email,
            t_counter_endtime: now,
            t_created: now,
            s_created_by: email,
            t_modified: now,
            s_modified_by: email,
            s_company_driver_id_type_2: set_s_company_driver_id_type_2,
            s_company_driver_id_num_2: set_s_company_driver_id_num_2,
            s_status: success ? 'DOCUMENTED' :  'REJECTED',
            b_counter_reject: set_b_counter_reject,
            s_counter_reject_agent: set_s_counter_reject_agent,
            t_counter_reject_time: set_t_counter_reject_time
        }, () => {
            console.log(`============= SUBMITTED =============`);
            //console.log(this.state);
            this.createExportItem(success);
            //this.updateProcessedQueue();
            //resetInputAwbDetails(); //consider using timeout?
        });

        // var myWindow = window.open("", "MsgWindow", "width=200,height=100");
        // myWindow.document.write("<p>This is 'MsgWindow'. I am 200px wide and 100px tall!</p>");
    }

    resetInputAwbDetails = () => {
        this.setState({
            s_transport_type: '',
            s_flight_number: '',
            t_depart_date: '',
            s_origin: '',
            s_destination: '',
            s_port_of_unlading: '',
            i_pieces: '',
            i_weight: '',
            s_commodity: '',
            b_dg: false,
            s_shc1: '',
            s_shc2: '',
            s_shc3: '',
            s_shc4: '',
            s_shc5: '',
            s_notes: '',
            s_counter_reject_reason: '',
            //
            b_screened: null,
            s_ccsf: null,
            s_iac: null,
            s_non_iac: null,
            screeningType: null,
            foundIac: null,
            foundCcsf: null,
            tsaSaveInfo: {
                saved: false,
                time: null
            },            
        }, () => {
            console.log(this.state.s_counter_reject_reason);
        });
    }

    testHandleRequiredFields = () => {
        for(let key in this.state) {
            console.log(`${key} = ${this.state[key]}`);
        }
    };

    createExportItem = (success) => {
        // createExportItem
        // updateProcessedQueue
        // myAwbsQuery
        // updateSelectedAwb
        // resetInputAwbDetails

        const {
            s_unit,
            s_awb_type,
            s_status,
            s_mawb,
            s_priority,
            s_airline,
            s_airline_code,
            s_flight_number,
            t_depart_date,
            s_origin,
            s_destination,
            i_weight,
            i_pieces,
            s_transport_type,
            s_iac,
            s_port_of_unlading,
            s_commodity,
            s_shc1,
            s_shc2,
            s_shc3,
            s_shc4,
            s_shc5,
            b_dg,
            b_screened,
            s_ccsf,
            s_non_iac,
            s_language,
            b_sms_enabled,
            s_sms,
            s_company,
            s_company_driver_name,
            s_company_driver_id_type_1,
            s_company_driver_id_num_1,
            d_company_driver_id_expiration_1,
            b_company_driver_photo_match_1,
            s_company_driver_id_type_2,
            s_company_driver_id_num_2,
            d_company_driver_id_expiration_2,
            b_company_driver_photo_match_2,
            b_counter_reject,
            s_counter_reject_agent,
            t_counter_reject_time,
            s_counter_reject_reason,
            s_kiosk_submitted_agent,
            t_kiosk_submitteddatetime,
            s_counter_assigned_agent,
            t_counter_assigned_start,
            s_counter_by,
            t_counter_start_time,
            t_counter_endtime,
            t_created,
            s_created_by,
            t_modified,
            s_modified_by,
            s_transaction_id,
            s_mawb_id,
            s_notes            
        } = this.state;

        const acceptanceSheet = renderToString(
            <CreateAcceptanceSheet
                b_dg={b_dg}
                b_screened={b_screened}
                s_mawb={s_mawb}
                i_pieces={i_pieces}
                i_weight={i_weight}
                s_transport_type={s_transport_type}
                s_airline_code={s_airline_code}
                s_airline={s_airline}
                t_depart_date={t_depart_date}
                s_origin={s_origin}
                s_destination={s_destination}
                s_port_of_unlading={s_port_of_unlading}
                s_commodity={s_commodity}
                s_iac={s_iac}
                s_ccsf={s_ccsf}
                s_shc1={s_shc1}
                s_shc2={s_shc2}
                s_shc3={s_shc3}
                s_shc4={s_shc4}
                s_shc5={s_shc5}
                s_company_driver_name={s_company_driver_name}
                s_company={s_company}
                s_company_driver_id_type_1={s_company_driver_id_type_1}
                s_company_driver_id_num_1={s_company_driver_id_num_1}
                d_company_driver_id_expiration_1={d_company_driver_id_expiration_1}
                b_company_driver_photo_match_1={b_company_driver_photo_match_1}
                s_company_driver_id_type_2={s_company_driver_id_type_2}
                s_company_driver_id_num_2={s_company_driver_id_num_2}
                d_company_driver_id_expiration_2={d_company_driver_id_expiration_2}
                b_company_driver_photo_match_2={b_company_driver_photo_match_2}
                s_kiosk_submitted_agent={s_kiosk_submitted_agent}
                getProcessAgentName={false}
                user={this.props.user}
                t_created={t_created}
            />
        );

        axios.post(`${this.props.eosWebApiUrl}/createExportItem`, {
            data: {
                procedureData: {
                    s_unit,
                    s_awb_type,
                    s_status,
                    s_mawb,
                    s_priority,
                    s_airline,
                    s_airline_code,
                    s_flight_number,
                    t_depart_date,
                    s_origin,
                    s_destination,
                    i_weight,
                    i_pieces,
                    s_transport_type,
                    s_iac,
                    s_port_of_unlading,
                    s_commodity,
                    s_shc1,
                    s_shc2,
                    s_shc3,
                    s_shc4,
                    s_shc5,
                    b_dg,
                    b_screened,
                    s_ccsf,
                    s_non_iac,
                    s_language,
                    b_sms_enabled,
                    s_sms,
                    s_company,
                    s_company_driver_name,
                    s_company_driver_id_type_1,
                    s_company_driver_id_num_1,
                    d_company_driver_id_expiration_1,
                    b_company_driver_photo_match_1,
                    s_company_driver_id_type_2,
                    s_company_driver_id_num_2,
                    d_company_driver_id_expiration_2,
                    b_company_driver_photo_match_2,
                    b_counter_reject,
                    s_counter_reject_agent,
                    t_counter_reject_time,
                    s_counter_reject_reason,
                    s_kiosk_submitted_agent,
                    t_kiosk_submitteddatetime,
                    s_counter_assigned_agent,
                    t_counter_assigned_start,
                    s_counter_by,
                    t_counter_start_time,
                    t_counter_endtime,
                    t_created,
                    s_created_by,
                    t_modified,
                    s_modified_by,
                    s_transaction_id,
                    s_mawb_id,
                    s_notes
                },
                otherData: {
                    success,
                    acceptanceSheet,
                    s_mawb,
                    s_created_by
                }
            }
        }, {
            headers: {'Authorization': `Bearer ${this.props.eosWebAuthCode}`}
        }).then(response => {
            console.log(response);
            if(success) {
                this.launchCreateAcceptanceSheet(acceptanceSheet);
            } else {
                this.props.createSuccessNotification('AWB Rejected');
            } 
            this.updateProcessedQueue(success);
        }).catch(error => {
            console.log(error);
        });
    }

    updateProcessedQueue = (success) => {
        const email = this.props.user && this.props.user.name;
        const now = moment().local().format('MM/DD/YYYY hh:mm A');

        const t_modified = now;
        const s_modified_by = email;
        const s_status = success ? 'DOCUMENTED' : 'REJECTED';
        const s_trucking_driver = this.state.s_company_driver_name;
        const t_counter_start = this.state.t_counter_start_time;
        const t_counter_end = this.state.t_counter_endtime;
        const s_notes = this.state.s_notes;
        const s_mawb_id = this.state.s_mawb_id;
        // const b_counter_reject = this.state.b_counter_reject;
        let b_counter_reject = false;
        let s_counter_reject_agent = null
        let t_counter_reject_time = null;
        if (!success) {
            s_counter_reject_agent = email;
            t_counter_reject_time = now;
            b_counter_reject = true;
        }

        const s_counter_reject_reason = this.state.s_counter_reject_reason;

        axios.put(`${this.props.eosWebApiUrl}/updateProcessedQueue`, {
            t_modified,
            s_modified_by,
            s_status,
            s_trucking_driver,
            t_counter_start,
            t_counter_end,
            s_notes,
            s_mawb_id,
            b_counter_reject,
            s_counter_reject_agent,
            t_counter_reject_time,
            s_counter_reject_reason
        }, {
            headers: {'Authorization': `Bearer ${this.props.eosWebAuthCode}`}
        }).then(response => {
            this.myAwbsQuery(email);
        }).catch(err => {
            console.log(err);
        });
    };


    launchCreateAcceptanceSheet = (acceptanceSheet) => {
        const myWindow = window.open("", "MsgWindow", "width=1920,height=1080");
        myWindow.document.write(acceptanceSheet);
    }
    
    handleCreateAwbType = (e) => {
        console.log(e.target.value);
        this.setState({
            createAwbType: e.target.value
        });
    };

    enableCreateAwb = () => {
        if(this.state.createAwbNum.length === 11 && this.state.createAwbType.length > 0) {
            return true;
        }
        return false;
    };

    handleCreateAwb = () => {
        console.log(`handleCreateAwb clicked`);
        const agent = this.props.user && this.props.user.name;
        const now = moment().local().format('MM/DD/YYYY hh:mm A');
        const t_created = now;
        const s_created_by = agent;
        const t_modified = now;
        const s_modified_by = agent
        const s_trucking_company = this.state.s_company;
        const s_trucking_driver = this.state.s_company_driver_name;
        const s_trucking_cell = this.state.s_sms;
        const b_trucking_sms = this.state.b_sms_enabled;
        const s_trucking_email = this.state.s_trucking_email;
        const s_trucking_language = this.state.s_language;
        const s_mawb = this.state.createAwbNum;
        const s_type = this.state.createAwbType;
        const s_status = 'DOCUMENTING';
        const s_priority = 'REGULAR';
        const s_transaction_id = this.state.s_transaction_id;
        const t_kiosk_submitted = now;
        const s_state = this.state.s_state === 'MIXED' || this.state.createAwbType !== this.state.s_state ? 'MIXED' : this.state.createAwbType;
        const s_counter_ownership_agent = agent;
        const t_counter_ownership = now;
        const s_mawb_id = uuidv4();
        const s_unit = this.state.s_unit;
        const s_airline = this.state.s_airline;
        const s_airline_code = this.state.s_airline_code;
        const t_kiosk_start = now;

        axios.post(`${this.props.eosWebApiUrl}/createAwb`, {
            t_created,
            s_created_by,
            t_modified,
            s_modified_by,
            s_trucking_company,
            s_trucking_driver,
            s_trucking_cell,
            b_trucking_sms,
            s_trucking_email,
            s_trucking_language,
            s_mawb,
            s_type,
            s_status,
            s_priority,
            s_transaction_id,
            t_kiosk_submitted,
            s_state,
            s_counter_ownership_agent,
            t_counter_ownership,
            s_mawb_id,
            s_unit,
            s_airline,
            s_airline_code,
            t_kiosk_start
        }, {
            headers: {'Authorization': `Bearer ${this.props.eosWebAuthCode}`}
        }).then(response => {
            //console.log(response);
            this.setState({
                createAwbNum: '',
                createAwbType: '',
                modalCreateAwbOpen: false
            }, () => {
                this.myAwbsQuery(this.props.user.name);
                this.props.createSuccessNotification('AWB Created');
            });
        }).catch(error => {
            console.log(error);
        });
    };

    handleReject = () => {
        this.setState({
            modalRejectionConfirm: false
        }, () => {
            this.handleFinalSubmit(false);
        });
    };

    maxLengthCheck = (object) => {
        if (object.target.value.length > object.target.maxLength) {
         object.target.value = object.target.value.slice(0, object.target.maxLength)
          }
    }

    handleModalUniversal = (modalOpenProperty) => {
        this.setState(prevState => ({
            [modalOpenProperty]: !prevState[modalOpenProperty]
        }), () => {

        });
    }

    resolveBorder = (field) => {
        if(field && field.length > 0) {
            return '1px solid #d7d7d7';
        }
        return '2px solid red';
    }

    render() {

        const {
            myExportAwbs, 
            s_awb_type, 
            s_mawb, s_airline, 
            s_airline_code, 
            s_company, 
            s_company_driver_name, 
            s_sms, 
            s_trucking_email, 
            s_transport_type,
            s_flight_number,
            t_depart_date,
            s_origin,
            s_destination,
            s_port_of_unlading,
            i_pieces,
            i_weight,
            s_commodity,
            b_dg,
            s_shc1,
            s_shc2,
            s_shc3,
            s_shc4,
            s_shc5,
            b_screened,
            s_notes,
            s_counter_reject_reason,
            //Non-essential:
            createAwbNum,
            createAwbType,
            modalCreateAwbOpen,
            sm_driver_photo
        } = this.state;

        const {user, authButtonMethod, isAuthenticated, baseApiUrl, headerAuthCode, promptUserLocation, selectUserLocation, setUserLocation, saveUserLocation, launchModalChangeLocation, halfWindow, getWidth, displaySubmenu, handleDisplaySubmenu} = this.props;

        return(
            <Fragment>
                <div className={`mx-3`} style={{backgroundColor: '#f8f8f8' }}>
                    
                    <div className='row'>
                        <div className='col-12'>
                            <h4>Export</h4>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-4'>
                            <GradientWithRadialProgressCard
                                icon="fas fa-id-card"
                                title={`ID Check`}
                                percent={this.idCheckComplete() ? 100 : 0}
                                progressText={this.idCheckComplete() ? '100%' : '0%'}
                                onClick={this.handleModal}
                            />
                        </div>
                        <div className='col-4'>
                            <GradientWithRadialProgressCard
                                icon="fas fa-shield-alt"
                                title={`TSA Check`}
                                percent={this.state.tsaSaveInfo.saved ? 100 : 0}
                                progressText={this.state.tsaSaveInfo.saved ? '100%' : '0%'}
                                onClick={this.handleModalTsaCheck}
                            />
                        </div>
                        <div className='col-4'>
                            <GradientWithRadialProgressCard
                                icon="fas fa-tasks"
                                title={`AWB Details`}
                                percent={this.trackAwbDetailsProgress()}
                                progressText={`${this.trackAwbDetailsProgress()}%`}
                            />
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-12'>
                            <MyAwbs 
                                myExportAwbs={myExportAwbs}
                                updateSelectedAwb={this.updateSelectedAwb}
                                handleModalUniversal={this.handleModalUniversal}
                            />
                        </div>
                    </div>

                    <div className={`row my-2`}>
                        <Col md={12} lg={6}>

                            <DriversInfo 
                                s_company={s_company}
                                s_company_driver_name={s_company_driver_name}
                                s_sms={s_sms}
                                s_trucking_email={s_trucking_email}                            
                            />

                            <Master 
                                s_mawb={s_mawb}
                                s_origin={s_origin}
                                s_port_of_unlading={s_port_of_unlading}
                                s_destination={s_destination}
                                i_pieces={i_pieces}
                                i_weight={i_weight}
                                s_commodity={s_commodity}
                                b_dg={b_dg}
                                handleBdg={this.handleBdg}
                                b_screened={b_screened}
                                handleInput={this.handleInput}
                                resolveBorder={this.resolveBorder}
                                s_shc1={s_shc1}
                                s_shc2={s_shc2}
                                s_shc3={s_shc3}
                                s_shc4={s_shc4}
                                s_shc5={s_shc5}
                            />

                        </Col>

                        <Col md={12} lg={6}>
                            
                            <Flight 
                                s_airline={s_airline}
                                s_airline_code={s_airline_code}
                                s_transport_type={s_transport_type}
                                handleTransportType={this.handleTransportType}
                                s_flight_number={s_flight_number}
                                t_depart_date={t_depart_date}
                                handleInput={this.handleInput}
                                resolveBorder={this.resolveBorder}                
                            />

                            <Notes
                                s_notes={s_notes}
                                handleInput={this.handleInput}
                            />

                            <Finalize 
                                enableFinalSubmit={this.enableFinalSubmit}
                                handleFinalSubmit={this.handleFinalSubmit}
                                handleModalRejectionConfirm={this.handleModalRejectionConfirm}                                    
                            />
                        </Col>
                    </div>

                </div>
                
                <div>

                    <IdCheckModal 
                        open={this.state.modalIdType1} 
                        handleModal={this.handleModal}
                        handleModalIdType2={this.handleModalIdType2}

                        b_company_driver_photo_match_1={this.state.b_company_driver_photo_match_1}
                        handlePhotoMatch1Change={this.handlePhotoMatch1Change}

                        s_company_driver_id_type_1={this.state.s_company_driver_id_type_1}
                        handleIdType1={this.handleIdType1}

                        d_company_driver_id_expiration_1={this.state.d_company_driver_id_expiration_1}
                        handleIdType1Exp={this.handleIdType1Exp}

                        s_company_driver_name={this.state.s_company_driver_name}
                        handleDriverName={this.handleDriverName}

                        s_company_driver_id_num_1={this.state.s_company_driver_id_num_1}
                        handleIdNum1={this.handleIdNum1}

                        idCheckComplete={this.idCheckComplete}
                        handleClickIdCheckSave={this.handleClickIdCheckSave}

                        handleInput={this.handleInput}

                        sm_driver_photo={sm_driver_photo}
                    />

                    <IdCheckModal2 
                        open={this.state.modalIdType2} 
                        handleModal={this.handleModal}
                        handleModalIdType2={this.handleModalIdType2}

                        b_company_driver_photo_match_2={this.state.b_company_driver_photo_match_2}
                        handlePhotoMatch2Change={this.handlePhotoMatch2Change}

                        s_company_driver_id_type_2={this.state.s_company_driver_id_type_2}
                        handleIdType2={this.handleIdType2}

                        d_company_driver_id_expiration_2={this.state.d_company_driver_id_expiration_2}
                        handleIdType2Exp={this.handleIdType2Exp}

                        s_company_driver_name={this.state.s_company_driver_name}
                        handleDriverName={this.handleDriverName}

                        s_company_driver_id_num_2={this.state.s_company_driver_id_num_2}
                        handleIdNum2={this.handleIdNum2}

                        idCheckComplete={this.idCheckComplete}
                        handleClickIdCheckSave={this.handleClickIdCheckSave}

                        handleInput={this.handleInput}

                        sm_driver_photo={sm_driver_photo}
                    />

                    <TsaCheckModal
                        open={this.state.modalTsaCheck}
                        handleModal={this.handleModalTsaCheck}

                        b_screened={this.state.b_screened}
                        handleBscreened={this.handleBscreened}
                        handleScreeningType={this.handleScreeningType}
                        screeningType={this.state.screeningType}

                        s_iac={this.state.s_iac}
                        checkIac={this.checkIac}
                        foundIac={this.state.foundIac}
                        handleIacNum={this.handleIacNum}

                        s_ccsf={this.state.s_ccsf}
                        checkCcsf={this.checkCcsf}
                        foundCcsf={this.state.foundCcsf}
                        handleCcsfNum={this.handleCcsfNum}

                        s_non_iac={this.state.s_non_iac}
                        handleNonIac={this.handleNonIac}

                        enableSavingTsaCheck={this.enableSavingTsaCheck}
                        handleSaveTsaCheck={this.handleSaveTsaCheck}
                        tsaSaveInfo={this.state.tsaSaveInfo}
                    />

                    <RejectConfirmModal 
                        open={this.state.modalRejectionConfirm}
                        handleModal={this.handleModalRejectionConfirm}
                        s_counter_reject_reason={this.state.s_counter_reject_reason}
                        handleInput={this.handleInput}
                        handleReject={this.handleReject}
                    />

                    <ModalCreateAwb 
                        open={modalCreateAwbOpen}
                        handleModalCreateAwb={this.handleModalUniversal}
                        handleCreateAwbType={this.handleCreateAwbType}
                        enableCreateAwb={this.enableCreateAwb}
                        handleCreateAwb={this.handleCreateAwb}
                        handleInput={this.handleInput}
                        createAwbNum={createAwbNum}
                        createAwbType={createAwbType}
                    />

                </div>
            </Fragment>
        );
    }
}

// export default injectIntl(Export);
export default withAuthenticationRequired(Export);

