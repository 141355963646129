import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label } from 'reactstrap';
import { Field } from 'formik';

export default ({
    values,
    modal,
    setModal,
    createImportItem,
    push
}) => {

    const toggle = () => setModal(!modal);

    return (
        <div>
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader>Reject</ModalHeader>
                <ModalBody>
                    <Label>Enter Reject Reason</Label>
                    <Field name={'s_counter_reject_reason'} component={'textarea'} className={'form-control'} style={{ height: '200px' }} />
                </ModalBody>
                <ModalFooter>
                    <Button 
                        color="danger" 
                        onClick={() => createImportItem(true, push)}
                        disabled={!values.s_counter_reject_reason || values.s_counter_reject_reason.length === 0}
                    >
                        Reject
                    </Button>
                    <Button color="secondary" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}