import React, {Fragment} from 'react';
import Switch from "rc-switch";
import "rc-switch/assets/index.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Input,
    Label,
    Row,
    Form,
    Col,
    Table
  } from "reactstrap";
import moment from 'moment';

const ModalLocate = ({
    open, 
    handleModal,
    ffm,
    rackData,
    resolveNullHouseData,
    updateArrayHouseValue,
    resolveSelectedHouseValue,
    selectedTower,
    setSelectedTower,
    selectedLevel,
    setSelectedLevel,
    selectedLocation,
    setSelectedLocation,
    completeLocation,
    locationAvailable,
    enableSpecialLocation,
    setEnableSpecialLocation,
    specialLocationSelected,
    setSpecialLocationSelected,
    locateInRack,
    pcsToLocate,
    setPcsToLocate,
    enableLocate
}) => {

    const towerLetters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L'];

    return (
        <Fragment>
            <Modal isOpen={open} toggle={(e) => handleModal(!open)}>
                <div className="modal-content px-5" style={{width: '900px'}}>
                    <div className="modal-body mx-auto">
                        <div className='text-center'>
                            <h1>Locate AWB</h1>
                        </div>
                        <div>
                            <Row>
                                <Col md='5' lg='5' className='mr-5'>
                                    <Row className='pb-1'>
                                        <h4>Flight: {ffm && ffm.s_flightid}</h4>
                                    </Row>
                                    <Row className='pb-1'>
                                        <h4>ULD: {ffm && ffm.s_uld}</h4>
                                    </Row>
                                    <Row className='pb-1'>
                                        <h4>AWB: {ffm && ffm.s_mawb}</h4>
                                    </Row>
                                    <Row>
                                        <h4 className='pr-2'>House:</h4>
                                        {
                                            resolveNullHouseData && resolveNullHouseData(ffm && ffm.houseData) ?
                                            <h4>None</h4> :
                                            <select style={{width: '110px'}} value={ffm && resolveSelectedHouseValue(ffm)} onChange={(e) => updateArrayHouseValue(e.target.value)}>
                                            {
                                                ffm && ffm.houseData.map((d, i) => 
                                                    <option value={`${ffm.i_id},${i}`} key={i}>{d.s_house_waybill_summaries_serial_1}</option>
                                                )
                                            }
                                            </select>
                                        }
                                    </Row>
                                    <Row className='pt-4'>
                                        <Table striped>
                                            <thead>
                                                <tr>
                                                    <th>Location</th>
                                                    <th>Pieces</th>
                                                    <th>House</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    rackData && rackData.map((d, i) =>
                                                        <tr key={i}>
                                                            <td>{d.s_location}</td>
                                                            <td>{d.i_pieces}</td>
                                                            <td>{d.s_hawb}</td>
                                                        </tr> 
                                                    )
                                                }
                                            </tbody>
                                        </Table>
                                    </Row>
                                </Col>
                                <Col md='6' lg='6'>
                                    <Row className='pb-2'>
                                        <h4 className='mr-2'>How many pieces:</h4>
                                        <input type='number' style={{display: 'inline'}} value={pcsToLocate} onChange={(e) => setPcsToLocate(e.target.value)} />
                                    </Row>
                                    <Row className='py-2 text-center'>
                                        <Col md='12' lg='12' style={{backgroundColor: 'grey'}}>
                                            <h4>Tower</h4>
                                        </Col>
                                        {
                                            towerLetters.map((l, i) =>
                                                <Col key={i} md='1' lg='1' className={`${selectedTower === l && 'selectedLocateButton'} px-0 locateButton`} onClick={() => setSelectedTower(l)} style={{border: '1px solid black'}}>
                                                    {l}
                                                </Col>
                                            )
                                        }
                                    </Row>
                                    <Row className='pt-2'>
                                        <Col md='12' lg='12' className='text-center' style={{backgroundColor: 'grey'}}>
                                            <h4>Level</h4>
                                        </Col>
                                    </Row>
                                    <Row className='text-center'>
                                        {
                                            [...Array(3)].map((l, i) =>
                                                <Col key={i} md='4' lg='4' className={`${selectedLevel === i+1 && 'selectedLocateButton'} px-0 locateButton`} onClick={() => setSelectedLevel(i+1)} style={{border: '1px solid black'}}>
                                                    {i+1}
                                                </Col>
                                            )
                                        }
                                    </Row>
                                    <Row className='pt-2'>
                                        <Col md='12' lg='12' className='text-center' style={{backgroundColor: 'grey'}}>
                                            <h4>Location</h4>
                                        </Col>
                                    </Row>
                                    <Row className='text-center'>
                                        {
                                            [...Array(24)].map((l, i) =>
                                                <Col key={i} md='1' lg='1' className={`${selectedLocation === i+1 && 'selectedLocateButton'} px-0 locateButton`} onClick={() => setSelectedLocation(i+1)} style={{border: '1px solid black'}}>
                                                    {i+1}
                                                </Col>
                                            )
                                        }
                                    </Row>
                                    <Row className='mt-4'>
                                        <input type='checkbox' onChange={(e) => setEnableSpecialLocation(e.target.checked)} className='mr-2' style={{position: 'relative', top: '3px'}} />
                                        <h4 className='mr-2'>Special Location:</h4>
                                        <select onChange={(e) => setSpecialLocationSelected(e.target.value)}> 
                                            <option value={""}></option>
                                            <option value={"BYARD"}>BYARD</option>
                                            <option value={"WCOOL"}>WCOOL</option>
                                            <option value={"COOL1"}>COOL1</option>
                                            <option value={"COOL2"}>COOL2</option>
                                            <option value={"COOL3"}>COOL3</option>
                                            <option value={"COOL4"}>COOL4</option>
                                            <option value={"DGRAC"}>DGRAC</option>
                                            <option value={"SMRAC"}>SMRAC</option>
                                            <option value={"CHOLD"}>CHOLD</option>
                                            <option value={"LIVE1"}>LIVE1</option>
                                            <option value={"VAL"}>VAL</option>
                                        </select>
                                    </Row>
                                    <Row className='mt-3 px-0'>
                                        <Col md='7' lg='7' className='pl-0'>
                                            <h4>Location Selected: {enableSpecialLocation ? specialLocationSelected : completeLocation}</h4>
                                        </Col>
                                        <Col md='5' lg='5' className='pl-0' className={`${locationAvailable ? 'bg-success' : 'bg-danger'}`}>
                                            <h4 className='text-center py-3 text-white' style={{fontWeight: 'bold', height: '52px'}}>{locationAvailable ? 'Open' : 'Occupied'}</h4>
                                        </Col>
                                    </Row>
                                    <Row className='mt-2'>
                                        <Col md='12' lg='12' className='text-right px-0'>
                                            <Button color='primary' disabled={!enableLocate()} onClick={() => locateInRack()}>Submit</Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </Modal>
        </Fragment>
    );
}

export default ModalLocate;