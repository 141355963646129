import React from 'react';
import { Row, Col, Card, CardBody, Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

export default ({
    awb,
    width
}) => {

    const history = useHistory();

    const resolveBooleanValue = (condition) => condition ? 'Yes' : 'No';

    const jumpToProcess = (awb) => {
        if (awb.s_type === 'EXPORT') {
            history.push('/export');
        } else {
            history.push('/import');
        }
    } 

    const resolveAwbBackground = (awb) => {
        const { s_type } = awb;

        switch(s_type) {
            case 'EXPORT':
                return '#6BB4DD';
            case 'IMPORT':
                // return '#6BB4DD';
                return '#61B996';
        }
    };

    const formatMawb = (mawb) => {
        return mawb.substr(0, 13).replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
    }

    return (
        <Col md={ width >= 1600 ? 6 : 12} className='my-1'>
            <Card className='custom-opacity-card' style={{borderRadius: '0.75rem'}}>
                <CardBody className='custom-card-transparent card-shadow py-3' style={{ backgroundColor: resolveAwbBackground(awb) }}>
                    <div className='float-left' style={{ width: '50%' }}>
                        <h2 className='font-weight-bold'>{formatMawb(awb.s_mawb)}</h2>
                        <Row>
                            <Col md={6}>
                                <h4>ISC Paid: {resolveBooleanValue(awb.s_payment_type === 'ISC')}</h4>
                                <h4>Located: {resolveBooleanValue(awb.s_located_status === 'LOCATED')}</h4>
                            </Col>
                            <Col md={6}>
                                <h4>On Hold: {resolveBooleanValue(awb.b_hold)}</h4>
                                <h4>Valuable: {resolveBooleanValue(awb.s_location === 'VAL')}</h4>
                            </Col>
                        </Row>
                    </div>
                    <div className='float-right text-right'>
                        <h4>Added today at {moment(awb.t_created).format('hh:mm A')}</h4>
                        <h4 className='mb-0'>{awb.s_type}</h4>
                        <br />
                        <Button color='light' className='text-right mr-0' onClick={() => jumpToProcess(awb)}>Process this AWB</Button>
                    </div>
                </CardBody>
            </Card>
        </Col>
    );
}