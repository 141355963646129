import React, {Fragment, useState, useEffect, useRef} from 'react';
import {withRouter} from 'react-router-dom';
import { Table, Button, Input, Card, CardBody, Breadcrumb, BreadcrumbItem, Nav, NavItem, TabContent, TabPane, Row, Col  } from "reactstrap";
import { NavLink } from "react-router-dom";
import classnames from "classnames";
import moment from 'moment';
import axios from 'axios';

import ModalReportDamage from './ModalReportDamage';
import ModalNotListed from './ModalNotListed';
import {CircularProgressbar} from "react-circular-progressbar";

const AcceptUld = ({
    user, 
    eosWebApiUrl, 
    eosWebAuthCode, 
    createSuccessNotification,
    s_unit
}) => {

    const [s_pou, set_s_pou] = useState(null);
    const [userEmail, setUserEmail] = useState(null);

    const [flights, setFlights] = useState([]);
    const [firstAccepted, setFirstAccepted] = useState('none');
    const [lastAccepted, setLastAccepted] = useState('none');
    const [ulds, setUlds] = useState([]);
    const [acceptedUldsCount, setAcceptedUldsCount] = useState();
    const [s_flight_id, set_s_flight_id] = useState(null);
    const [d_arrival_date, set_d_arrival_date] = useState('');
    const [selectedFlight, setSelectedFlight] = useState(null);
    const [uldPercentageAccepted, setUldPercentageAccepted] = useState(0);

    //Damage Report
    const [comments, setComments] = useState('');
    const [fileInputKey, setFileInputKey] = useState(0);
    const [files, setFiles] = useState([]);
    const [modalReportDamageOpen, setModalReportDamageOpen] = useState(false);
    const [awb_uld, set_awb_uld] = useState('');

    //Modal Not Listed
    const [modalNotListedOpen, setModalNotListedOpen] = useState(false);
    const [s_uld, set_s_uld] = useState('');
    const [s_notes, set_s_notes] = useState('');

    const selectUldAcceptanceFlights = () => {
        axios.post(`${eosWebApiUrl}/selectUldAcceptanceFlights`, {
            d_arrival_date,
            s_pou
        }, {
            headers: {'Authorization': `Bearer ${eosWebAuthCode}`}
        }).then(response => {
            console.log(response.data);
            setFlights(response.data);
        }).catch(error => {

        });
    }

    const selectFlightUlds = () => {
        axios.post(`${eosWebApiUrl}/selectFlightUlds`, {
            s_flight_id,
            s_pou
        }, {
            headers: {'Authorization': `Bearer ${eosWebAuthCode}`}
        }).then(response => {
            console.log(response.data);
            setUlds(response.data);
        }).catch(error => {

        });
    }

    const acceptRemoveUld = (id, accept) => {

        const s_status = accept ? 'RECEIVED' : 'MESSAGED';
        const b_accepted = accept ? true : false;
        const s_modified_by = userEmail;
        const s_user_accepted_uld = accept ? userEmail : null;
        const t_modified = moment().local().format('MM/DD/YYYY hh:mm A');
        const t_user_accepted_uld = accept ? moment().local().format('MM/DD/YYYY hh:mm A') : null;

        axios.post(`${eosWebApiUrl}/acceptRemoveUld`, {
            id,
            s_flight_id,
            s_status,
            s_modified_by,
            t_modified,
            s_user_accepted_uld,
            t_user_accepted_uld,
            b_accepted,
            s_pou
        }, {
            headers: {'Authorization': `Bearer ${eosWebAuthCode}`}
        }).then(response => {
            console.log(response.data);
            setUlds(response.data);
        }).catch(error => {

        });
    }

    const resolveAcceptedDates = () => {
        const acceptedUlds = ulds.filter(u => u.b_accepted);

        if (acceptedUlds.length > 0) {
            const orderedUlds = acceptedUlds.sort(sortByUldDate);

            const dates = {
                first: moment(orderedUlds[0].t_user_accepted_uld).format('MM/DD/YYYY HH:mm'),
                last: moment(orderedUlds[orderedUlds.length - 1].t_user_accepted_uld).format('MM/DD/YYYY HH:mm')
            }
            
            setFirstAccepted(dates.first);
            setLastAccepted(dates.last);
        } else {
            setFirstAccepted('none');
            setLastAccepted('none');
        }
    }

    const resolveSelectedFlight = () => {
        const flight = ulds.filter(u => u.s_flight_id === s_flight_id)[0];
        return flight;
    }

    const handleReportDamage = (uld) => {
        set_awb_uld(uld);
        setModalReportDamageOpen(true);
    }

    const submitDamageReport = () => {

        axios.post(`${eosWebApiUrl}/submitVisualReport`, {
            user: {
                email: user.name,
                displayName: user.name
            },
            awb_uld,
            comments, 
            files
          }, {
            headers: {'Authorization': `Bearer ${eosWebAuthCode}`}
          }).then(res => {
                console.log('DAMAGE REPORT SUBMITTED');
          }).catch(err => {
            console.log(err);
        });

        setModalReportDamageOpen(false);
        createSuccessNotification('Submitting your report - you will receive a confirmation email.');

    }

    const enableSubmitUld = () => {
        return selectedFlight !== null && s_uld.length >= 3;
    }

    const resolveUldNumber = (s_uld) => {
        const remainingString = s_uld.substr(3);
        const size = remainingString.length - 2;
        return s_uld.substr(3, size);
    } 

    const submitUld = () => {

        const now = moment().local().format('MM/DD/YYYY hh:mm A');

        const data = {
            s_flight_id,
            s_airline_code: selectedFlight.s_airline_code,
            s_flight_number: selectedFlight.s_flight_number,
            s_flight_serial: selectedFlight.s_flight_serial,
            d_arrival_date,
            s_origin: selectedFlight.s_pol,
            s_destination: s_pou,
            s_uld,
            s_uld_type: s_uld.toUpperCase() !== 'BULK' ? s_uld.substr(0, 3) : s_uld,
            s_uld_number: s_uld.toUpperCase() !== 'BULK' ? resolveUldNumber(s_uld) : s_uld,
            s_uld_code: s_uld.toUpperCase() !== 'BULK' ? s_uld.substr(s_uld.length - 2, 2) : s_uld,
            t_created: now,
            s_created_by: userEmail,
            s_modified_by: userEmail,
            t_modified: now,
            s_notes,
            s_pol: selectedFlight.s_pol,
            s_pou
        }

        axios.post(`${eosWebApiUrl}/submitUld`, {
            data
        }, {
            headers: {'Authorization': `Bearer ${eosWebAuthCode}`}
        }).then(response => {
            setUlds(response.data);
            createSuccessNotification('ULD Created');
            resetFields();
            setModalNotListedOpen(false);
        }).catch(error => {

        });
    }

    const resolveUldPercentAccepted = () => {

        if (ulds.length > 0) {
            const acceptedUlds = ulds.filter(u => u.b_accepted);
            setUldPercentageAccepted(Math.round((acceptedUlds.length * 1.0 / ulds.length * 1.0) * 100));
            
            console.log(acceptedUlds.length);
            console.log(ulds.length);
            console.log(Math.round((acceptedUlds.length * 1.0 / ulds.length * 1.0) * 100));
        } else {
            setUldPercentageAccepted(0);
        }
    }

    const resetFields = () => {
        set_s_notes('');
        set_s_uld('');
    }

    useEffect(() => {
        const email = user && user.name; 

        s_unit && set_s_pou(s_unit.substr(1, 3));
        email && setUserEmail(email);
    }, [user, s_unit]);

    useEffect(() => {
        if (moment(d_arrival_date).isValid()) {
            selectUldAcceptanceFlights();
        }
    }, [d_arrival_date, s_pou]);

    useEffect(() => {
        if (s_flight_id !== '' && s_flight_id !== null) {
            selectFlightUlds();
        }
    }, [s_flight_id]);

    useEffect(() => {
        resolveAcceptedDates();

        if (ulds.length > 0) {
            setSelectedFlight(resolveSelectedFlight());
            const acceptedUlds = ulds.filter(u => u.b_accepted);
            setAcceptedUldsCount(acceptedUlds.length);
            setUldPercentageAccepted(Math.round((acceptedUlds.length * 1.0 / ulds.length * 1.0) * 100));
        }
    }, [ulds]);

    const sortByUldDate = (a, b) => moment(a.t_user_accepted_uld) - moment(b.t_user_accepted_uld);

    const getFiles = (files) => setFiles(files);

    return (
        <Row>
            <Col md='12' lg='12'>
                <Row>
                    <div className={`mb-3 col-3`}>
                        <Card className='custom-opacity-card' style={{borderRadius: '0.75rem'}}>
                            <CardBody className='custom-card-transparent py-3' style={{backgroundColor: 'rgba(255,255,255,0.2)'}}>
                                <Row>
                                    <h4 className='pr-3'>Select Date: </h4>
                                    <input type='date' onChange={(e) => set_d_arrival_date(e.target.value)} style={{display: 'inline'}} />
                                </Row>
                                <Row className='mt-4'>
                                    <Table className={'table-row-hover'}>
                                        <thead>

                                        </thead>
                                        <tbody>
                                        {
                                            d_arrival_date !== '' && flights.length === 0 ? 
                                            <h4>No flights found.</h4> : 
                                            flights.map((f, i) => 
                                                <tr key={i} onClick={() => set_s_flight_id(f.s_flight_id)}  className={s_flight_id === f.s_flight_id ? 'table-row-selected' : ''}>
                                                    <td>
                                                        <img src={f.logo_url} style={{width: '170px', height: 'auto'}} />
                                                    </td>
                                                    <td>{f.s_flight_number}</td>
                                                </tr>
                                            )
                                        }
                                        </tbody>
                                    </Table>
                                </Row>
                            </CardBody>
                        </Card>
                    </div>

                    <div className={`mb-3 col-4`}>
                        <Card className='custom-opacity-card' style={{borderRadius: '0.75rem'}}>
                            <CardBody className='custom-card-transparent py-3' style={{backgroundColor: 'rgba(255,255,255,0.2)'}}>
                                <Row>
                                    <Col md='12' lg='12'>
                                        <h4 className='pr-3'style={{float:'left'}}>Messaged</h4>
                                        <Button style={{float:'right'}} disabled={!selectedFlight || selectedFlight === null} onClick={() => setModalNotListedOpen(true)}>ULD Not Listed</Button>
                                    </Col>
                                </Row>
                                <Row className='mt-4 mb-0'>
                                    <Table style={{tableLayout: 'fixed'}} className='mb-0'>
                                        <thead>
                                            <tr>
                                                <th width='28%'>ULD</th>
                                                <th width='50%'>Destinations</th>
                                                <th width='22%'></th>                                                        
                                            </tr>
                                        </thead>
                                        <tbody>

                                        </tbody>
                                    </Table>
                                </Row>
                                <Row style={{maxHeight: '576px', overflowY: 'scroll'}}>
                                    <Table className={'table-row-hover'} style={{tableLayout: 'fixed'}}>
                                        <thead></thead>    
                                        <tbody>
                                        {
                                            ulds.map((u, i) => !u.b_accepted && 
                                                <tr key={i}>
                                                    <td width='28%'>{u.s_uld}</td>
                                                    <td width='50%'>{u.s_destination}</td>
                                                    <td width='22%'>
                                                        <Button onClick={() => acceptRemoveUld(u.id, true)} className='py-1 px-1' color='primary'>Accept ULD</Button>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                        </tbody>
                                    </Table>
                                </Row>
                            </CardBody>
                        </Card>
                    </div>

                    <div className={`mb-3 col-5`}>
                        <Card className='custom-opacity-card' style={{borderRadius: '0.75rem'}}>
                            <CardBody className='custom-card-transparent py-3' style={{backgroundColor: 'rgba(255,255,255,0.2)'}}>
                                <Row>
                                    <Col md='12' lg='12'>
                                        <h4 className='pr-3' style={{float: 'left'}}>Accepted: {acceptedUldsCount} / {ulds.length}</h4>
                                        <div className="progress-bar-circle" style={{ width: 60, height: 60, float: 'right' }}>
                                            <CircularProgressbar
                                                strokeWidth={4}
                                                value={uldPercentageAccepted}
                                                text={`${uldPercentageAccepted}%`}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <h4 className='ml-2' style={{display: 'inline'}}>First: {firstAccepted} and Last: {lastAccepted}</h4>
                                </Row>
                                <Row className='mt-4 mb-0'>
                                    <Table style={{tableLayout: 'fixed'}} className='mb-0'>
                                        <thead>
                                            <tr>
                                                <th width='25%'>ULD</th>
                                                <th width='20%'>Accepted By</th>
                                                <th width='20%'>Accepted On</th>
                                                <th width='35%'></th>                                                        
                                            </tr>
                                        </thead>
                                        <tbody>

                                        </tbody>
                                    </Table>
                                </Row>
                                <Row style={{maxHeight: '590px', overflowY: 'scroll'}}>
                                    <Table className={'table-row-hover'} style={{tableLayout: 'fixed'}}>
                                        <thead></thead>    
                                        <tbody>
                                        {
                                            ulds.map((u, i) => u.b_accepted  && 
                                                <tr key={i}>
                                                    <td width='25%'>{u.s_uld}</td>
                                                    <td width='20%'>{u.sc_username_accepted_uld}</td>
                                                    <td width='20%'>{moment(u.t_user_accepted_uld).format('MM/DD/YYYY HH:mm')}</td>
                                                    <td width='35%'>
                                                        <Button onClick={() => acceptRemoveUld(u.id, false)} className='py-1 px-1 mr-1' color='warning'>Remove</Button>
                                                        <button onClick={() => handleReportDamage(u.s_uld)} className='btn btn-danger px-1 py-1'>Report Damage</button>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                        </tbody>
                                    </Table>
                                </Row>
                            </CardBody>
                        </Card>
                    </div>
                    
                    <ModalReportDamage 
                        open={modalReportDamageOpen}
                        handleModal={setModalReportDamageOpen}
                        comments={comments}
                        setComments={setComments}
                        getFiles={getFiles}
                        fileInputKey={fileInputKey}
                        awb_uld={awb_uld}
                        submitDamageReport={submitDamageReport}
                    />

                    <ModalNotListed 
                        open={modalNotListedOpen}
                        handleModal={setModalNotListedOpen}
                        s_pou={s_pou}
                        s_flight_id={s_flight_id}
                        d_arrival_date={d_arrival_date}
                        selectedFlight={selectedFlight}
                        s_uld={s_uld}
                        set_s_uld={set_s_uld}
                        s_notes={s_notes}
                        set_s_notes={set_s_notes}
                        enableSubmitUld={enableSubmitUld}
                        submitUld={submitUld}
                    />

                </Row>
            </Col>
        </Row>
    );
}

export default withRouter(AcceptUld);