import React from 'react';
import moment from 'moment';
import {
    CustomInput,
  } from "reactstrap";
import Switch from "rc-switch";
import "rc-switch/assets/index.css";
import {Row, Col, Table, Card, CardBody} from 'reactstrap';

import {useEffect, useRef, useState} from 'react';

const Flight = ({
    s_airline,
    s_airline_code,
    handleTransportType,
    s_transport_type,
    s_flight_number,
    t_depart_date,
    handleInput,
    resolveBorder
}) => {
    return (
        <Row className='py-2' style={{fontSize: '16px'}}>
            <Col md={12}>
                <Card className='custom-opacity-card' style={{borderRadius: '0.75rem'}}>
                    <CardBody className='custom-card-transparent pt-3 pb-0' style={{backgroundColor: 'rgba(255,255,255,0.2)'}}>
                        <h4>Flight:</h4>
                        <Table>
                            <thead>
                                <tr style={{backgroundColor: '#F3F3F3'}}>
                                    <th scope="col">Airline</th>
                                    <th scope="col">Airline Code</th>
                                    <th scope="col">Aircraft Type</th>
                                    <th scope="col">Flight Number</th>
                                    <th scope="col">Flight Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className='pb-0'>{s_airline}</td>
                                    <td className='pb-0'>{s_airline_code}</td>
                                    <td className='pb-0'>
                                        <div className={`btn-group aircraft-type ${s_transport_type.length === 0 ? 'input-required' : null}`} data-toggle="buttons">
                                            <label className="btn btn-info mr-2" style={{backgroundColor: `${s_transport_type === 'CAO' ? '#118496' : '#19A2B4'}`}}>
                                                <input type="radio" id='s_transport_type' value="CAO" onClick={(e) => handleTransportType(e)} style={{display: 'none'}} /> CAO
                                            </label>
                                            <label className="btn btn-info" style={{backgroundColor: `${s_transport_type === 'PAX' ? '#118496' : '#19A2B4'}`}}>
                                                <input type="radio" id='s_transport_type' value="PAX" onClick={(e) => handleTransportType(e)} style={{display: 'none'}} /> PAX
                                            </label>
                                        </div>
                                    </td>
                                    <td className='pb-0'><input type="text" id={'s_flight_number'} value={s_flight_number} onChange={(e) => handleInput(e)} className={`form-control`} style={{maxWidth: '180px', border: resolveBorder(s_flight_number)}}/></td>
                                    <td className='pb-0'>
                                        <input 
                                            type='date' 
                                            id={'t_depart_date'}
                                            className="form-control" 
                                            onChange={(e) => handleInput(e)}
                                            min={moment().format('YYYY-MM-DD')}
                                            style={{border: resolveBorder(t_depart_date)}}
                                            value={t_depart_date}
                                        >
                                        </input>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </CardBody>
                </Card>
            </Col>
        </Row>

    );
}

export default Flight;