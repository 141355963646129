import React, { useState, useContext } from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { AppContext } from '../components/context/index';
import {withRouter} from 'react-router-dom';
import "rc-switch/assets/index.css";
import { Nav, NavItem, TabContent, TabPane, Row, Col  } from "reactstrap";
import { NavLink } from "react-router-dom";
import classnames from "classnames";

import AcceptUld from '../components/warehouseImport/AcceptUld';
import NilFlights from '../components/warehouseImport/NilFlights';
import WarehouseBreakdown from '../components/warehouseImport/WarehouseBreakdown';

const WarehouseImport = ({
    
}) => {

    const {
        user,
        eosWebApiUrl,
        eosWebAuthCode,
        s_unit,
        actions
    } = useContext(AppContext);

    const { createSuccessNotification } = actions;

    const [activeFirstTab, setActiveFirstTab] = useState('1');
    
    const toggleTab = (tab) => {
        if (activeFirstTab !== tab) {
            setActiveFirstTab(tab);
        }   
    }

    return (
        <Row className='px-3 py-3'>
            <Col md='12' lg='12'>
                <Row>
                    <h1 className='pl-3' style={{position: 'relative', top: '6px'}}>Warehouse Import</h1>
                </Row>

                <Row className='mt-2'>
                    <Col mg='12' lg='12'>
                        <Nav tabs className="separator-tabs ml-0 mb-2">
                            <NavItem>
                                <NavLink
                                location={{}}
                                to="#"
                                className={classnames({
                                    active: activeFirstTab === "1",
                                    "nav-link": true
                                })}
                                onClick={() => {
                                    toggleTab("1");
                                }}
                                >
                                Accept ULD
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                location={{}}
                                to="#"
                                className={classnames({
                                    active: activeFirstTab === "2",
                                    "nav-link": true
                                })}
                                onClick={() => {
                                    toggleTab("2");
                                }}
                                >
                                Breakdown
                                </NavLink>
                            </NavItem>
                            {/* <NavItem>
                                <NavLink
                                location={{}}
                                to="#"
                                className={classnames({
                                    active: activeFirstTab === "3",
                                    "nav-link": true
                                })}
                                onClick={() => {
                                    toggleTab("3");
                                }}
                                >
                                Nil Flights
                                </NavLink>
                            </NavItem> */}
                        </Nav>
                    </Col>
                </Row>

                <TabContent activeTab={activeFirstTab} className='mt-2'>
                    <TabPane tabId="1">
                        <AcceptUld 
                            eosWebApiUrl={eosWebApiUrl}
                            eosWebAuthCode={eosWebAuthCode}
                            user={user}
                            s_unit={s_unit}
                            createSuccessNotification={createSuccessNotification}                            
                        />
                    </TabPane>
                    <TabPane tabId="2">
                        <WarehouseBreakdown 
                            eosWebApiUrl={eosWebApiUrl}
                            eosWebAuthCode={eosWebAuthCode}
                            user={user}
                            s_unit={s_unit}
                            createSuccessNotification={createSuccessNotification}                            
                        />
                    </TabPane>
                    {/* <TabPane tabId="3">
                        <NilFlights 
                            eosWebApiUrl={eosWebApiUrl}
                            eosWebAuthCode={eosWebAuthCode}
                            user={user}
                            s_unit={s_unit}
                            createSuccessNotification={createSuccessNotification}                            
                        />
                    </TabPane> */}
                </TabContent>
            </Col>
        </Row>
    );
}

export default withAuthenticationRequired(WarehouseImport);