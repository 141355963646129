import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, Button } from 'reactstrap';
import SelectAwbSlideShow from './SelectAwbSlideShow';
import { Field } from 'formik';
import ReactTable from '../custom/ReactTable';
import tableMapping from './tableMappings';
import Charges from './Charges';
import FfmFwbFhl from './FfmFwbFhl';
import Locations from './Locations';

export default ({
    myImportAwbs,
    selectedAwb,
    setSelectedAwb,
    manualMode,
    setManualMode,
    values, 
    setFieldValues,
    payments,
    ffms,
    fhls,
    fwbs,
    locations,
    checkSelectedFfm,
    handleSelectFfm,
    charges,
    calcPaymentDue,
    formatCost,
    handleAddPaymentCharge,
    calcTotalCharges,
    storageStartDate,
    setModalCreateAwb
}) => {

    return (
        <Row id={'navTabs'}> 
            <Col md={6}>
                <Card>
                    <CardBody>
                        <Row>
                            <Col md={4}>
                                <h4 className={'mt-2'}>AWB Detail ({myImportAwbs.length})</h4>
                            </Col>
                            <Col md={4}>
                                <SelectAwbSlideShow 
                                    items={myImportAwbs}
                                    setSelectedAwb={setSelectedAwb}
                                />
                            </Col>
                            <Col md={4} className={'text-right'}>
                                <Button onClick={() => setModalCreateAwb(true)}>Create AWB</Button>
                            </Col>
                        </Row>
                        <Row className={'mt-2'}>
                            <Col md={6} className={'mt-2'}>
                                <h6 className={'d-inline mr-2'}>Pieces:</h6>
                                {
                                    manualMode ?
                                        <Field type='number' name={'i_pieces'} className={'form-control d-inline mr-2'} /> :
                                        <h6 className={'d-inline'}>{values.i_pieces}</h6>
                                }
                            </Col>
                            <Col md={6} className={'mt-2'}>
                                <h6 className={'d-inline mr-2'}>Weight:</h6>
                                {
                                    manualMode ?
                                        <Field type='number' name={'f_weight'} className={'form-control d-inline mr-2'} /> :
                                        <h6 className={'d-inline'}>{values.f_weight}</h6>
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6} className={'mt-2'}>
                                <h6 className={'d-inline mr-2'}>Flight Date:</h6>
                                {
                                    manualMode ?
                                        <Field type='date' name={'d_last_arrival_date'} className={'form-control d-inline mr-2'} /> :
                                        <h6 className={'d-inline'}>{values.d_last_arrival_date}</h6>
                                }
                            </Col>
                            <Col md={6} className={'mt-2'}>
                                <h6 className={'d-inline mr-2'}>Storage Start Date:</h6>
                                <h6 className={'d-inline'}>{storageStartDate}</h6>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} className={'mt-2'}>
                                <h6 className={'d-inline mr-2'}>HAWB:</h6>
                                <Field name={'s_hawb'} type={'text'} className={'form-control d-inline'} style={{ width: '200px' }} />
                            </Col>
                        </Row>
                        <Row className={'mt-3'}>
                            <Col md={12} className={'mb-1'}>
                                <h4 className={'float-left'}>Subtotals</h4>
                                <div className={'float-right'}>
                                    <Button onClick={() => handleAddPaymentCharge(false)}>Accept Payment</Button>
                                    <Button onClick={() => handleAddPaymentCharge(true)} color={'warning'} className={'ml-2'}>Add Charge</Button>
                                </div>
                            </Col>
                            <Col md={12}>
                                <ReactTable 
                                    data={payments}
                                    mapping={tableMapping.payments}
                                    locked={true}
                                    numRows={5}
                                    index={true}
                                />
                            </Col>
                        </Row>
                        {/* Charges Summary */}
                        <Charges 
                            charges={charges}
                            calcPaymentDue={calcPaymentDue}
                            formatCost={formatCost}
                            calcTotalCharges={calcTotalCharges}
                        />
                    </CardBody>
                </Card>
            </Col>
            <Col md={6}>
               <FfmFwbFhl 
                    ffms={ffms}
                    handleSelectFfm={handleSelectFfm}
                    fwbs={fwbs}
                    fhls={fhls}
               />
               <Locations 
                    locations={locations}
               />
            </Col>
        </Row>
    );
}