import React, {Fragment} from 'react';

import {
    Modal
  } from "reactstrap";


const ModalCreateAwb = ({
    open, 
    handleModalCreateAwb, 
    handleCreateAwbType,
    enableCreateAwb,
    handleCreateAwb,
    handleInput,
    createAwbNum,
    createAwbType
}) => {

    const maxLengthCheck = (object) => {
        if (object.target.value.length > object.target.maxLength) {
         object.target.value = object.target.value.slice(0, object.target.maxLength)
          }
    }

    return (
        <Fragment>
            <Modal isOpen={open} toggle={() => handleModalCreateAwb('modalCreateAwbOpen')}>
            <div className="modal-content" style={{width: '600px'}}>
                <div className="modal-header mx-auto">
                    <h5 className="modal-title" id="exampleModalLabel">Create AWB</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body mx-auto">
                    <div className="row">
                        <div className="col-12">
                            <input type="number" maxLength='11' onInput={maxLengthCheck}  id='createAwbNum' value={createAwbNum} onChange={(e) => handleInput(e)} className="form-control text-center" style={{maxWidth: '200px'}}/>
                            <div className={`btn-group aircraft-type ml-2 mt-3`} data-toggle="buttons">
                                <label className="btn btn-info mr-2" style={{backgroundColor: `${createAwbType === 'EXPORT' ? '#118496' : '#19A2B4'}`}}>
                                    <input type="radio" id='createAwbType' value="EXPORT" onClick={(e) => handleCreateAwbType(e)} style={{display: 'none'}} /> EXPORT
                                </label>
                                <label className="btn btn-info" style={{backgroundColor: `${createAwbType === 'IMPORT' ? '#118496' : '#19A2B4'}`}}>
                                    <input type="radio" id='createAwbType' value="IMPORT" onClick={(e) => handleCreateAwbType(e)} style={{display: 'none'}} /> IMPORT
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" onClick={() => handleModalCreateAwb('modalCreateAwbOpen')}>Close</button>
                    <button className="btn btn-primary ml-2 py-0" style={{height: '42px'}} type="button" disabled={!enableCreateAwb()} onClick={() => handleCreateAwb()}>Submit</button>
                </div>
            </div>
            </Modal>
        </Fragment>
    );
}

export default ModalCreateAwb;