import React from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import moment from 'moment';

export default ({
    awb,
    selectAwb
}) => {

    return (
        <Card className={'mb-2'} style={{ backgroundColor: '#61B996' }} onClick={() => selectAwb(awb)}>
            <CardBody>
                <Row>
                    <Col md={12}>
                        <div className={'float-left'}>
                            <h6>{awb.s_mawb}</h6>
                        </div>
                        {/* <div className={'float-right'}>
                            <h6 className={'float-right'}>{awb.locations.length}</h6>
                        </div> */}
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
}