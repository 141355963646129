import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, ButtonGroup, Label, Input } from 'reactstrap';
import Webcam from "react-webcam";
import CropPhoto from './CropPhoto';
import { Wizard, Steps, Step } from 'react-albus';
import TopNavigation from '../custom/wizard-hooks/TopNavigation';
import SelectFileType from './SelectFileType';
import FileBase64 from 'react-file-base64';

export default ({
    type,
    modal,
    setModal,
    fileTypes,
    addToFiles,
    file,
    setFile,
    updateFile,
    values,
    setFieldValue,
    selectedAwb,
    checkIdentification,
    saveIdentificationInformation
}) => {

    // ------ Upload Files ------

    const [fileKey, setFileKey] = useState(0);
    const [fileType, setFileType] = useState('');

    // ------ Webcam ------

    //Other Button:
    const [addOtherType, setAddOtherType] = useState(false);

    useEffect(() => {
        if (addOtherType) {
            updateFile('fileType', '');
        }
    }, [addOtherType]);

    // Crop:
    const [upImg, setUpImg] = useState();
    const imgRef = useRef(null);

    const webcamRef = React.useRef(null); 

    const capture = (next) => {
        const imageSrc = webcamRef.current.getScreenshot();
    
        let img = document.createElement('img');
        img.setAttribute('src', imageSrc);

        imgRef.current = img;
        setUpImg(imageSrc);

        next();
    }

    const retakePhoto = (previous) => {
        previous();
    }

    // Select Camera:

    const [deviceId, setDeviceId] = React.useState({});
    const [devices, setDevices] = React.useState([]);
    
    const handleDevices = React.useCallback(
        mediaDevices =>
        setDevices(mediaDevices.filter(({ kind }) => kind === "videoinput")),
        [setDevices]
    );

    useEffect(() => {
        if (devices.length > 0) {
            setDeviceId(devices[0].deviceId);
        }
    }, [devices]);

    useEffect(() => {
        navigator.mediaDevices.enumerateDevices().then(handleDevices);
    },[handleDevices]);

    const toggle = () => setModal(!modal);

    useEffect(() => {
        setFileKey(fileKey + 1);
        setFile({});
        setFileType('');
    }, [modal]);

    const topNavClick = (stepItem, push, step) => {
        if (step.id === '1') {
            if (!enableSelectFileNext()) {
                return;
            }
        } else if (step.id === '2') {
            if (!file.base64) {
                if (stepItem.id !== '1') {
                    return;
                }
            }
        }
        push(stepItem.id);
    };

    const handleAddFile = () => {
        if (type === 'UPLOAD') {
            file.fileType = fileType;
        } 
        addToFiles(file, type === 'UPLOAD');
        setModal(false);
        setFile({});
    }

    const enableSelectFileNext = () => {
        if (type === 'WEBCAM') {
            if (file.fileType === 'IDENTIFICATION') {
                return checkIdentification();
            } 
            return file.fileType && file.fileType.length > 0;
        } else {
            if (fileType === 'IDENTIFICATION') {
                return checkIdentification();
            } 
            return fileType && fileType.length > 0;
        }
    }

    if (type === 'WEBCAM') {
        return (
            <div>
                <Modal isOpen={modal} toggle={toggle} style={{ maxWidth: '1300px', width: '100%' }}>
                    <ModalHeader>Scan File</ModalHeader>
                    <ModalBody>
                        
                        <div className="wizard wizard-default">
                            <Wizard>
                                <TopNavigation
                                    className="justify-content-center mb-4"
                                    disableNav={false}
                                    topNavClick={topNavClick}
                                />
    
                                <Steps>
    
                                    <Step id={'1'} name={'Select File Type'} render={({ next }) => (
                                        <SelectFileType 
                                            file={file}
                                            updateFile={updateFile}
                                            fileTypes={fileTypes}
                                            next={next}
                                            uploadFile={false}
                                            values={values}
                                            setFieldValue={setFieldValue}
                                            selectedAwb={selectedAwb}
                                            checkIdentification={checkIdentification}
                                            saveIdentificationInformation={saveIdentificationInformation}
                                            fileType={fileType}
                                            setFileType={setFileType}
                                        />
                                    )}></Step>
    
                                    <Step id={'2'} name={'Take Photo'} render={({ next }) => (
                                        <Row>
                                            <Col md={12} className='text-center'>
                                                <div className='mb-2'>
                                                    {devices.map((device, key) => (
                                                        <Button
                                                            key={device.deviceId}
                                                            onClick={() => setDeviceId(device.deviceId)}
                                                            active={deviceId === device.deviceId}
                                                            className='mr-2'
                                                        >
                                                            {device.label || `Device ${key + 1}`}
                                                        </Button>
                                                    ))}
                                                </div>
                                            </Col>
                                            <Col md={12} className='text-center'>
                                                <Webcam
                                                    audio={false}
                                                    ref={webcamRef}
                                                    screenshotFormat="image/jpeg"
                                                    // videoConstraints={{ deviceId }}
                                                    // width={720}
                                                    // height={480}
                                                    videoConstraints={{ deviceId, width: 2592, height: 1944 }}
                                                    width={1280}
                                                    height={720}
                                                />
                                            </Col>
                                            <Col md={12} className='text-center'>
                                                <Button onClick={() => capture(next)} className='text-center'>Capture photo</Button>
                                            </Col>
                                            <Col md={12} className='text-center'>
                                                <h4 className='fas fa-times mt-1' style={{ color: 'red' }}>Photo Not Saved</h4>
                                            </Col>
                                        </Row>
                                    )}>
                                    </Step>
    
                                    <Step id={'3'} name={'Crop Photo'} render={({ next, previous }) => (
                                        <CropPhoto 
                                            upImg={upImg}
                                            setUpImg={setUpImg}
                                            imgRef={imgRef}
                                            retakePhoto={retakePhoto}
                                            next={next}
                                            previous={previous}
                                            updateFile={updateFile}
                                        />
                                    )}></Step>
    
                                    <Step id={'4'} name={'Save Photo'} render={({ next, previous }) => (
                                        <Row className={"text-center"}>
                                            <Col md={12}>
                                                <img src={file.base64} style={{ maxWidth: 1000, height: 'auto' }} />
                                            </Col>
                                            <Col md={12} className={'mt-2'}>
                                                <Button className={'d-inline mr-2'} onClick={() => previous()} color={'info'}>Crop Again</Button>
                                                <Button className={'d-inline'} onClick={() => handleAddFile()}>Save</Button>
                                            </Col>
                                        </Row>
                                    )} />
                                </Steps>
                            </Wizard>
                        </div>
                        
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={toggle}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    } else {
        return (
            <div>
                <Modal isOpen={modal} toggle={toggle} style={{ maxWidth: '1300px', width: '100%' }}>
                    <ModalHeader>Upload File</ModalHeader>
                    <ModalBody>
                        
                        <div className="wizard wizard-default">
                            <Wizard>
                                <TopNavigation
                                    className="justify-content-center mb-4"
                                    disableNav={false}
                                    topNavClick={topNavClick}
                                />
    
                                <Steps>
    
                                    <Step id={'1'} name={'Select File Type'} render={({ next }) => (
                                        <SelectFileType 
                                            file={file}
                                            updateFile={updateFile}
                                            fileTypes={fileTypes}
                                            next={next}
                                            uploadFile={true}
                                            values={values}
                                            setFieldValue={setFieldValue}
                                            selectedAwb={selectedAwb}
                                            checkIdentification={checkIdentification}
                                            saveIdentificationInformation={saveIdentificationInformation}
                                            fileType={fileType}
                                            setFileType={setFileType}
                                        />
                                    )}></Step>
    
                                    <Step id={'2'} name={'Upload File'} render={({ next }) => (
                                        <Row>
                                            <Col md={12} className='text-center'>
                                                <FileBase64 
                                                    onDone={setFile}
                                                    key={fileKey}
                                                />
                                                <Button 
                                                    onClick={() => handleAddFile()}
                                                    disabled={!file.base64}
                                                >
                                                    Save
                                                </Button>
                                            </Col>
                                        </Row>
                                    )}>
                                    </Step>
    
                                </Steps>
                            </Wizard>
                        </div>
                        
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={toggle}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}