import React, {Fragment} from 'react';

import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Input,
    Label,
  } from "reactstrap";
import moment from 'moment';
import { throwStatement } from '@babel/types';
import TsaCheckWizard from './TsaCheckWizard';
import FoundIac from './FoundIac';
import FoundCcsf from './FoundCcsf';

const TsaCheckModal = ({
    open, 
    handleModal,
    b_screened,
    handleBscreened,
    handleScreeningType,
    screeningType,
    s_iac,
    checkIac,
    foundIac,
    handleIacNum,
    s_ccsf,
    checkCcsf,
    foundCcsf,
    handleCcsfNum,
    s_non_iac,
    handleNonIac,
    enableSavingTsaCheck,
    handleSaveTsaCheck,
    tsaSaveInfo
}) => {

    const enableSavingIdType1 = () => {

    }

    // const validIacAlsoCcsf = () => {
    //     if(screeningType === 'iacAlsoCcsf') {
    //         if(foundIac && foundCcsf) {
    //             if(foundIac.approval_number === foundCcsf.iac_number) {
    //                 return true;
    //             } else {
    //                 return false;
    //             }
    //         } else {
    //             return false;
    //         }
    //     } else {
    //         return true;
    //     }
    // }

    const validIacAlsoCcsf = () => {
        if(screeningType === 'iacAlsoCcsf' && s_iac !== null && s_iac.length === 9 && s_ccsf !== null && s_ccsf.length === 20) {
            if(foundIac && foundCcsf) {
                if(foundIac.approval_number === foundCcsf.iac_number) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        } else {
            return true;
        }
    }

    return (
        <Fragment>
            <Modal isOpen={open} toggle={handleModal} size="lg">
                <div className="modal-content">
                    <div className="modal-header mx-auto">
                        <h5 className="modal-title" id="exampleModalLabel">TSA Check</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body mx-auto">
                        <div className="row d-flex justify-content-center">
                            <TsaCheckWizard 
                                b_screened={b_screened}
                                handleBscreened={handleBscreened}
                                handleScreeningType={handleScreeningType}
                                screeningType={screeningType}

                                s_iac={s_iac} 
                                checkIac={checkIac}
                                handleIacNum={handleIacNum}

                                s_ccsf={s_ccsf}
                                checkCcsf={checkCcsf}
                                handleCcsfNum={handleCcsfNum}

                                s_non_iac={s_non_iac}
                                handleNonIac={handleNonIac}
                            />
                        </div>
                        {
                            foundIac && <FoundIac foundIac={foundIac} validIacAlsoCcsf={validIacAlsoCcsf}/>
                        }
                        {
                            foundCcsf && <FoundCcsf foundCcsf={foundCcsf} validIacAlsoCcsf={validIacAlsoCcsf}/>
                        }
                    </div>
                    <div className="modal-footer">
                        <h6 style={{position: 'relative', right: '120px', fontWeight: 'bolder', color: `${tsaSaveInfo.saved ? '#013220' : 'red'}`}}>
                            {tsaSaveInfo.saved ? `Saved at ${tsaSaveInfo.time}` : 'Not Saved'}
                        </h6>
                        <button type="button" className="btn btn-secondary" onClick={handleModal}>Close</button>
                        <button type="button" className="btn btn-primary" disabled={!enableSavingTsaCheck()} onClick={() => handleSaveTsaCheck()}>Save changes</button>
                    </div>
                </div>
            </Modal>
        </Fragment>
    );
}

export default TsaCheckModal;