import React, {useEffect, useRef, useState} from 'react';
import moment from 'moment';
import {
    CustomInput,
  } from "reactstrap";
import Switch from "rc-switch";
import "rc-switch/assets/index.css";

import {Row, Col, Table, Card, CardBody} from 'reactstrap';


const DriversInfo = ({
    s_company,
    s_company_driver_name,
    s_sms,
    s_trucking_email
}) => {
    return (
        <Row className='py-2' style={{fontSize: '16px'}}>
            <Col md={12}>
                <Card className='custom-opacity-card' style={{borderRadius: '0.75rem'}}>
                    <CardBody className='custom-card-transparent pt-3 pb-0' style={{backgroundColor: 'rgba(255,255,255,0.2)'}}>
                        <h4>Driver's Info:</h4>
                        <Table>
                            <thead>
                                <tr style={{backgroundColor: '#F3F3F3'}}>
                                    <th scope="col">Trucking Company</th>
                                    <th scope="col">Driver's Name</th>
                                    <th scope="col">Cell Number</th>
                                    <th scope="col">Email</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{s_company}</td>
                                    <td>{s_company_driver_name}</td>
                                    <td>{s_sms}</td>
                                    <td>{s_trucking_email}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
}

export default DriversInfo;