import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form } from 'reactstrap';
import CompanyCardDetails from './CompanyCardDetails';

export default ({
    modal,
    setModal,
    selectedCompany,
    selectedType,
    awbs,
    isMyAssignment,
    myAssignments,
    takeOwnership,
    removeOwnership,
    removeCompanyOwnership,
    markLeftEarly,
    checkIsWaiting
}) => {

    const [isWaiting, setIsWaiting] = useState(false);

    useEffect(() => {
        setIsWaiting(checkIsWaiting(selectedCompany, awbs, selectedType));
    }, [selectedCompany, awbs, selectedType]);

    const toggle = () => setModal(!modal);

    const resolveStatus = (selectedCompany) => {
        const status = isWaiting ?
            selectedCompany.s_status :
            `Being Processed by ${selectedCompany.s_counter_ownership_agent}`;
        return status;
    }

    return (
        <div>
            <Modal isOpen={modal} toggle={toggle} size={'lg'}>
                <ModalHeader>
                    {selectedCompany.s_trucking_company} ({selectedCompany.s_trucking_driver})
                    <h6 className='mb-0 mt-1'>Status: {resolveStatus(selectedCompany)}</h6>
                </ModalHeader>
                <ModalBody style={{ maxHeight: '500px', overflowY: 'scroll' }}>
                    {

                        awbs.map((awb, i) => awb.s_transaction_id === selectedCompany.s_transaction_id &&
                            <CompanyCardDetails 
                                awb={awb} 
                                isMyAssignment={isMyAssignment} 
                                removeOwnership={removeOwnership} 
                                key={i}
                                selectedType={selectedType}
                            /> 
                        )
                    }
                </ModalBody>
                <ModalFooter>
                    {
                        selectedCompany.s_status === 'WAITING' && 
                        <Button color='danger' onClick={() => markLeftEarly(selectedCompany)}>Left Early</Button>
                    }
                    {
                        isMyAssignment(selectedCompany) && 
                        <Button color='warning' onClick={() => removeCompanyOwnership()}>Remove Ownership</Button>
                    }
                    {
                        myAssignments.length === 0 && isWaiting && 
                        <Button color="primary" onClick={() => takeOwnership(selectedCompany, selectedType)}>Take Ownership</Button>
                    }
                    <Button color="secondary" onClick={toggle}>Close</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}