import React from "react";
import { Card, CardBody } from "reactstrap";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const GradientWithRadialProgressCard = ({
    icon, 
    title, 
    percent, 
    progressText, 
    onClick
}) => {
  return (
    <Card className="progress-banner" style={{height: '130px', borderRadius: '0.5rem'}} onClick={onClick}>
      <CardBody className="justify-content-between d-flex flex-row align-items-center py-0">
        <div>
            <i
                className={`${icon} mr-2 align-text-bottom d-inline-block`}
                style={{ fontSize: 60 }}
            />
            <div>
                <h4>{title}</h4>
            </div>
        </div>
        <div style={{ width: 120, height: 120 }}>
            <CircularProgressbar
                strokeWidth={4}
                value={percent}
                text={progressText}
            />
        </div>
      </CardBody>
    </Card>
  );
};
export default GradientWithRadialProgressCard;
