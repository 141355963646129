import React, {Fragment, useState, useEffect} from 'react';

import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Input,
    Label,
    Row,
    Form,
    Col,
    Table
  } from "reactstrap";
import moment from 'moment';

import {tableArray} from './tableArray';

const ModalEditData = ({
    open,
    handleModal,
    rackItem,
    i_pieces,
    set_i_pieces,
    s_tower,
    set_s_tower,
    s_level,
    set_s_level,
    s_position,
    set_s_position,
    updateRackLocation,
    rackItems,
    specialLocation,
    setSpecialLocation,
    specialLocations,
    enableSpecialLocation,
    setEnableSpecialLocation,
    s_notes,
    set_s_notes,
    updateRackStatus
}) => {

    const resolvePosition = (position) => parseInt(position.toString().substr(1, 2));

    const levels = [1, 2, 3];

    // const occupied = rackItems.map(i => i.s_location);

    const currentPosition = `${s_tower}${s_level}${parseInt(s_position) < 10 ? `0${s_position}` : s_position}`;

    const allOccupied = rackItems.map(i => i.s_location);

    const occupied = allOccupied.filter(o => o !== currentPosition);

    const notOccupied = (useTower, position) => {
        const checkPosition = `${useTower}${position}`;
        return occupied.indexOf(checkPosition) === -1;
    }

    const resolvePositionMapping = () => {

        console.log(s_tower);

        const useTower = s_tower !== null ? s_tower : tableArray[0].letter;
        
        set_s_tower(useTower);

        console.log(useTower);

        const selection = enableSpecialLocation ? tableArray[0] : tableArray.filter(a => a.letter === useTower)[0];

        console.log(selection);

        const blanks = selection && selection.blanks && selection.blanks !== null ? selection.blanks : [];

        let positions = [];

        const useLevel = enableSpecialLocation ? 1 : s_level !== null ? s_level : 1;

        set_s_level(useLevel);
        
        for (let i = parseInt(selection.start); i <= parseInt(selection.end); i++ ) {
            positions.push(i + (100 * useLevel) - 100);
        }

        console.log(positions);

        positions = positions.filter(p => blanks.indexOf(p) === -1 && notOccupied(useTower, p));

        console.log(positions);

        positions = positions.map(p => resolvePosition(p));

        console.log(positions);

        return positions;

    }


    return (
        <Fragment>
            <Modal isOpen={open} toggle={() => handleModal(!open)} className='mx-auto'>
                <div className="modal-content px-5" style={{width: '900px', marginLeft: '-200px'}}>
                    {
                        rackItem && rackItem !== null &&
                        <div className="modal-body mx-auto">
                            <div className='text-center'>
                                <h1>Edit Data for {rackItem.s_mawb} {rackItem.s_hawb !== null && rackItem.s_hawb.length > 0 && `/ ${rackItem.s_hawb}`}</h1>
                            </div>
                            <div>
                                <Row style={{fontSize: '18px'}}>
                                    <Table>
                                        <thead></thead>
                                        <tbody>
                                            <tr>
                                                <th>Pieces:</th>
                                                <th>Tower:</th>
                                                <th>Level:</th>
                                                <th>Position:</th>
                                                <th>
                                                    <input type='checkbox' className='mr-2' checked={enableSpecialLocation} onClick={(e) => setEnableSpecialLocation(!enableSpecialLocation)}></input>
                                                    Special Location
                                                </th>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <input type='number' onChange={(e) => set_i_pieces(e.target.value)} value={i_pieces} />
                                                </td>
                                                <td>
                                                    <select value={s_tower} onChange={(e) => set_s_tower(e.target.value)} disabled={enableSpecialLocation}>
                                                        {
                                                            tableArray.map((t, i) =>
                                                                <option key={i} >{t.letter}</option>
                                                            )
                                                        }
                                                    </select>
                                                </td>
                                                <td>
                                                    <select value={s_level} onChange={(e) => set_s_level(e.target.value)} disabled={enableSpecialLocation}>
                                                        {
                                                            levels.map((l, i) =>
                                                                <option key={i}>{l}</option>
                                                            )
                                                        }
                                                    </select>
                                                </td>
                                                <td>
                                                    <select value={s_position} onChange={(e) => set_s_position(e.target.value)} disabled={enableSpecialLocation}>
                                                        {
                                                            resolvePositionMapping().map((p, i) =>
                                                                <option key={i}>{p}</option>
                                                            )
                                                        }
                                                    </select>
                                                </td>
                                                <td>
                                                    <select value={specialLocation} onChange={(e) => setSpecialLocation(e.target.value)} disabled={!enableSpecialLocation}>
                                                        {
                                                            specialLocations.map((l, i) =>
                                                                <option key={i}>{l}</option>
                                                            )
                                                        }
                                                    </select>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th colSpan={5}>Previous Notes</th>
                                            </tr>
                                            <tr>
                                                <td colSpan={5}>
                                                    {
                                                        rackItem.s_notes && rackItem.s_notes.split('.').map((n, i) => 
                                                            <p key={i}>{n}</p>
                                                        )
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <th colSpan={5}>Add Notes</th>
                                            </tr>
                                            <tr>
                                                <td colSpan={5}>
                                                    <input value={s_notes} onChange={(e) => set_s_notes(e.target.value)} type='text' style={{width: '100%'}} />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Row>
                                <Row>
                                    <Col md={12} lg={12} className='text-center'>
                                        <Button className='mr-2' color='primary' onClick={() => updateRackLocation()}>Save</Button>
                                        <Button className='mr-2' color='danger'  onClick={() => updateRackStatus('DELETED')}>Delete</Button>
                                        <Button color='secondary' onClick={() => handleModal(false)}>Close</Button>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    }
                </div>
            </Modal>
        </Fragment>
    );
}

export default ModalEditData;