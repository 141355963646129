import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from '../components/context/index';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Row, Col, Button, Card, CardBody, Nav, NavItem, TabContent, TabPane } from 'reactstrap';
import classnames from "classnames";
import axios from 'axios';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import ModalManageContainer from '../components/inboundTransfers/ModalManageContainer';
import ModalUpdateContainer from '../components/inboundTransfers/ModalUpdateContainer';
import ModalManageSku from '../components/inboundTransfers/ModalManageSku';
import ModalAddInbTransfer from '../components/inboundTransfers/ModalAddInbTransfer';
import ModalUpdateTransfer from '../components/inboundTransfers/ModalUpdateTransfer';
import Main from '../components/inboundTransfers/Main';
import Container from '../components/inboundTransfers/Container';
import SKU from '../components/inboundTransfers/SKU';

const InboundTransfer = ({
    
}) => {

    const {
        user, 
        s_unit,
        metadata,
        baseApiUrl,
        actions,
        audience
    } = useContext(AppContext);

    const { addLocalValue, updateLocalValue, deleteLocalValue, getAccessTokenSilently, createSuccessNotification } = actions;

    const [activeFirstTab, setActiveFirstTab] = useState('1');
    
    // Container
    const [newContainer, setNewContainer] = useState(false);
    const [modalAddContainerOpen, setModalAddContainerOpen] = useState(false);
    const [modalUpdateContainerOpen, setModalUpdateContainerOpen] = useState(false);
    const [containerData, setContainerData] = useState([]);
    const [selectedContainer, setSelectedContainer] = useState({});
    
    // Sku
    const [skuData, setSkuData] = useState([]);
    const [selectedSku, setSelectedSku] = useState(null);
    const [modalManageSku, setModalManageSku] = useState(false);
    const [newSku, setNewSku] = useState(false);
    const [modalSkuRedirect, setModalSkuRedirect] = useState(false);

    //Inbound Transfer
    const [containerInboundData, setContainerInboundData] = useState([]);
    const [selectedTransfer, setSelectedTransfer] = useState({});
    const [modalAddInbTransfer, setModalAddInbTransfer] = useState(false);
    const [modalUpdateTransfer, setModalUpdateTransfer] = useState(false);

    const toggleTab = (tab) => {
        if (activeFirstTab !== tab) {
            setActiveFirstTab(tab);
        }   
    }

    useEffect(() => {
        const getInboundTransferData = async () => {
            const data = {
                s_unit,
                s_company_guid: metadata.s_company_guid
            }
            
            let token = null;

            try {
                token = await actions.getAccessTokenSilently({ audience });
            } catch (err) {
                alert(err);
            }
    
            try {
                const response = await axios.post(`${baseApiUrl}/getInboundTransferData`, {
                    data
                }, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
    
                if (response.status === 200) {
                    const { containers, sku } = response.data;
                    console.log(containers);
                    console.log(sku);
                    setContainerData(containers);
                    setSkuData(sku);
                }
            } catch (err) {
                alert(err);
            }
        }
        if (s_unit && metadata.s_company_guid) {
            getInboundTransferData();
        }
    }, [s_unit, metadata]);

    const handleAddContainer = () => {
        setNewContainer(true);
        setModalAddContainerOpen(true);
    }

    const handleUpateContainer = (container) => {
        setSelectedContainer(container);
        setNewContainer(false);
        setModalAddContainerOpen(true);
    }

    const addContainer = async (values) => {
        const now = moment().local().format('MM/DD/YYYY HH:mm');
        let endpoint; 

        values.t_modified = now;
        values.s_modified_by = user.name;

        if (newContainer) {
            values.s_guid = uuidv4();
            values.t_created = now;
            values.s_created_by = user.name;    
            values.s_unit = s_unit;
            values.s_company_guid = metadata.s_company_guid;    
            endpoint = 'addContainer';
        } else {
            values.id = selectedContainer.id
            endpoint = 'updateContainer';
        }

        const data = values;
        const token = await actions.getAccessTokenSilently({ audience });

        try {
            const response = await axios.post(`${baseApiUrl}/${endpoint}`, {
                data
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
    
            if (response.status === 200) {
                actions.createSuccessNotification('Success');
                setModalAddContainerOpen(false);
                if (newContainer) {
                    addLocalValue(containerData, setContainerData, response.data);
                } else {
                    updateLocalValue(containerData, setContainerData, selectedContainer.id, values);
                }
            }
        } catch (err) {
            alert(err);
        }
    }

    const addInboundTransfer = async (values) => {
        const now = moment().local().format('MM/DD/YYYY HH:mm');
        const data = values;
        data.s_unit = s_unit;
        data.s_company_guid = metadata.s_company_guid;
        data.t_created = now;
        data.s_created_by = user.name;
        data.s_modified_by = user.name;
        data.t_modified = now;
        data.s_guid = uuidv4();
        data.s_container_guid = selectedContainer.s_guid;
        data.s_sku_guid = selectedSku.s_guid;

        const token = await actions.getAccessTokenSilently({ audience });

        try {   
            const response = await axios.post(`${baseApiUrl}/addInboundTransfer`, {
                data
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            if (response.status === 200) {
                actions.createSuccessNotification('Inbound Transfer Added');
                setModalAddInbTransfer(false);
                console.log(response.data);
                addLocalValue(containerInboundData, setContainerInboundData, response.data);
            }
        } catch (err) {
            alert(err);
        }
    }

    useEffect(() => {
        if (selectedContainer.id) {
            const getContainerInboundData = async () => {
                const token = await actions.getAccessTokenSilently({ audience });
                try {   
                    const response = await axios.get(`${baseApiUrl}/getContainerInboundData/${selectedContainer.s_guid}`, {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });

                    if (response.status === 200) {
                        setContainerInboundData(response.data);
                        console.log(response.data);
                    }
                } catch (err) {
                    alert(err);
                }
            }
            getContainerInboundData();
        }
    }, [selectedContainer]);

    const handleUpdateTransfer = (transfer) => {
        setSelectedTransfer(transfer);
        setModalUpdateTransfer(true);
    }

    const updateInboundTransfer = async (values) => {
        const data = values;
        const now = moment().local().format('MM/DD/YYYY HH:mm');

        data.id = selectedTransfer.id;
        data.t_modified = now;
        data.s_modified_by = user.name;

        const token = await actions.getAccessTokenSilently({ audience });
        try {
            const response = await axios.put(`${baseApiUrl}/updateInboundTransfer`, {
                data
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            if (response.status === 200) {
                updateLocalValue(containerInboundData, setContainerInboundData, selectedTransfer.id, values);
                actions.createSuccessNotification('Updated');
                setModalUpdateTransfer(false);
            }            
        } catch (err) {
            alert(err);
        }
    }

    const deleteTransfer = async (id) => {
        const token = await actions.getAccessTokenSilently({ audience });
        try {
            const response = await axios.delete(`${baseApiUrl}/deleteTransfer/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            if (response.status === 200) {
                actions.createSuccessNotification('Deleted');
                setModalUpdateTransfer(false);
                deleteLocalValue(containerInboundData, setContainerInboundData, id);
            }
        } catch (err) {
            alert(err);
        }
    }

    const updateContainer = async (values) => {
        const data = values;
        data.id = selectedContainer.id;

        const token = await getAccessTokenSilently({ audience });
        try {
            const response = await axios.put(`${baseApiUrl}/updateContainer`, {
                data
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            if (response.status === 200) {
                updateLocalValue(containerData, setContainerData, selectedContainer.id, values);
                createSuccessNotification('Container updated');
                setModalUpdateContainerOpen(false);
            }
        } catch (err) {
            alert(err);
        }
    }

    const deleteContainer = async () => {
        const { id, s_guid } = selectedContainer;
        const data = {
            id,
            s_guid
        }

        const token = await getAccessTokenSilently({ audience });
        try {
            const response = await axios.post(`${baseApiUrl}/deleteContainer`, {
                data
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            if (response.status === 200) {  
                createSuccessNotification('Container deleted');
                setModalUpdateContainerOpen(false);

                //delete container
                deleteLocalValue(containerData, setContainerData, selectedContainer.id);

                //delete all inbound transfers in the container
                const copy = containerInboundData.filter(d => d.s_container_guid !== selectedContainer.s_guid);
                setContainerInboundData(copy);

            }
        } catch (err) {
            alert(err);
        }
    }

    const handleCreateUpdateSku = (type, redirect=false) => {
        setNewSku(type);
        setModalAddInbTransfer(false);
        setModalManageSku(true);
        setModalSkuRedirect(redirect);
    }

    const createUpdateSku = async (values) => {
        const data = values;
        let endpoint;

        if (newSku) {
            data.s_guid = uuidv4();
            data.s_unit = s_unit;
            data.s_company_guid = metadata.s_company_guid;
            data.s_created_by = user.name;
            data.s_modified_by = user.name;
            endpoint = `${baseApiUrl}/createSku`;
        } else {
            data.id = selectedSku.id;
            data.s_modified_by = user.name;
            data.t_modified = moment().local().format('MM/DD/YYYY');
            endpoint = `${baseApiUrl}/updateSku`;
        }

        const token = await getAccessTokenSilently({ audience });
        const authorization = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const successNotification = newSku ? 'SKU Created' : 'SKU Updated';   

        try {
            const response = newSku ? 
                await axios.post(endpoint, data, authorization) :
                await axios.put(endpoint, data, authorization);
            
                if (response.status === 200) {
                    createSuccessNotification(successNotification);
                    setModalManageSku(false);

                    if (newSku) {
                        addLocalValue(skuData, setSkuData, response.data)
                    } else {
                        updateLocalValue(skuData, setSkuData, selectedSku.id, data);
                    }

                    setSelectedSku(null);

                    if (modalSkuRedirect) {
                        setModalAddInbTransfer(true);
                        setModalSkuRedirect(false);
                    }
                }   
        } catch (err) {
            alert(err);
        }
    }

    const deleteSku = async (id) => {
        const token = await getAccessTokenSilently({ audience });
        try {   
            const response = await axios.delete(`${baseApiUrl}/deleteSku/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            if (response.status === 200) {
                setModalManageSku(false);
                createSuccessNotification('SKU Deleted');
                deleteLocalValue(skuData, setSkuData, id);
            }
        } catch (err) {
            alert(err);
        }
    }

    return (
        <>
            <Row className='mt-2'>
                <Col mg='12' lg='12'>
                    <Nav tabs className="separator-tabs ml-0 mb-2">
                        <NavItem>
                            <div
                                className={classnames({
                                    active: activeFirstTab === "1",
                                    "nav-link": true
                                })}
                                onClick={() => {
                                    toggleTab("1");
                                }}
                            >
                            Inbound Transfers
                            </div>
                        </NavItem>
                        <NavItem>
                            <div
                                className={classnames({
                                    active: activeFirstTab === "2",
                                    "nav-link": true
                                })}
                                onClick={() => {
                                    toggleTab("2");
                                }}
                            >
                            Container
                            </div>
                        </NavItem>
                        <NavItem>
                            <div
                                className={classnames({
                                    active: activeFirstTab === "3",
                                    "nav-link": true
                                })}
                                onClick={() => {
                                    toggleTab("3");
                                }}
                            >
                            SKU
                            </div>
                        </NavItem>
                        <NavItem>
                            <div
                                className={classnames({
                                    active: activeFirstTab === "4",
                                    "nav-link": true
                                })}
                                onClick={() => {
                                    toggleTab("4");
                                }}
                            >
                            Storage
                            </div>
                        </NavItem>
                    </Nav>
                </Col>
            </Row>

            <TabContent activeTab={activeFirstTab} className='mt-2'>
                <TabPane tabId="1">
                    <Main 
                        handleAddContainer={handleAddContainer}
                        handleUpateContainer={handleUpateContainer}
                        modalAddContainerOpen={modalAddContainerOpen}
                        setModalAddContainerOpen={setModalAddContainerOpen}
                        containerData={containerData}
                        skuData={skuData}
                        selectedContainer={selectedContainer}
                        setSelectedContainer={setSelectedContainer}
                        addContainer={addContainer}
                        modalAddInbTransfer={modalAddInbTransfer}
                        setModalAddInbTransfer={setModalAddInbTransfer}
                        selectedSku={selectedSku}
                        setSelectedSku={setSelectedSku}
                        addInboundTransfer={addInboundTransfer}
                        containerInboundData={containerInboundData}
                        modalUpdateTransfer={modalUpdateTransfer}
                        setModalUpdateTransfer={setModalUpdateTransfer}
                        selectedTransfer={selectedTransfer} 
                        handleUpdateTransfer={handleUpdateTransfer}
                        updateInboundTransfer={updateInboundTransfer}
                        deleteTransfer={deleteTransfer}
                        setModalUpdateContainerOpen={setModalUpdateContainerOpen}
                    />
                </TabPane>
                <TabPane tabId='2'>
                    <Container 
                        data={containerData}
                        setSelectedContainer={setSelectedContainer}
                        setModalAddContainerOpen={setModalAddContainerOpen}
                        setModalUpdateContainerOpen={setModalUpdateContainerOpen}
                        handleAddContainer={handleAddContainer}
                        handleUpateContainer={handleUpateContainer}
                    />
                </TabPane>
                <TabPane tabId='3'>
                    <SKU 
                        data={skuData}
                        setSelectedSku={setSelectedSku}
                        handleCreateUpdateSku={handleCreateUpdateSku}
                    />
                </TabPane>
            </TabContent>

            <ModalManageContainer 
                open={modalAddContainerOpen}
                toggle={() => setModalAddContainerOpen(!modalAddContainerOpen)}
                addContainer={addContainer}
                newContainer={newContainer}
                selectedContainer={selectedContainer}
            />
            <ModalUpdateContainer 
                modal={modalUpdateContainerOpen}
                setModal={setModalUpdateContainerOpen}
                selectedContainer={selectedContainer}
                updateContainer={updateContainer}
                deleteContainer={deleteContainer}
            />
            <ModalAddInbTransfer 
                open={modalAddInbTransfer}
                toggle={() => setModalAddInbTransfer(!modalAddInbTransfer)}
                skuData={skuData}
                selectedSku={selectedSku}
                setSelectedSku={setSelectedSku}
                handleCreateUpdateSku={handleCreateUpdateSku}
                addInboundTransfer={addInboundTransfer}
            />
            <ModalUpdateTransfer 
                open={modalUpdateTransfer}
                toggle={() => setModalUpdateTransfer(!modalUpdateTransfer)}
                selectedTransfer={selectedTransfer}
                updateInboundTransfer={updateInboundTransfer}
                deleteTransfer={deleteTransfer}
            />
            <ModalManageSku 
                modal={modalManageSku}
                setModal={setModalManageSku}
                newSku={newSku}
                selectedSku={selectedSku}
                createUpdateSku={createUpdateSku}
                deleteSku={deleteSku}
            />
        </>
    );
}

export default withAuthenticationRequired(InboundTransfer);