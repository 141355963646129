import React, { useState } from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
  Card,
  CardBody
} from 'reactstrap';

const SelectAwbSlideShow = ({
  items,
  setSelectedAwb
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setSelectedAwb(items[nextIndex]);
    setActiveIndex(nextIndex);
  }

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setSelectedAwb(items[nextIndex]);
    setActiveIndex(nextIndex);
  }

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  }

  const slides = items && items !== null && items.length > 0 && items.map((item, i) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={i}
      >
        <Card style={{borderRadius: '0.75rem', width: '250px'}}>
          <CardBody className='py-2 text-center' style={{backgroundColor: 'rgba(255,255,255,0.2)'}}>
            <h3 className='my-0'>{item.s_mawb}</h3>
          </CardBody>
        </Card>
        {/* <CarouselCaption captionText={item.caption} captionHeader={item.caption} /> */}
      </CarouselItem>
    );
  });

  return (
    items && items.length > 0 ?
      <div className='px-0 py-0'>
        <style>
        {
          `
            .carousel-control-prev-icon,
            .carousel-control-next-icon {
              filter: invert(100%);
            }

            .carousel-inner {
              width: 250px;
            }

            .carousel-indicators {
              display: none;
            }
          `
        }
      </style>
        <Carousel
            activeIndex={activeIndex}
            next={next}
            previous={previous}
            interval={false}
        >
            <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex} />
                {slides}
            <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
            <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
        </Carousel>
      </div> :
      <></>
  );
}

export default SelectAwbSlideShow;