import React, {Fragment} from 'react';
import Switch from "rc-switch";
import "rc-switch/assets/index.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Input,
    Label,
    Row
  } from "reactstrap";
import moment from 'moment';
import FileBase64 from 'react-file-base64';

const ModalReportDamage = ({
    open, 
    handleModal,
    comments,
    setComments,
    getFiles,
    fileInputKey,
    awb_uld,
    submitDamageReport
}) => {

    return (
        <Fragment>
            <Modal isOpen={open} toggle={(e) => handleModal(!open)} style={{marginTop: '10%'}}>
                <div className="modal-content" style={{width: '600px'}}>
                    <div className="modal-body mx-auto">
                        <div className='text-center'>
                            <h1>Report Damage for ULD: {awb_uld}</h1>
                            <h4>Notes</h4>
                            <Input type='textarea' className='mb-3' value={comments} onChange={(e) => setComments(e.target.value)} />
                            <FileBase64
                                multiple={ true }
                                onDone={ getFiles } 
                                key={fileInputKey}
                            />
                            <button type="button" className="btn btn-secondary mr-3" onClick={(e) => handleModal(!open)}>Cancel</button>
                            <button className="btn btn-success" onClick={() => submitDamageReport()}>Submit Damage Report</button>
                        </div>
                    </div>
                </div>
            </Modal>
        </Fragment>
    );
}

export default ModalReportDamage;