import React from 'react';

const CompanyCardDetails = ({
    awb, 
    isMyAssignment, 
    removeOwnership, 
    hover, 
    handleSelectAwb, 
    awbsArray,
    selectedType
}) => {

    const resolveBackground = () => {
        if(awb.s_type === 'EXPORT') {
            return `url(/assets/img/bg-blue-sm.png)`;
        }
        return `url(/assets/img/bg-green-sm.png)`;
    }

    if (selectedType === 'ALL' || selectedType === awb.s_type) {
        return (
            <div className={`card my-1 px-2 py-2 ${hover ? 'awb-card-details-hover' : ''}`} style={{borderRadius: "0.75rem 0.75rem", backgroundImage: resolveBackground(), backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} onClick={() => handleSelectAwb && handleSelectAwb(awb, awbsArray)}>
                <h4 className="my-0 py-0 text-dark" style={{fontWeight: 'bold'}}>
                    <div className='row'>
                        <div className='col-10'>
                            <div className='row mx-0'>
                                <div className='col-6 pl-0'>
                                    <h1 className='mt-3'>{awb.s_mawb}</h1>
                                </div>
                                <div className='col-6'>
                                    <img src={awb.s_logo} style={{width: '240px', height: 'auto'}} />
                                </div>
                            </div>
                        </div>
                        <div className='col-2 pt-4' style={{color: 'grey'}}>
                            {isMyAssignment(awb) ? <span className='fas fa-trash-alt mr-1' onClick={() => removeOwnership(awb.s_mawb_id)} style={{float: 'right', fontSize: '20px'}}></span> : null}
                        </div>
                    </div>
                </h4>
            </div>
        );
    } else {
        return (<div></div>);
    }
}

export default CompanyCardDetails;