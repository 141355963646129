import React, {Fragment} from 'react';
import Switch from "rc-switch";
import "rc-switch/assets/index.css";

import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Input,
    Label,
  } from "reactstrap";
import moment from 'moment';
import { throwStatement } from '@babel/types';

const IdCheckModal = ({
    open, 
    handleModal, 
    handleModalIdType2,
    b_company_driver_photo_match_1, 
    handlePhotoMatch1Change, 
    s_company_driver_id_type_1, 
    handleIdType1, 
    d_company_driver_id_expiration_1, 
    handleIdType1Exp,
    s_company_driver_name,
    handleDriverName,
    s_company_driver_id_num_1,
    handleIdNum1,
    idCheckComplete,
    handleClickIdCheckSave,
    handleInput,
    sm_driver_photo
}) => {

    const validDate = (date) => {
        const checkDate = moment(date, 'YYYY-MM-DD');
        return checkDate.isValid() && !moment(checkDate).isBefore(moment().format('MM/DD/YYYY'));
    }

    const enableSavingIdType1 = () => {
        const today = moment();
        console.log(today);
        console.log(moment('11/22/2019').diff(today, 'hours'));
        if(b_company_driver_photo_match_1 && s_company_driver_name.length > 0 && s_company_driver_id_num_1.length > 0 && validDate(d_company_driver_id_expiration_1)) {
            return true;
        }
        return false;
    }

    console.log(sm_driver_photo);

    return (
        <Fragment>
            <Modal isOpen={open} toggle={handleModal}>
            <div className="modal-content" style={{width: '1000px', position: 'absolute', right: '-60%'}}>
                <div className="modal-header mx-auto">
                    <h5 className="modal-title" id="exampleModalLabel">Identification Type 1</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body mx-auto">
                    <div className="row">
                        <div className="col-6">
                            <div className="row">
                                {
                                    sm_driver_photo && sm_driver_photo !== null ? 
                                    <img src={sm_driver_photo} style={{width: '100%', height: 'auto'}} /> :
                                    <i className="fas fa-user" style={{fontSize: '250px', position: 'relative'}}></i>
                                }
                            </div>
                            <div className="row">
                                <label className="col-12 col-form-label" style={{fontSize: '12px', position: 'absolute', left: `${sm_driver_photo ? '40%' : '10%'}`}} id="outPhotoMatchLabel">Photo Match: {b_company_driver_photo_match_1 ? 'Yes' : 'No'}</label>
                                <div className="col-12 mt-4" style={{position: 'absolute', left: `${sm_driver_photo ? '40%' : '15%'}`}}>
                                    <Switch
                                        className="custom-switch custom-switch-primary"
                                        checked={b_company_driver_photo_match_1}
                                        onClick={handlePhotoMatch1Change}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-6">

                            <h4>Name:</h4>
                            <input type="text" value={s_company_driver_name} onChange={handleDriverName} style={{width: '405px'}} className="mb-4 form-control"/>

                            <h4>Identification Type:</h4>
                            <FormGroup>
                                <select value={s_company_driver_id_type_1} onChange={handleIdType1} className="form-control" style={{width: '405px'}}>
                                    <option value="STATE DRIVER'S LICENSE">STATE DRIVER'S LICENSE</option>
                                    <option value="U.S. Passport">U.S. PASSPORT/CARD</option>
                                    <option value="SIDA BADGE">SIDA BADGE</option>
                                    <option value="U.S. Department of Defense ID">U.S. DEPARTMENT OF DEFENSE ID</option>
                                    <option value="PERMENANT RESIDENT CARD">PERMENANT RESIDENT CARD</option>
                                    <option value="FOREIGN GOVERNMENT-ISSUED PASSPORT">FOREIGN GOVERNMENT-ISSUED PASSPORT</option>
                                    <option value="TRANSPORTATION WORKER IDENTIFICATION CREDENTIAL">TRANSPORTATION WORKER IDENTIFICATION CREDENTIAL</option>
                                </select>
                            </FormGroup>

                            <h4>Identification Number:</h4>
                            <input className="form-control" value={s_company_driver_id_num_1} onChange={handleIdNum1} type="text" style={{width: '405px'}}/>
                            
                            <h4 className='mt-2'>Expiration Date</h4>
                            <input 
                                type='date' 
                                className="form-control" 
                                id={'d_company_driver_id_expiration_1'}
                                onChange={(e) => handleInput(e)}
                                defaultValue={moment(d_company_driver_id_expiration_1).format('YYYY-MM-DD')}
                                min={moment().format('YYYY-MM-DD')}
                            >
                            </input>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <h6 style={{position: 'relative', right: '120px', fontWeight: 'bolder', color: `${idCheckComplete() ? '#013220' : 'red'}`}}>
                            {idCheckComplete() ? `Saved` : 'Not Saved'}
                    </h6>
                    <button type="button" className="btn btn-secondary" onClick={handleModal}>Close</button>
                    <button type="button" className="btn btn-primary" disabled={!enableSavingIdType1()} onClick={() => handleClickIdCheckSave()}>Save changes</button>
                    <button type="button" className="btn btn-danger" onClick={handleModalIdType2}>ID Type 2</button>
                </div>
            </div>
            </Modal>
        </Fragment>
    );
}

export default IdCheckModal;