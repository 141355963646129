import React from 'react';
import moment from 'moment';
import {
    CustomInput,
  } from "reactstrap";
import Switch from "rc-switch";
import "rc-switch/assets/index.css";
import {Row, Col, Card, CardBody} from 'reactstrap';

import {useEffect, useRef, useState} from 'react';

const Notes = ({
    s_notes,
    handleInput
}) => {
    return (
        <Row className='py-2' style={{fontSize: '16px'}}>
            <Col md={12}>
                <Card className='custom-opacity-card' style={{borderRadius: '0.75rem'}}>
                    <CardBody className='custom-card-transparent py-3' style={{backgroundColor: 'rgba(255,255,255,0.2)'}}>
                        <h4>Notes:</h4>
                        <textarea type="text" id='s_notes' value={s_notes} onChange={(e) => handleInput(e)} className="form-control"></textarea>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
}

export default Notes;