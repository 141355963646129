import React, { useState, useContext } from "react";
import { AppContext } from '../components/context/index';
import { NavLink as RouterNavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    useWindowSize
} from '@react-hook/window-size'

import {
  Collapse,
  Container,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import ReactTooltip from 'react-tooltip';

import { useAuth0 } from "@auth0/auth0-react";

const NavBar = () => {

    const [width, height] = useWindowSize();

    const {
        s_unit,
        setModalSetUnitOpen,
        metadata
    } = useContext(AppContext);

    const [isOpen, setIsOpen] = useState(false);
    const { user, isAuthenticated, loginWithRedirect, logout } = useAuth0();
    console.log(user);
    const toggle = () => setIsOpen(!isOpen);

    const logoutWithRedirect = () =>
        logout({
        returnTo: window.location.origin
        });

    return (
        <div className="nav-container">
            <Navbar color="light" light expand="md" className='py-1'>
                <Container fluid>
                    {/* <NavbarBrand className="logo" /> */}
                    <NavbarToggler onClick={toggle} />
                    <Collapse isOpen={isOpen} navbar>
                    <ReactTooltip />

                        <Nav className="mr-1" navbar data-tip={'Queue'}>
                            <NavItem>
                                <NavLink
                                    tag={RouterNavLink}
                                    to="/queue"
                                    exact
                                    activeClassName="router-link-exact-active"
                                >
                                <i className="fas fa-users"></i>
                                <h6 className={'icon-title'}>Queue</h6>
                                </NavLink>
                            </NavItem>
                        </Nav>

                        <Nav className="mr-1" navbar>
                            <NavItem>
                                <NavLink
                                    tag={RouterNavLink}
                                    to="/export"
                                    exact
                                    activeClassName="router-link-exact-active"
                                >
                                <i className="fas fa-sign-in-alt" data-tip={'Export'}></i>
                                <h6 className={'icon-title'}>Export</h6>
                                </NavLink>
                            </NavItem>
                        </Nav>

                        <Nav className="mr-1" navbar>
                            <NavItem>
                                <NavLink
                                    tag={RouterNavLink}
                                    to="/import"
                                    exact
                                    activeClassName="router-link-exact-active"
                                >
                                <i className="fas fa-sign-out-alt" data-tip={'Import'}></i>
                                <h6 className={'icon-title'}>Import</h6>
                                </NavLink>
                            </NavItem>
                        </Nav>

                        <Nav className="mr-1" navbar>
                            <NavItem>
                                <NavLink
                                    tag={RouterNavLink}
                                    to="/dock-delivery"
                                    exact
                                    activeClassName="router-link-exact-active"
                                >
                                <i className="fas fa-file-signature" data-tip={'Dock Delivery'}></i>
                                <h6 className={'icon-title'}>Dock Delivery</h6>
                                </NavLink>
                            </NavItem>
                        </Nav>

                        <Nav className="mr-1" navbar>
                            <NavItem>
                                <NavLink
                                    tag={RouterNavLink}
                                    to="/breakdown-instructions"
                                    exact
                                    activeClassName="router-link-exact-active"
                                >
                                <i className="fas fa-plane-arrival" data-tip={'Breakdown Instructions'}></i>
                                <h6 className={'icon-title'}>Breakdown Instructions</h6>
                                </NavLink>
                            </NavItem>
                        </Nav>

                        <Nav className="mr-1" navbar>
                            <NavItem>
                                <NavLink
                                    tag={RouterNavLink}
                                    to="/warehouse-import"
                                    exact
                                    activeClassName="router-link-exact-active"
                                >
                                <i className="fas fa-people-carry" data-tip={'Warehouse Import'}></i>                              
                                <h6 className={'icon-title'}>Warehouse Import</h6>
                                </NavLink>
                            </NavItem>
                        </Nav>

                        <Nav className="mr-r" navbar>
                            <NavItem>
                                <NavLink
                                    tag={RouterNavLink}
                                    to="/rack"
                                    exact
                                    activeClassName="router-link-exact-active"
                                >
                                <i className="fas fa-boxes" data-tip={'Rack'}></i>
                                <h6 className={'icon-title'}>Rack</h6>
                                </NavLink>
                            </NavItem>
                        </Nav>

                        <Nav className="mr-1" navbar>
                            <NavItem>
                                <NavLink
                                    tag={RouterNavLink}
                                    to="/add-transfers"
                                    exact
                                    activeClassName="router-link-exact-active"
                                >
                                <i className="fas fa-truck-loading" data-tip={'Add'}></i>
                                <h6 className={'icon-title'}>Add</h6>
                                </NavLink>
                            </NavItem>
                        </Nav>

                        <Nav className="mr-1" navbar>
                            <NavItem>
                                <NavLink
                                    tag={RouterNavLink}
                                    to="/inbound-transfers"
                                    exact
                                    activeClassName="router-link-exact-active"
                                >
                                <i className="fas fa-ship" data-tip={'Inbound Transfers'}></i>
                                <h6 className={'icon-title'}>Inbound Transfers</h6>
                                </NavLink>
                            </NavItem>
                        </Nav>

                        <Nav className="mr-1" navbar>
                            <NavItem>
                                <NavLink
                                    tag={RouterNavLink}
                                    to="/view-transfers"
                                    exact
                                    activeClassName="router-link-exact-active"
                                >
                                <i className="fas fa-truck-moving" data-tip={'View'}></i>
                                <h6 className={'icon-title'}>View</h6>
                                </NavLink>
                            </NavItem>
                        </Nav>

                        <Nav className="mr-auto" navbar>
                            <NavItem>
                                <NavLink
                                    tag={RouterNavLink}
                                    to="/training"
                                    exact
                                    activeClassName="router-link-exact-active"
                                >
                                <i className="fas fa-graduation-cap" data-tip={'Training'}></i>
                                <h6 className={'icon-title'}>Training</h6>
                                </NavLink>
                            </NavItem>
                        </Nav>

                        {/* <Nav className="text-center" navbar>
                            <NavItem>
                                <NavLink
                                    tag={RouterNavLink}
                                    to="/"
                                    exact
                                    activeClassName="router-link-exact-active"
                                >
                                <img src='https://ewrstorage1.blob.core.windows.net/pics/choice-logo-small.png'/>
                                </NavLink>
                            </NavItem>
                        </Nav> */}
                        
                        <h4 className='mr-3'>{metadata && metadata.s_company}</h4>
                        <Nav className="d-none d-md-block mr-1" navbar>
                        {isAuthenticated && (
                            <UncontrolledDropdown nav inNavbar>
                                <DropdownToggle nav caret id="profileDropDown">
                                    <img
                                        src={user.picture}
                                        alt="Profile"
                                        className="nav-user-profile rounded-circle"
                                        width="50"
                                    />
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem header>{user.name}</DropdownItem>
            
                                    {/* <DropdownItem
                                    tag={RouterNavLink}
                                    to="/view-transfers"
                                    className="dropdown-profile"
                                    activeClassName="router-link-exact-active"
                                    >
                                    View Transfers
                                    </DropdownItem>

                                    <DropdownItem
                                    tag={RouterNavLink}
                                    to="/add-transfers"
                                    className="dropdown-profile"
                                    activeClassName="router-link-exact-active"
                                    >
                                    Add Transfers
                                    </DropdownItem> */}

                                    <DropdownItem
                                        tag={RouterNavLink}
                                        to={'#'}
                                        className="dropdown-profile"
                                        activeClassName="router-link-exact-active"
                                        onClick={() => setModalSetUnitOpen(true)}
                                    >
                                    Unit: {s_unit}
                                    </DropdownItem>

                                    <DropdownItem
                                        id="qsLogoutBtn"
                                        onClick={() => logoutWithRedirect()}
                                    >
                                    <FontAwesomeIcon icon="power-off" className="mr-3" /> Log
                                    out
                                    </DropdownItem>

                                </DropdownMenu>
                            </UncontrolledDropdown>
                        )}
                        </Nav>
                        {isAuthenticated && (
                        <Nav
                            className="d-md-none mr-0"
                            navbar
                            style={{ minHeight: 140 }}
                        >
                            <NavItem>
                                <span className="user-info">
                                    <img
                                        src={user.picture}
                                        alt="Profile"
                                        className="nav-user-profile d-inline-block rounded-circle"
                                        width="50"
                                    />
                                    <h6 className="d-inline-block">{user.name}</h6>
                                </span>
                            </NavItem>
                            <NavItem>
                                <FontAwesomeIcon icon="power-off" className="" />
                                <RouterNavLink
                                    to="#"
                                    id="qsLogoutBtn"
                                    onClick={() => logoutWithRedirect()}
                                >
                                    Log out
                                </RouterNavLink>
                            </NavItem>
                        </Nav>
                    )}
                    {/* <h4 className='mr-3'>{metadata && metadata.s_company}</h4> */}
                    <img src='https://ewrstorage1.blob.core.windows.net/pics/choice-logo-small.png'/>

                    </Collapse>
                </Container>
            </Navbar>
        </div>
    );
};

export default NavBar;
