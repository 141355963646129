import React, { useState, useEffect, useContext } from "react";
import { AppContext } from '../components/context/index';
import { Container, Row, Col, Card, CardBody, Table, Button, Input } from "reactstrap";
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';

import Loading from "../components/Loading";
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';

const AddTransfers = () => {

    const { 
        user, 
        s_unit,
        s_company_guid,
        baseApiUrl,
        audience,
        actions
    } = useContext(AppContext);

    const [s_truck_id, set_s_truck_id] = useState('');
    const [t_departure, set_t_departure] = useState('');
    const [t_estimated_arrival, set_t_estimated_arrival] = useState('');
    const [s_from, set_s_from] = useState('');
    const [s_to, set_s_to] = useState('');
    const [s_emails_to, set_s_emails_to] = useState('');
    const [s_mawb, set_s_mawb] = useState('');
    const [s_mawb_arrival_flight, set_s_mawb_arrival_flight] = useState('');
    const [d_mawb_arrival_flight, set_d_mawb_arrival_flight] = useState('');
    const [i_mawb_pieces, set_i_mawb_pieces] = useState('');
    const [f_mawb_weight_kg, set_f_mawb_weight_kg] = useState('');
    const [s_mawb_destination, set_s_mawb_destination] = useState('');
    const [tableData, setTableData] = useState([
        {
            s_mawb: '',
            s_mawb_arrival_flight: '',
            d_mawb_arrival_flight: '',
            i_mawb_pieces: '',
            f_mawb_weight_kg: '',
            s_mawb_destination: ''
        }
    ]);

    const updateTableData = (i, e) => {
        const copy = Object.assign([], tableData);

        if (e.target.id === 's_mawb') {
            copy[i][e.target.id] = formatMawb(e.target.value);
        } else {
            copy[i][e.target.id] = e.target.value;
        }

        setTableData(copy);
    }

    const addRow = () => {
        const copy = Object.assign([], tableData);

        copy.push({
            s_mawb: '',
            s_mawb_arrival_flight: '',
            d_mawb_arrival_flight: '',
            i_mawb_pieces: '',
            f_mawb_weight_kg: '',
            s_mawb_destination: ''
        });

        setTableData(copy);
    }

    const validTableData = () => {
        let tableDataValid = true;
        let headerDataValid = true;

        const checkArray = [s_truck_id, t_departure, t_estimated_arrival, s_from, s_to];

        for (let i = 0; i < checkArray.length; i++) {
            if (checkArray[i].length < 1) {
                headerDataValid = false;
            }
        }

        for (let i = 0; i < tableData.length; i++) {
            for (let key in tableData[i]) {
                if (tableData[i][key].length < 1) {
                    tableDataValid = false;
                }   
            }
        }

        return headerDataValid && tableDataValid;
    }

    const enableSaving = () => validTableData() && validEmails();

    const rowCreator = (item) => {
        return (`
            <tr>
                <td width='7.77%'>${s_truck_id}</td>
                <td width='15%'>${moment(t_departure).format('MM/DD/YYYY HH:mm a')}</td>
                <td width='15%'>${moment(t_estimated_arrival).format('MM/DD/YYYY HH:mm a')}</td>
                <td width='5%'>${s_from}</td>
                <td width='5%'>${s_to}</td>
                <td width='13%'>${item.s_mawb}</td>
                <td width='7.77%'>${item.s_mawb_arrival_flight}</td>
                <td width='7.77%'>${moment(item.d_mawb_arrival_flight).format('MM/DD/YYYY')}</td>
                <td width='7.77%'>${item.i_mawb_pieces}</td>
                <td width='7.77%'>${item.f_mawb_weight_kg}</td>
                <td width='7.77%'>${item.s_mawb_destination}</td>
            </tr>
        `);
    }

    const saveData = async () => {
        const now = moment().local().format('MM/DD/YYYY hh:mm A');
        const s_truck_guid = uuidv4();
        const itemsData = [];
        let rows = ``;

        for (let i = 0; i < tableData.length; i++) {
            const pushData = {
                s_truck_id,
                t_departure,
                t_estimated_arrival,
                s_from,
                s_to,
                s_emails_to,
                t_created: now,
                s_created_by: user.name,
                t_modified: now,
                s_modified_by: user.name,
                s_status: 'ENTERED',
                s_submitted_on: 'EXTERNAL PORTAL',
                s_truck_guid,
                s_mawb_guid: uuidv4(),
                s_company: 'TGT',
                s_company_guid,
                s_unit
            }
            for (let key in tableData[i]) {
                pushData[key] = tableData[i][key];
            }
            itemsData.push(pushData);
            console.log(rowCreator(tableData[i]));
            rows += rowCreator(tableData[i]);
        }

        let table = `
            <table>
                <thead>

                </thead>
                <tbody>
                    <tr style='font-weight: bold'>
                        <td width='7.77%'>Truck No.</td>
                        <td width='15%'>Depart Date</td>
                        <td width='15%'>Time of Arrival</td>
                        <td width='5%'>From</td>
                        <td width='5%'>To</td>
                        <td width='13%'>AWB</td>
                        <td width='7.77%'>Flight</td>
                        <td width='7.77%'>Date</td>
                        <td width='7.77%'>Pieces</td>
                        <td width='7.77%'>Weight</td>
                        <td width='7.77%'>Destination</td>
                    </tr>
                    ${rows}
                </tbody>
            </table>
        `;

        const data = {
            itemsData,
            emailsData: {
                s_emails_to,
                emailBody: table,
                emailTitle: `These AWBs were entered into the Transfer Portal by ${user.name} at ${now}.`
            }
        }

        const token = await actions.getAccessTokenSilently({ audience });
            const response = await axios.post(`${baseApiUrl}/addTransfers`, {
                data
            }, {
                headers: {'Authorization': `Bearer ${token}`}
            });

            if (response.status === 200) {
                reset();
                actions.createSuccessNotification('Data entered');
            }
        try {

        } catch (err) {
            alert(err);
        }
    }

    const formatMawb = (mawb) => {
        return mawb.substr(0, 13).replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
    }

    const reset = () => {
        set_s_truck_id('');
        set_t_departure('');
        set_t_estimated_arrival('');
        set_s_from('');
        set_s_to('');
        set_s_emails_to('');
        setTableData([
            {
                s_mawb: '',
                s_mawb_arrival_flight: '',
                d_mawb_arrival_flight: '',
                i_mawb_pieces: '',
                f_mawb_weight_kg: '',
                s_mawb_destination: ''
            }
        ]);
    }

    const deleteRow = (index) => {
        const copy = Object.assign([], tableData);
        const newTable = copy.filter((t, i) => i !== index);
        setTableData(newTable);
    }

    const validateEmail = (email) => {
        var re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
        return re.test(email);
    }
            
    const validEmails = () => {
        if (s_emails_to.length === 0) {
            return true;
        } else {
            const emails = s_emails_to.split(';');
            const invalidEmails = [];
        
            for (let i = 0; i < emails.length; i++) {
                if (emails[i].trim().length > 0) {
                    if(!validateEmail(emails[i].trim())) {
                        invalidEmails.push(emails[i].trim())
                    }
                }
            }
    
            return invalidEmails.length === 0;
        }
    }

    return (
        <Container fluid className='px-4 py-4'>
            <Row>
                <Col md={12}>
                    <h1>Add Transfers</h1>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <Card className='custom-card'>
                        <CardBody className='py-3 px-5'>
                            <Row>
                                <h4>Add truck transfer details:</h4>
                            </Row>
                            <Row>
                                <Table striped style={{tableLayout: 'fixed'}}>
                                    <thead>

                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th width='25%'>Truck Number</th>
                                            <td>
                                                <input type='text' value={s_truck_id} onChange={(e) => set_s_truck_id(e.target.value)} style={{width: '249px'}} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Depart Date and Time</th>
                                            <td>
                                                <input type='datetime-local' value={t_departure} onChange={(e) => set_t_departure(e.target.value)} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Truck Estimated Time of Arrival</th>
                                            <td>
                                                <input type='datetime-local' value={t_estimated_arrival} onChange={(e) => set_t_estimated_arrival(e.target.value)} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>From</th>
                                            <td>
                                                <input type='text' value={s_from} onChange={(e) => set_s_from(e.target.value)} style={{width: '249px'}} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>To</th>
                                            <td>
                                                <input type='text' value={s_to} onChange={(e) => set_s_to(e.target.value)} style={{width: '249px'}} />
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Row>
                            <Row>
                                <Table striped>
                                    <thead>
                                        <tr>
                                            <th>AWB</th>
                                            <th>Arrive Flight</th>
                                            <th>Arrive Date</th>
                                            <th>Pieces</th>
                                            <th>Weight</th>
                                            <th>Destination</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            tableData.map((d, i) => 
                                                <tr key={i}>
                                                    <td>
                                                        <input type='text' id={'s_mawb'} value={d.s_mawb} onChange={(e) => updateTableData(i, e)} />
                                                    </td>
                                                    <td>
                                                        <input type='text' id={'s_mawb_arrival_flight'} value={d.s_mawb_arrival_flight} onChange={(e) => updateTableData(i, e)} />
                                                    </td>
                                                    <td>
                                                        <input type='date' id={'d_mawb_arrival_flight'} value={d.d_mawb_arrival_flight} onChange={(e) => updateTableData(i, e)} />
                                                    </td>
                                                    <td>
                                                        <input type='number' id={'i_mawb_pieces'} value={d.i_mawb_pieces} onChange={(e) => updateTableData(i, e)} />
                                                    </td>
                                                    <td>
                                                        <input type='number' id={'f_mawb_weight_kg'} value={d.f_mawb_weight_kg} onChange={(e) => updateTableData(i, e)} />
                                                    </td>
                                                    <td>
                                                        <input type='text' id={'s_mawb_destination'} value={d.s_mawb_destination} onChange={(e) => updateTableData(i, e)} />
                                                    </td>
                                                    <td>
                                                        {
                                                            i > 0 && 
                                                            <i className="fas fa-minus-circle" style={{fontSize: '28px'}} onClick={() => deleteRow(i)}></i>
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </Table>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <i className="fas fa-plus-circle text-left" style={{fontSize: '28px', float: 'left'}} onClick={() => addRow()}></i>
                                </Col>
                            </Row>
                            <Row className='mt-4'>
                                <Col md={12}>
                                    <h4>Send Emails To:</h4>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={11}>
                                    <Input type='text-area' value={s_emails_to} onChange={(e) => set_s_emails_to(e.target.value)} style={{float: 'left'}} />
                                </Col>
                                <Col md={1}>
                                    <Button style={{float: 'right'}} color='secondary' disabled={!enableSaving()} onClick={() => saveData()}>Save</Button>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default withAuthenticationRequired(AddTransfers, {
    onRedirecting: () => <Loading />
});
