import React from 'react';
import moment from 'moment';
import {
    CustomInput,
  } from "reactstrap";
import Switch from "rc-switch";
import "rc-switch/assets/index.css";

import {Card, CardBody, Input} from 'reactstrap';

import SelectAwbSlideShow from './SelectAwbSlideShow';

const MyAwbs = ({
    myExportAwbs,
    updateSelectedAwb,
    handleModalUniversal
}) => {
    return (
        <div className="row pt-3 pb-2 px-3">
            <Card className='custom-opacity-card' style={{borderRadius: '0.75rem'}}>
                <CardBody className='custom-card-transparent py-3 px-5' style={{backgroundColor: 'rgba(255,255,255,0.2)'}}>
                    <div className="row px-0">
                        <h4>My Export AWBs: {myExportAwbs && myExportAwbs.length}</h4>
                    </div>
                    <div className='row px-0'>
                        <div style={{width: '250px'}}>
                            <SelectAwbSlideShow         
                                items={myExportAwbs}
                                updateSelectedAwb={updateSelectedAwb}
                            />
                        </div>
                        <div>
                            <button className="btn btn-info ml-2 py-0" style={{height: '42px'}} onClick={() => handleModalUniversal('modalCreateAwbOpen')} type="button">Create AWB</button>
                        </div>
                    </div>
                </CardBody>
            </Card>
        </div>
    );
}

export default MyAwbs;
