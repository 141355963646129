import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input } from 'reactstrap';
import { Formik } from 'formik';

export default ({
    open,
    toggle,
    selectedTransfer,
    updateInboundTransfer,
    deleteTransfer
}) => {

    const [initialValues, setInitialValues] = useState({});


    const resolveInitialValues = (transfer) => {
        const values = {};
        const keys = ['i_planned_quantity', 'i_actual_quantity'];

        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            values[key] = transfer[key];
        }   

        return values;
    }

    useEffect(() => {
        if (selectedTransfer.id) {
            const values = resolveInitialValues(selectedTransfer);
            setInitialValues(values);
        }
    }, [selectedTransfer]);


    return (
        <div>
            <Modal isOpen={open} toggle={toggle} size={'lg'}>
                <ModalHeader>Update Inbound Transfer</ModalHeader>
                    <Formik
                        initialValues={initialValues}
                        validate={values => {
                            const errors = {};
                            const exclude = [];
                            for (let key in values) {
                                if (exclude.indexOf(key) !== -1 && values[key].length === 0) {
                                    errors[key] = `${key} is invalid: ${values[key]}`
                                }
                            }
                            return errors;
                        }}
                    >
                        {({ isValid, values, setFieldValue }) => (
                            <>
                                <ModalBody>
                                    <Form>
                                        <FormGroup>
                                            <Label>Planned Quantity</Label>
                                            <Input type='number' value={values.i_planned_quantity} onChange={(e) => setFieldValue('i_planned_quantity', e.target.value)} />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>Actual Quantity</Label>
                                            <Input type='number' value={values.i_actual_quantity} onChange={(e) => setFieldValue('i_actual_quantity', e.target.value)} />
                                        </FormGroup>
                                    </Form>
                                </ModalBody>
                                <ModalFooter>
                                    <Button color="primary" onClick={() => updateInboundTransfer(values)}>Submit</Button>
                                    <Button color='danger' onClick={() => deleteTransfer(selectedTransfer.id)}>Delete</Button>
                                    <Button color="secondary" onClick={toggle}>Cancel</Button>
                                </ModalFooter>
                            </>
                        )}
                    </Formik>
            </Modal>
        </div>
    );
}