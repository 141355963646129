import React, { useState, useEffect, useContext } from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { AppContext } from '../components/context/index';
import { Formik } from 'formik';
import moment from 'moment';
import axios from 'axios';
import { Button, Row, Col, Card, CardBody } from 'reactstrap';
import classnames from 'classnames';

import CompanyCard from '../components/queue/CompanyCard';
import ModalViewCompany from '../components/queue/ModalViewCompany';
import MyAssignment from '../components/queue/MyAssignment';
import ModalAddDriver from '../components/queue/ModalAddDriver';
import addDriverMapping from '../components/queue/addDriverMapping';

const Queue = () => {

    const {
        user,
        s_unit,
        eosWebApiUrl,
        eosWebAuthCode,
        actions
    } = useContext(AppContext);

    const email = user.name;
    const { asyncHandler, createSuccessNotification, addLocalValue, updateLocalValue, deleteLocalValue } = actions;

    const companyTypes = ['IMPORT', 'EXPORT', 'TRANSFERS', 'ALL'];
    const [apiCallPending, setApiCallPending] = useState(false);
    const [companiesList, setCompaniesList] = useState([]);
    const [awbs, setAwbs] = useState([]);
    const [accessToken, setAccessToken] = useState('');
    const [modal, setModal] = useState(false);
    const [selectedCompany, setSelectedCompany] = useState({});
    const [selectedType, setSelectedType] = useState(companyTypes[0]);
    const [myAssignments, setMyAssignments] = useState([]);
    const [myAssignmentCompany, setMyAssignmentCompany] = useState({});
    const [customerFrequency, setCustomerFrequency] = useState(0);
    const [stats, setStats] = useState([]);
    const [modalAddDriver, setModalAddDriver] = useState(false);
    const [initialValues, setInitialValues] = useState({});

    const setQueueData = (data) => {
        const { companiesList, items, accessToken } = data;
        setCompaniesList(companiesList);
        setAwbs(items);
        setAccessToken(accessToken);
        const myAssignments = resolveMyAssignments(items);
        setMyAssignments(myAssignments);

        if (myAssignments.length > 0 && myAssignments[0]) {
            const { s_trucking_company } = myAssignments[0];

            if (myAssignmentCompany.s_trucking_company !== s_trucking_company) {
                setMyAssignmentCompany(myAssignments[0]);
            }
        }

    }

    const queueQuery = asyncHandler(async () => {
        if (!apiCallPending) {
            setApiCallPending(true);
    
            const res = await axios.post(`${eosWebApiUrl}/queue`, {
                s_unit
            }, {
                headers: {
                    Authorization: `Bearer ${eosWebAuthCode}`
                }
            });

            console.log(res.data);

            setQueueData(res.data);
            setApiCallPending(false);
        }
    });

    const statsQuery = asyncHandler(async () => {
        if (!apiCallPending) {
            const s_user = user && email;
    
            const res = await axios.post(`${eosWebApiUrl}/queueStats`, {
                s_unit,
                s_user
            }, {
                headers: {
                    Authorization: `Bearer ${eosWebAuthCode}`
                }
            });

            setStats(res.data);

        }
    });

    const resolveInitialValues = (mapping) => {
        const values = {};
        for (let i = 0; i < mapping.length; i++) {
            const key = mapping[i];
            values[key] = '';
            values['t_kiosk_start'] = moment().local().format('MM/DD/YYYY HH:mm')
        }
        return values;
    }

    const initializeValues = () => {
        const values = resolveInitialValues(addDriverMapping);
        setInitialValues(values);
    }

    useEffect(() => {
        if (user && s_unit) {

            queueQuery();
            statsQuery();

            const dataInterval = setInterval(() => {
                queueQuery();
            }, 10000);

            const statsInterval = setInterval(() => {
                statsQuery();
            }, 60000);

            initializeValues();

            return () => {
                clearInterval(dataInterval);
                clearInterval(statsInterval);
            }

        }
    }, [user, s_unit]);

    const viewCompany = (company) => {
        setSelectedCompany(company);
        setModal(true);
    }

    const viewMyAssignmentCompany = (company) => {
        setSelectedCompany(company);
        setSelectedType(company.s_type);
        setModal(true);
    }

    const renderCompany = (company) => {
        if (selectedType === 'EXPORT') {
            return company.exportCount > 0;
        } else if (selectedType === 'IMPORT') {
            return company.importCount > 0;
        } else if (selectedType === 'TRANSFERS') {
            return false;
        } else {
            return company.exportCount > 0 || company.importCount > 0;
        }
    }

    const isMyAssignment = (awb) => {
        const { s_counter_ownership_agent } = awb;
        if (s_counter_ownership_agent && user) {
            return s_counter_ownership_agent.toUpperCase() === email.toUpperCase();
        }
        return false;
    }

    const takeOwnership = asyncHandler(async(selectedCompany, selectedType) => {
        if (user && email && email !== null && email.length > 0) {
            
            const useEmail = email;
            const nameArray = useEmail.split('@');
            const agentName = nameArray[0];
            const t_counter_ownership = moment().local().format('MM/DD/YYYY HH:mm');
            const { s_transaction_id, s_trucking_company, s_trucking_driver, s_trucking_cell, b_trucking_sms } = selectedCompany;
            
            if (useEmail && useEmail !== null && useEmail.length > 0) {
                
                setApiCallPending(true);

                const res = await axios.put(`${eosWebApiUrl}/takeOwnership`, {
                    s_transaction_id,
                    s_counter_ownership_agent: useEmail,
                    s_type: selectedType,
                    t_counter_ownership,
                    s_unit,
                    s_trucking_company, 
                    s_trucking_driver, 
                    s_trucking_cell, 
                    b_trucking_sms, 
                    agentName
                }, {
                    headers: {'Authorization': `Bearer ${eosWebAuthCode}`}
                });

                console.log(res.data);
                
                setQueueData(res.data);
                setApiCallPending(false);
                setModal(false);
            }
        } 
    });

    const resolveMyAssignments = (awbs) => {
        const assignments = [];
        for (let i = 0; i < awbs.length; i++) {
            if (awbs[i].s_counter_ownership_agent === email.toUpperCase()) {
                assignments.push(awbs[i]);
            }
        }
        return assignments;
    }

    const removeOwnership = asyncHandler(async(s_mawb_id) => { 
        if (user && email.length > 0) {
            const now = moment().local().format('MM/DD/YYYY HH:mm');

            setApiCallPending(true);
    
            const res = await axios.put(`${eosWebApiUrl}/removeOwnership`, {
                s_mawb_id,
                s_modified_by: email,
                t_modified: now,
                s_unit
            }, {
                headers: {
                    'Authorization': `Bearer ${eosWebAuthCode}`
                }
            });
    
            setQueueData(res.data);
            setApiCallPending(false);
            setModal(false);
        } 
    });

    const removeCompanyOwnership = asyncHandler(async() => { 
        if (user && email.length > 0) {
            const now = moment().local().format('MM/DD/YYYY HH:mm');

            setApiCallPending(true);
    
            const res = await axios.put(`${eosWebApiUrl}/removeCompanyOwnership`, {
                s_transaction_id: selectedCompany.s_transaction_id,
                s_modified_by: email,
                t_modified: now,
                s_unit,
                s_type: selectedType
            }, {
                headers: {
                    'Authorization': `Bearer ${eosWebAuthCode}`
                }
            });

            console.log(res.data);
    
            setQueueData(res.data);
            setApiCallPending(false);
            setModal(false);
        } 
    });

    const markLeftEarly = asyncHandler(async(selectedCompany) => {
        const agent = user && email && email.length > 0;
        const now = moment().local().format('MM/DD/YYYY hh:mm A');
        const t_modified = now;
        const s_modified_by = agent;
        const s_status = 'LEFT EARLY';
        const { s_transaction_id } = selectedCompany;
        const s_abandoned_agent = agent;
        const t_abandoned = now;

        if (agent) {
            setApiCallPending(true);

            const res = await axios.put(`${eosWebApiUrl}/markLeftEarly`, {
                t_modified,
                s_modified_by,
                s_status,
                s_transaction_id,
                s_abandoned_agent,
                t_abandoned,
                s_unit
            }, {
                headers: {'Authorization': `Bearer ${eosWebAuthCode}`}
            });

            createSuccessNotification(`${selectedCompany.s_trucking_company} removed from Queue`);
            setQueueData(res.data);
            setApiCallPending(false);
            setModal(false);

        }
    });

    const queueCustomerFrequency = asyncHandler(async() => {
        const res = await axios.post(`${eosWebApiUrl}/queueCustomerFrequency`, {
            s_trucking_cell: myAssignmentCompany.s_trucking_cell
        }, {
            headers: {
                Authorization: `Bearer ${eosWebAuthCode}`
            }
        });

        setCustomerFrequency(res.data[0].i_frequency);
    });

    useEffect(() => {
        if (myAssignmentCompany && myAssignmentCompany.s_trucking_cell && myAssignmentCompany.s_trucking_cell.length >= 10) {
            queueCustomerFrequency(); 
        }
    }, [myAssignmentCompany]);

    const checkIsWaiting = (company, awbs, selectedType) => {
        if (company.s_state === 'MIXED') {

            // Select all AWBs for the company (waiting & documenting):
            const companyAwbs = awbs.filter(
                awb => awb.s_transaction_id === company.s_transaction_id
            );

            if (selectedType === 'ALL') {
                //All AWBs must be WAITING to take ownership of ALL AWBs in a company:
                for (let i = 0; i < companyAwbs.length; i++) {
                    if (companyAwbs[i].s_status !== 'WAITING') {
                        return false;
                    }
                }
                return true;
            } else {

                // If 1+ AWBs of the selected type are WAITING, they can be taken ownership of:
                const waitingAwbs = companyAwbs.filter(
                    awb => awb.s_status === 'WAITING' && awb.s_type === selectedType
                );
        
                return waitingAwbs.length > 0;
            }

        }
        return company.s_status === 'WAITING';
    }

    const timeSince = (_start) => {
        const start = moment(_start)
        const now = moment();
        const diff = moment.duration(moment(now).diff(moment(start)));
        var days = parseInt(diff.asDays()); //84
        var hours = parseInt(diff.asHours()); //2039 hours, but it gives total hours in given miliseconds which is not expacted.
        hours = hours - days*24;  // 23 hours
        var minutes = parseInt(diff.asMinutes()); //122360 minutes,but it gives total minutes in given miliseconds which is not expacted.
        minutes = minutes - (days*24*60 + hours*60);
        minutes = minutes < 10 ? `0${minutes}` : minutes;
        //daylight savings ? - 4 : - 5
        //daylight savings = March 8 to November 1
        const minusHours = moment().isDST() ? 4 : 5;
        hours = hours - minusHours;
        return `${hours}:${minutes}`;
    }

    const resolveCounterPaths = (path) => {
        const counterPaths = ['/EOS/Operations/Queue', '/EOS/Operations/Delivery', '/EOS/Operations/Acceptance'];
        return counterPaths.indexOf(path) !== -1;
    }

    const handleAddDriver = () => {
        initializeValues();
        setModalAddDriver(true);
    }

    return (
        <Row className='px-3 py-3'>
            <Col md={12}>
                <Row>
                    <Col md={9}>
                        <Row>
                            <Col md={12} className='mb-2'>
                                <h1 className='d-inline'>Queue</h1>
                                {
                                    s_unit === 'CJFK2' && 
                                    <Button className='d-inline ml-2' onClick={() => handleAddDriver()}>Add Driver</Button>
                                }
                            </Col>
                        </Row>
                        {
                            stats.length > 0 && 
                            <Row className='mb-4'>
                                <Col md={3}>
                                    <Card style={{ borderRadius: '0.75rem' }}>
                                        <CardBody className='custom-opacity-card py-2'>
                                            <h4>Customer Wait</h4>
                                            <h6>{stats[0].num_transactions} Transactions</h6>
                                            <Row>
                                                <Col md={12}>
                                                    <Row>
                                                        <Col md={12}>
                                                            <i className='fas fa-arrow-up d-inline mr-3' />
                                                            <h6 className='d-inline'>Max: {stats[0].max_wait}</h6>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={12}>
                                                            <i className='fas fa-ellipsis-h d-inline mr-3' />
                                                            <h6 className='d-inline'>Avg: {stats[0].avg_wait}</h6>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={12}>
                                                            <i className='fas fa-arrow-down d-inline mr-3' />
                                                            <h6 className='d-inline'>Min: {stats[0].min_wait}</h6>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col md={3}>
                                    <Card style={{ borderRadius: '0.75rem' }}>
                                        <CardBody className='custom-opacity-card py-2'>
                                            <h4>Counter Process</h4>
                                            <h6>{stats[0].num_awbs} AWBs</h6>
                                            <Row>
                                                <Col md={12}>
                                                    <i className='fas fa-arrow-up d-inline mr-3' />
                                                    <h6 className='d-inline'>Max: {stats[0].max_process}</h6>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={12}>
                                                    <i className='fas fa-ellipsis-h d-inline mr-3' />
                                                    <h6 className='d-inline'>Avg: {stats[0].avg_process}</h6>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={12}>
                                                    <i className='fas fa-arrow-down d-inline mr-3' />
                                                    <h6 className='d-inline'>Min: {stats[0].min_process}</h6>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col md={3}>
                                    <Card style={{ borderRadius: '0.75rem' }}>
                                        <CardBody className='custom-opacity-card py-2'>
                                            <h4>My Process</h4>
                                            <h6>{stats[1].num_awbs} AWBs</h6>
                                            <Row>
                                                <Col md={12}>
                                                    <i className='fas fa-arrow-up d-inline mr-3' />
                                                    <h6 className='d-inline'>Max: {stats[0].my_max_process}</h6>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={12}>
                                                    <i className='fas fa-ellipsis-h d-inline mr-3' />
                                                    <h6 className='d-inline'>Avg: {stats[0].my_avg_process}</h6>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={12}>
                                                    <i className='fas fa-arrow-down d-inline mr-3' />
                                                    <h6 className='d-inline'>Min: {stats[0].my_min_process}</h6>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col md={3}>
                                    <Card style={{ borderRadius: '0.75rem', height: '133px' }}>
                                        <CardBody className='custom-opacity-card py-2'>
                                            <h4>Current Customer</h4>
                                            {
                                                myAssignments.length > 0 && myAssignments[0] && 
                                                <>
                                                    <h6>Waiting Time: {timeSince(myAssignments[0].t_kiosk_submitted)}</h6>
                                                    <h6>Processing Time: {timeSince(myAssignments[0].t_counter_ownership)}</h6>
                                                </>
                                            }
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        }
                        <Row>
                            <Col md={12}>
                                <h4>My Assignments</h4>
                            </Col>
                        </Row>
                        {
                            myAssignments.length > 0 ?
                            <>
                                <Row>
                                    <Col md={7}>
                                        <Card style={{borderRadius: '0.75rem'}} onClick={() => viewMyAssignmentCompany(myAssignments[0])} className='hover-pointer'>
                                            <CardBody className='custom-card-opacity py-3'>
                                                <Row>
                                                    <Col md={3}>
                                                        <img src={`${myAssignments[0].s_driver_photo_link || 'https://media.istockphoto.com/vectors/default-profile-picture-avatar-photo-placeholder-vector-illustration-vector-id1214428300?b=1&k=6&m=1214428300&s=612x612&w=0&h=kMXMpWVL6mkLu0TN-9MJcEUx1oSWgUq8-Ny6Wszv_ms='}`} style={{ borderRadius: '50%', width: '110px', height: '110px' }} />
                                                    </Col>
                                                    <Col md={9}>
                                                        <h4>Customer Details:</h4>
                                                        <h6>{myAssignments[0].s_trucking_company}</h6>
                                                        <h6>{myAssignments[0].s_trucking_driver}</h6>
                                                        <h6>{myAssignments[0].s_trucking_email}</h6>
                                                        <h6>{myAssignments[0].s_trucking_cell || 'No Cellphone Entered'} - SMS {myAssignments[0].b_trucking_sms ? 'Enabled' : 'Disabled'}</h6>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col md={5}>
                                        <Card style={{borderRadius: '0.75rem', height: '143px'}}>
                                            <CardBody className='custom-card-opacity py-3'>
                                                <Row>
                                                    <Col md={12}>
                                                        <h4>Frequency</h4>
                                                        <h6>This customer has visited this warehouse {customerFrequency} times in the 30 days.</h6>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row className='mt-4'>
                                    <Col md={12}>
                                        <h4>Task List: {myAssignments.length}</h4>
                                    </Col>
                                    <Row className='mx-1' style={{ height: '300px', overflowY: 'scroll', width: '100%' }}>
                                    {
                                        myAssignments.map((awb, i) =>
                                            <MyAssignment 
                                                awb={awb}
                                                key={i}
                                                // width={width}
                                            />
                                        )
                                    }
                                    </Row>
                                </Row>
                            </> :
                            awbs.length > 0 ?
                            <h4>You are not currently assigned a customer. Please select a customer from the queue selection.</h4> :
                            <h4>There are no customers waiting.</h4>
                        }
                    </Col>
                    <Col md={3}>
                        <Row>
                            <Col md={12}>
                                {
                                    companyTypes.map((t, i) =>
                                        <Button 
                                            key={i} 
                                            className={classnames(`mx-1`, { 'active': selectedType === t })}
                                            onClick={()=> setSelectedType(t)}
                                            active={selectedType === t}
                                        >
                                            {t}
                                        </Button>
                                    )
                                }
                            </Col>
                        </Row>
                        <Row className='mt-2' style={{ backgroundColor: '#D3D3D3', height: '60vh', borderRadius: '0.75rem', overflowY: 'scroll' }}>
                            <Col md={12}>
                                {
                                    companiesList && companiesList.map((c, i) => renderCompany(c) &&
                                        <CompanyCard 
                                            company={c}
                                            viewCompany={viewCompany}
                                            selectedType={selectedType}
                                            awbs={awbs}
                                            checkIsWaiting={checkIsWaiting}
                                            timeSince={timeSince}
                                            key={i}
                                        />
                                    )
                                }
                            </Col>
                        </Row>

                        {/* Active Users */}
                        {/* <Row style={{ overflowX: 'scroll'}}>
                            <Col md={12}>
                                <h6 className='mb-0 mt-2'>Currently on this Page:</h6>
                            </Col>
                            {
                                activeUsers.map((otherUser, i) => user && user.office === otherUser.station &&
                                    resolveCounterPaths(otherUser.path) &&
                                    <ActiveUser 
                                        user={otherUser}
                                        accessToken={accessToken}
                                        key={i}
                                        eightyWindow={eightyWindow}
                                    />
                                )
                            }
                        </Row> */}
                    </Col>
                </Row>
            </Col>

            <ModalViewCompany 
                modal={modal}
                setModal={setModal}
                selectedCompany={selectedCompany}
                awbs={awbs}
                selectedType={selectedType}
                isMyAssignment={isMyAssignment}
                myAssignments={myAssignments}
                takeOwnership={takeOwnership}
                removeOwnership={removeOwnership}
                removeCompanyOwnership={removeCompanyOwnership}
                markLeftEarly={markLeftEarly}
                checkIsWaiting={checkIsWaiting}
            />

            <Formik
                initialValues={initialValues}
            >
                {({ setFieldValue, values, resetForm, isValid, initialValues }) => (
                    <ModalAddDriver 
                        user={user}
                        s_unit={s_unit}
                        eosWebApiUrl={eosWebApiUrl}
                        eosWebAuthCode={eosWebAuthCode}
                        queueQuery={queueQuery}
                        createSuccessNotification={createSuccessNotification}
                        modal={modalAddDriver}
                        setModal={setModalAddDriver}
                        setFieldValue={setFieldValue}
                        values={values}
                        resetForm={resetForm}
                        isValid={isValid}
                        initialValues={initialValues}
                        setQueueData={setQueueData}
                        setApiCallPending={setApiCallPending}
                        asyncHandler={asyncHandler}
                        addLocalValue={addLocalValue}
                        updateLocalValue={updateLocalValue}
                        deleteLocalValue={deleteLocalValue}
                    />
                )}
            </Formik>

        </Row>
    );
}

export default withAuthenticationRequired(Queue);