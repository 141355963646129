import React, { useState, useEffect } from 'react';
import { Row, Col, Button, FormGroup } from 'reactstrap';
import { Field } from 'formik';

export default ({
    selectedAwb,
    values,
    createImportItem,
    push
}) => {
    return (
        <Row>
            <Col md={12}>
                <h4>MAWB: {selectedAwb.s_mawb} / HAWB: {values.s_hawb}</h4>
                <FormGroup>
                    <h4>Copy and Paste Cargo Clearance here:</h4>
                    <Field name={'s_customs_release'} component={'textarea'} className={'form-control'} style={{ height: '400px' }} />
                </FormGroup>
                <FormGroup className={'mt-2'}>
                    <h4>Notes:</h4>
                    <Field name={'s_notes'} component={'textarea'} className={'form-control'} style={{ height: '100px' }} />
                </FormGroup>
            </Col>
            <Col md={12} className={'text-right mt-2'}>
                <Button 
                    disabled={values.s_customs_release.length < 1}
                    onClick={() => createImportItem(false, push)}
                >
                    Accept Cargo Delivery and Send Order to Warehouse
                </Button>
            </Col>
        </Row>
    );
}