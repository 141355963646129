import React, { Component, Fragment, useState, useEffect, useRef  } from 'react';
import {withRouter} from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';
import Switch from "rc-switch";
import "rc-switch/assets/index.css";
import { Table, Button, Input, Card, CardBody, Breadcrumb, BreadcrumbItem, Nav, NavItem, TabContent, TabPane, Row, Col  } from "reactstrap";
import { NavLink } from "react-router-dom";
import classnames from "classnames";

import Locate from './Locate';

const WarehouseBreakdown = ({
    user, eosWebApiUrl, eosWebAuthCode, s_unit, createSuccessNotification
}) => {

    const [activeFirstTab, setActiveFirstTab] = useState('1');
    const [s_pou, set_s_pou] = useState(null);
    const [userEmail, setUserEmail] = useState(null);
    
    const toggleTab = (tab) => {
        if (activeFirstTab !== tab) {
            setActiveFirstTab(tab);
        }   
    }

    useEffect(() => {
        const email = user && user.name; 

        s_unit && set_s_pou(s_unit.substr(1, 3));
        email && setUserEmail(email);
    }, [user, s_unit]);

    return (
        <Col md='12' lg='12'>
            {/* <Row className='mt-2'>
                <Col mg='12' lg='12'>
                    <Nav tabs className="separator-tabs ml-0 mb-2">
                        <NavItem>
                            <NavLink
                            location={{}}
                            to="#"
                            className={classnames({
                                active: activeFirstTab === "1",
                                "nav-link": true
                            })}
                            onClick={() => {
                                toggleTab("1");
                            }}
                            >
                            Locate
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                            location={{}}
                            to="#"
                            className={classnames({
                                active: activeFirstTab === "2",
                                "nav-link": true
                            })}
                            onClick={() => {
                                toggleTab("2");
                            }}
                            >
                            Unmanifested
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                            location={{}}
                            to="#"
                            className={classnames({
                                active: activeFirstTab === "3",
                                "nav-link": true
                            })}
                            onClick={() => {
                                toggleTab("3");
                            }}
                            >
                            Close ULD
                            </NavLink>
                        </NavItem>
                    </Nav>
                </Col>
            </Row>

            <TabContent activeTab={activeFirstTab} className='mt-2'>
                <TabPane tabId="1">
                    <Locate 
                        eosWebApiUrl={eosWebApiUrl}
                        eosWebAuthCode={eosWebAuthCode}
                        user={user}
                        userEmail={userEmail}
                        s_pou={s_pou}
                        eightyWindow={eightyWindow}
                        createSuccessNotification={createSuccessNotification}                            
                    />
                </TabPane>
            </TabContent> */}
            <Row>
                <Locate 
                    eosWebApiUrl={eosWebApiUrl}
                    eosWebAuthCode={eosWebAuthCode}
                    user={user}
                    userEmail={userEmail}
                    s_pou={s_pou}
                    s_unit={s_unit}
                    createSuccessNotification={createSuccessNotification}                            
                />
            </Row>
        </Col>
    );
}

export default withRouter(WarehouseBreakdown);