import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Input, Label, Row, Col } from 'reactstrap';
import moment from 'moment';
import { Formik, Field } from 'formik';
import Switch from "rc-switch";
import "rc-switch/assets/index.css"; 
import FormikSwitch from '../custom/FormikSwitch';

export default ({
    modal,
    setModal,
    s_notes,
    set_s_notes,
    type,
    s_flightid,
    selectedUld,
    selectedAwb,
    addBreakdownNotes,
    hawbs,
    fwbData,
    selectedHawbRecord,
    setSelectedHawbRecord,
    setSelectedHouseSerial
}) => {

    console.log(selectedHawbRecord);

    const toggle = () => setModal(!modal);

    const resolveTitle = () => {
        if (type === 'uld') {
            return `Add Notes for ULD ${selectedUld.s_uld}.\n(${s_flightid})`;
        }
        return `Add Notes for AWB: ${selectedAwb.s_mawb} in ULD ${selectedUld.s_uld}.\n(${s_flightid})`;
    }

    const [initialValues, setInitialValues] = useState({});

    const resolveInitialValues = () => {
        const awbKeys = ['s_notes', 'b_customs_hold', 'b_usda_hold', 'b_hold', 'b_breakdown_hawb'];
        const uldKeys = ['s_notes', 's_shc', 'b_offloaded'];

        const keys = type === 'uld' ? uldKeys : awbKeys;

        const values = {};

        for (let i = 0; i < keys.length; i++) {
            const currentKey = keys[i];
            values[currentKey] = type === 'uld' ? selectedUld[currentKey] : selectedAwb[currentKey];
        }
        return values;
    }

    useEffect(() => {
        const initialValues = resolveInitialValues();
        console.log(initialValues);
        setInitialValues(initialValues);
    }, [modal]);

    if (type === 'uld') {
        return (
            <Formik
                initialValues={initialValues}
                enableReinitialize={true}
            >
                {({ values, setFieldValue }) =>
                    <Modal isOpen={modal} toggle={toggle} size={'lg'}>
                        <ModalHeader>ULD {selectedUld.s_uld} in Flight {s_flightid}</ModalHeader>
                        <ModalBody>
                            <Row>
                                <Col md={12}>
                                    <h4>ULD Details</h4>
                                    <Table striped>
                                        <thead></thead>
                                        <tbody>
                                            <tr>
                                                <th>Pieces</th>
                                                <th>{selectedUld.i_pieces_total}</th>
                                            </tr>
                                            <tr>
                                                <th>Weight</th>
                                                <th>{selectedUld.f_weight}</th>
                                            </tr>
                                            <tr>
                                                <th>Origin</th>
                                                <th>{selectedUld.s_origin}</th>
                                            </tr>
                                            <tr>
                                                <th>Port of Loading</th>
                                                <th>{selectedUld.s_pol}</th>
                                            </tr>
                                            <tr>
                                                <th>Port of Unloading</th>
                                                <th>{selectedUld.s_pou}</th>
                                            </tr>
                                            <tr>
                                                <th>Destination</th>
                                                <th>{selectedUld.s_destination}</th>
                                            </tr>
                                            <tr>
                                                <th colSpan={2}>
                                                    <FormikSwitch 
                                                        label={'Offloaded'}
                                                        name={'b_offloaded'}
                                                        values={values}
                                                        setFieldValue={setFieldValue}
                                                        disabled={true}
                                                    />
                                                </th>
                                            </tr>
                                            <tr>
                                                <th>Special Handling Codes</th>
                                                <th>
                                                    <Field name={'s_shc'} className={'form-control'} type='text' />
                                                </th>
                                            </tr>
                                        </tbody>
                                    </Table>
                                    <FormGroup>
                                        <Label>Notes for Breakdown</Label>
                                        <Field name={'s_notes'} className={'form-control'} component={'textarea'} />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <Row style={{ width: '100%' }}>
                                <Col md={12}>
                                    <div className={'float-left'}>
                                        <h4>Last modified by: {selectedUld.s_modified_by} at {moment(selectedUld.t_modified).format('MM/DD/YYYY HH:mm')}</h4>
                                    </div>
                                    <div className={'float-right'}>
                                        {/* <Button color="primary mr-2" onClick={() => addBreakdownNotes(values)}>
                                            Save
                                        </Button> */}
                                        <Button color="secondary" onClick={toggle}>Cancel</Button>
                                    </div>
                                </Col>
                            </Row>
                        </ModalFooter>
                    </Modal>
            }
            </Formik>
        );
    } else {
        return (
            <Formik
                initialValues={initialValues}
                enableReinitialize={true}
            >
                {({ values, setFieldValue }) =>
                    <Modal isOpen={modal} toggle={toggle} size={'lg'}>
                    <ModalHeader>AWB {selectedAwb.s_mawb} in ULD {selectedUld.s_uld} in Flight {s_flightid}</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col md={6}>
                                <h4>AWB Details</h4>
                                <Table striped>
                                    <thead></thead>
                                    <tbody>
                                        <tr>
                                            <th>Consignee</th>
                                            <th>{fwbData.s_consignee_address_name1}</th>
                                        </tr>
                                        <tr>
                                            <th>Commodity</th>
                                            <th>{selectedAwb.s_commodity}</th>
                                        </tr>
                                        <tr>
                                            <th>FWB</th>
                                            <th>{fwbData.i_total_consignment_number_of_pieces}</th>
                                        </tr>
                                        <tr>
                                            <th>In this ULD</th>
                                            <th>{selectedAwb.i_actual_piece_count}</th>
                                        </tr>
                                        <tr>
                                            <th>In this Flight</th>
                                            <th>{selectedAwb.i_pieces_total}</th>
                                        </tr>
                                        <tr>
                                            <th>Origin</th>
                                            <th>{selectedAwb.s_origin}</th>
                                        </tr>
                                        <tr>
                                            <th>Port of Loading</th>
                                            <th>{selectedAwb.s_pol}</th>
                                        </tr>
                                        <tr>
                                            <th>Port of Unloading</th>
                                            <th>{selectedAwb.s_pou}</th>
                                        </tr>
                                        <tr>
                                            <th>Destination</th>
                                            <th>{selectedAwb.s_destination}</th>
                                        </tr>
                                        <tr>
                                            <th>SHC</th>
                                            <th>{selectedAwb.s_special_handling_code}</th>
                                        </tr>
                                        <tr>
                                            <th>Agent</th>
                                            <th>{fwbData.s_agent_name}</th>
                                        </tr>
                                        <tr>
                                            <th>Select HAWB - {hawbs.length}</th>
                                            <th>
                                                <select onChange={(e) => setSelectedHouseSerial(e.target.value)} className={'form-control'}>
                                                    {
                                                        hawbs.map((h, i) =>
                                                            <option 
                                                                key={i}
                                                                value={h.s_house_waybill_summaries_serial_1}
                                                            >
                                                                {h.s_house_waybill_summaries_serial_1}
                                                            </option>
                                                        )
                                                    }
                                                </select>
                                            </th>
                                        </tr>
                                        <tr>
                                            <th>FHL Date</th>
                                            <th>{ selectedHawbRecord.t_created && moment(selectedHawbRecord.t_created).format('MM/DD/YYY HH:mm') }</th>
                                        </tr>
                                        <tr>
                                            <th>Nature of Goods</th>
                                            <th>{selectedHawbRecord.s_house_waybill_summaries_nature_of_goods_1}</th>
                                        </tr>
                                        <tr>
                                            <th>Pieces</th>
                                            <th>{selectedHawbRecord.i_house_waybill_summaries_num_pieces_1}</th>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <FormikSwitch 
                                        label={'Customs Hold'}
                                        name={'b_customs_hold'}
                                        values={values}
                                        setFieldValue={setFieldValue}
                                        disabled={true}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <FormikSwitch 
                                        label={'USDA Hold'}
                                        name={'b_usda_hold'}
                                        values={values}
                                        setFieldValue={setFieldValue}
                                        disabled={true}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <FormikSwitch 
                                        label={'CHOICE Hold'}
                                        name={'b_hold'}
                                        values={values}
                                        setFieldValue={setFieldValue}
                                        disabled={true}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <FormikSwitch 
                                        label={'Breakdown by House'}
                                        name={'b_breakdown_hawb'}
                                        values={values}
                                        setFieldValue={setFieldValue}
                                        disabled={true}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label>Notes</Label>
                                    <Field component='textarea' name={'s_notes'} className={'form-control'} style={{ height: '300px' }} />
                                </FormGroup>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Row style={{ width: '100%' }}>
                            <Col md={12}>
                                <div className={'float-left'}>
                                    <h4>Last modified by: {selectedAwb.s_modified_by} at {moment(selectedAwb.t_modified).format('MM/DD/YYYY HH:mm')}</h4>
                                </div>
                                <div className={'float-right'}>
                                    {/* <Button color="primary mr-2" onClick={() => addBreakdownNotes(values)}>
                                        Save
                                    </Button> */}
                                    <Button color="secondary" onClick={toggle}>Cancel</Button>
                                </div>
                            </Col>
                        </Row>
                    </ModalFooter>
                </Modal>
            }
            </Formik>
        );
    }


}