import React, { useContext, useState, useEffect } from "react";
import { AppContext } from './components/context/index';
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { Container } from "reactstrap";
import ReactNotification from 'react-notifications-component';

import Loading from "./components/Loading";
import NavBar from "./components/NavBar";
import Home from "./views/Home";
import { useAuth0 } from "@auth0/auth0-react";
import history from "./utils/history";
import ViewTransfers from './views/ViewTransfers';
import AddTransfers from './views/AddTransfers';
import InboundTransfers from './views/InboundTransfers';
import BreakdownInstructions from './views/BreakdownInstructions';
import ModalSelectStation from './components/ModalSelectStation';
import Training from './views/Training';
import Queue from './views/Queue';
import Export from './views/Export';
import Import from './views/Import';
import SecuredRoute from './components/SecuredRoute';
import WarehouseImport from './views/WarehouseImport';
import Rack from './views/Rack';
import DockDelivery from './views/DockDelivery';

// styles
import "./App.css";
// import '../src/assets/css/styles.scss';

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
initFontAwesome();
 
const App = () => {
    
    const { loading, isAuthenticated } = useAuth0();
    const {
        user,
        s_unit,
        eosWebApiUrl,
        eosWebAuthCode,
        actions,
        history
    } = useContext(AppContext);

    if (loading) {
        return <Loading />;
    }

    return (
        <div id="app" className="d-flex flex-column">
            {
                isAuthenticated && 
                <NavBar />
            }
            <Container fluid className="flex-grow-1 px-0">
                <ReactNotification />
                <Switch>
                    <Route exact path="/">
                        { isAuthenticated ? <Redirect to="/add-transfers" /> : <Home /> }
                    </Route>
                    <Route
                        path="/view-transfers" 
                        component={ViewTransfers} 
                    />
                    <Route 
                        path="/add-transfers" 
                        component={AddTransfers}
                    />
                    <Route 
                        path="/inbound-transfers" 
                        component={InboundTransfers}
                    />
                    <Route 
                        path="/breakdown-instructions" 
                        component={BreakdownInstructions}
                    />
                    <Route 
                        path="/training" 
                        component={Training}
                    />
                    <Route 
                        path="/queue" 
                        component={Queue}
                    />
                    <SecuredRoute 
                        path={'/export'}
                        component={Export}
                        user={user}
                        s_unit={s_unit}
                        eosWebApiUrl={eosWebApiUrl}
                        eosWebAuthCode={eosWebAuthCode}
                        actions={actions}
                        history={history}
                    />
                    <Route 
                        path="/import" 
                        component={Import}
                    />
                    <Route 
                        path="/warehouse-import" 
                        component={WarehouseImport}
                    />
                    <Route 
                        path="/rack" 
                        component={Rack}
                    />
                    <Route 
                        path="/dock-delivery" 
                        component={DockDelivery}
                    />
                </Switch>
            </Container>
            <ModalSelectStation />
        </div>
    );
};

export default App;
