import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Card, CardBody, Table } from 'reactstrap';
import ReactTable from '../custom/ReactTable';
import ModalViewFile from './ModalViewFile';
import ModalFiles from './ModalFiles';
import fileDownload from 'js-file-download';
import CustomerDetails from '../queue/CustomerDetails';

export default ({
    values,
    setFieldValue,
    importAttachments,
    setImportAttachments,
    fileTypes,
    files,
    addToFiles,
    file,
    setFile,
    updateFile,
    removeFile,
    selectedAwb,
    checkIdentification,
    saveIdentificationInformation
}) => {

    const [modalWebCam, setModalWebCam] = useState(false);
    const [modalViewFile, setModalViewFile] = useState(false);
    const [modalUploadFile, setModalUploadFile] = useState(false);
    const [selectedFile, setSelectedFile] = useState({});

    const [modalFilesType, setModalFilesType] = useState('');
    const [modalFiles, setModalFiles] = useState(false);

    const handleViewFile = (file) => {
        const imageTypes = ['image/jpg', 'image/jpeg', 'image/png'];
        if (file.type && !imageTypes.includes(file.type)) {
            fileDownload(file.base64, file.name);
        } else {
            setSelectedFile(file);
            setModalViewFile(true);
        }
    }

    const handleModalFiles = (type) => {
        setModalFilesType(type);
        setModalFiles(true);
    }

    return (
        <Row>
            <Col md={12}>
                <Row>
                    <Col md={6}>
                        <Card>
                            <CustomerDetails 
                                selectedAwb={selectedAwb}
                            />
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card onClick={() => handleModalFiles('WEBCAM')}>
                            <CardBody className={'text-center card-hover pointer'} style={{ borderRadius: '0.75rem' }}>
                                <img src={'/assets/img/scanner.png'} style={{ width: '50px', height: 'auto' }} />
                                <h4 className={'mt-1'}>Open Scanning Tool</h4>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card onClick={() => handleModalFiles('UPLOAD')}>
                            <CardBody className={'text-center card-hover pointer'} style={{ borderRadius: '0.75rem' }}>
                                <i className={'fas fa-paperclip'} style={{ fontSize: '50px', color: 'rgba(51,153,26,255)' }} />
                                <h4 className={'mt-2'}>Upload File</h4>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row className={'mt-3'}>
                    <Col md={3}>
                        <h4>Required Documents</h4>
                        <Table striped>
                            <thead></thead>
                            <tbody>
                                {
                                    Object.keys(fileTypes).map((key, i) => (
                                        <tr key={i}>
                                            <td className={'float-left'}>
                                                { key }
                                            </td>
                                            <td className={'float-right'}>
                                                { 
                                                    fileTypes[key] ? 
                                                        <span className='text-success'>Scanned</span> : 
                                                        <span className='text-danger'>Not Scanned</span>
                                                }
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </Col>
                    <Col md={9}>
                        <h4>Selected Documents to Upload</h4>
                        <ReactTable 
                            data={files}
                            mapping={[
                                {
                                    name: 'Type',
                                    value: 'fileType'
                                },
                                {
                                    name: 'Size',
                                    value: 'displaySize'
                                },
                                {
                                    name: '',
                                    value: 'fas fa-eye',
                                    icon: true,
                                    function: (item) => handleViewFile(item)
                                },
                                {
                                    name: '',
                                    value: 'fas fa-trash',
                                    icon: true,
                                    function: (item) => removeFile(item)
                                }
                            ]}
                            index={true}
                            numRows={5}
                        />
                    </Col>
                </Row>
            </Col>
            <ModalViewFile 
                modal={modalViewFile}
                setModal={setModalViewFile}
                selectedFile={selectedFile}
            />
            <ModalFiles 
                type={modalFilesType}
                modal={modalFiles}
                setModal={setModalFiles}
                fileTypes={fileTypes}
                addToFiles={addToFiles}
                file={file}
                setFile={setFile}
                updateFile={updateFile}
                values={values}
                setFieldValue={setFieldValue}
                selectedAwb={selectedAwb}
                checkIdentification={checkIdentification}
                saveIdentificationInformation={saveIdentificationInformation}
            />
        </Row>
    );
}