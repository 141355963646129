import React from 'react';
import ReactTable from '../custom/ReactTable';
import { tableMapping } from './skuMapping';

export default ({
    data,
    setSelectedSku,
    handleCreateUpdateSku,
    extendedMappingType,
    numRows,
    redirect
}) => {

    const minimalMapping = [
        {
            name: 'SKU Number',
            value: 's_sku'
        },
        {
            value: 'fas fa-edit',
            icon: true,
            function: () => handleCreateUpdateSku(false, redirect)
        }
    ]

    const extendedMapping = [
        {
            name: 'SKU',
            value: 's_sku'
        },
        {
            name: 'Commodity',
            value: 's_commodity'
        },
        {
            name: 'Weight KG',
            value: 'f_weight_kg'
        },
        {
            name: 'Dimensions',
            value: 's_dims'
        },
        {
            name: 'Notes',
            value: 's_notes'
        },
        {
            name: 'Last modified',
            value: 't_modified',
            datetime: true
        },
        {
            name: 'Modified by',
            value: 's_modified_by',
            email: true
        },
        {
            value: 'fas fa-edit',
            icon: true,
            function: () => handleCreateUpdateSku(false, redirect)
        }
    ]

    return (
        <ReactTable 
            data={data}
            mapping={ extendedMappingType ? extendedMapping : minimalMapping }
            index={true}
            enableClick={true}
            handleClick={setSelectedSku}
            locked={!extendedMappingType}
            numRows={numRows}
        />
    )
}