import React, { Fragment } from 'react';
import { Label, FormGroup } from 'reactstrap';
import { Field } from 'formik';

export default ({
    name,
    checked,
    label,
    onClick
}) => {

    return (
        <FormGroup>
            <Field
                name={name}
                type="checkbox"
                checked={checked}
                component={'input'}
                onClick={onClick}
                label={label}
            />
            <Label className='ml-2'>
                {label}
            </Label>
        </FormGroup>
    );
}
